import { green, red, blue, amber, orange } from '@mui/material/colors';
import { EDebatePosition, EDebateSpice } from '../model/enum';

export class ColorHelper {
    public static getBackground = (
        position: EDebatePosition | undefined
    ): string => {
        const color = this.getColor(position);
        if (color !== undefined) {
            return color;
        }
        return '#fff';
    };

    public static getText = (position: EDebatePosition | undefined): string => {
        return position === undefined ? '#000' : '#fff';
    };

    public static getAvatar = (position: EDebatePosition): string => {
        const colorGroup = this.getColorGroup(position);
        if (colorGroup !== undefined) {
            return colorGroup[300];
        }
        return '#fff';
    };

    public static getColorGroup = (position: EDebatePosition | undefined) => {
        switch (position) {
            case EDebatePosition.MODERATOR:
                return green;
            case EDebatePosition.SIDE_A:
                return red;
            case EDebatePosition.SIDE_B:
                return blue;
        }
    };

    public static getColor = (position: EDebatePosition | undefined) => {
        switch (position) {
            case EDebatePosition.MODERATOR:
                return green[700];
            case EDebatePosition.SIDE_A:
                return red[700];
            case EDebatePosition.SIDE_B:
                return blue[700];
        }
    };

    public static getSpiceColor = (spice: EDebateSpice): string => {
        switch (spice) {
            case EDebateSpice.MILD:
                return amber[500];
            case EDebateSpice.MEDIUM:
                return orange[500];
            case EDebateSpice.HOT:
                return red[500];
        }
    };
}
