import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAppState, EDebateStatus, EPage } from '../../model/enum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LogoComponent from '../components/Logo';
import { AppHelper } from '../../helpers/App';
import { StringHelper } from '../../helpers/String';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { setStartupSeen } from '../../redux/app/slice';
import { ButtonBase } from '@mui/material';
import BusyComponent from '../components/Busy';
import { resetDebate } from '../../redux/debate/slice';
import { getIsAgeAppropriate } from '../../redux/debate/actions';
import {
    ArrowForwardIosRounded as ArrowForwardIos,
    ArrowBackIosRounded as ArrowBackIos,
} from '@mui/icons-material';
import DebateTitleComponent from '../components/debate/Title';

const StartupScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const appState = useSelector((state: RootState) => state.app.appState);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const debate = useSelector((state: RootState) => state.debate.active);
    const [busy, setBusy] = useState(
        AppHelper.isOnDebatePage(location.pathname)
    );
    const [enterIsEnabled, setEnterIsEnabled] = useState(false);
    const audioContextRef = useRef<AudioContext | null>(null);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        dispatch(resetDebate());
        dispatch(setStartupSeen());
        navigate(EPage.HOME);
    };

    const handleClick = async () => {
        if (!AppHelper.isDevEnv()) {
            // AppHelper.enterFullscreen(document.documentElement);
        }
        try {
            audioContextRef.current = new (window.AudioContext ||
                (window as any).webkitAudioContext)();
            if (audioContextRef.current?.state === 'suspended') {
                await audioContextRef.current.resume();
                // console.log(
                //     'AudioContext resumed:',
                //     audioContextRef.current.state
                // );
            }
        } catch (err) {
            console.error('Failed to resume AudioContext:', err);
        }
        dispatch(setStartupSeen());
    };

    const checkAge = async () => {
        const isAppropriateAge = await dispatch(
            getIsAgeAppropriate(debate!.spice!)
        );
        if (isAppropriateAge || userInfo?.dob === null) {
            setEnterIsEnabled(true);
        }
    };

    useEffect(() => {
        if (debate !== undefined) {
            setBusy(false);
            navigate(AppHelper.makeDebatePath(debate));
        }
    }, [debate]);

    useEffect(() => {
        if (userInfo !== undefined && debate !== undefined) {
            checkAge();
        }
    }, [userInfo, debate]);

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                    padding: '1rem 2rem 2rem 2rem',
                    boxSizing: 'border-box',
                    borderRadius: '1rem',
                    maxWidth: 640,
                    position: 'relative',
                }}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box sx={{ margin: '1rem 0' }}>
                        <ButtonBase
                            onClick={handleLogoClick}
                            sx={{ borderRadius: '50%', overflow: 'visible' }}
                        >
                            <LogoComponent size="large" />
                        </ButtonBase>
                    </Box>
                    <Typography
                        align="center"
                        variant="h4"
                        sx={{ textTransform: 'uppercase' }}
                    >
                        Real&nbsp;Debate. Fake&nbsp;People.
                    </Typography>
                </Box>
                {debate ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: 6 }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: 2 }}
                        >
                            <DebateTitleComponent debate={debate} />
                        </Box>
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            disabled={
                                appState !== EAppState.LOADED || !enterIsEnabled
                            }
                            sx={{ maxWidth: 350 }}
                            startIcon={
                                <ArrowForwardIos
                                    sx={{ width: '2.4rem', height: '2.4rem' }}
                                />
                            }
                            endIcon={
                                <ArrowBackIos
                                    sx={{ width: '2.4rem', height: '2.4rem' }}
                                />
                            }
                        >
                            {debate.status === EDebateStatus.SHARING
                                ? StringHelper.insertNonBreakingSpace(
                                      `A ${AppHelper.getDebateSpiceName(debate.spice)} debate`
                                  )
                                : StringHelper.insertNonBreakingSpace(
                                      'Let’s finish this thing'
                                  )}
                        </Button>
                    </Box>
                ) : (
                    <Button
                        variant="contained"
                        onClick={handleClick}
                        disabled={appState !== EAppState.LOADED}
                    >
                        Enter
                    </Button>
                )}
                {/* {isMobile && (
                    <Typography variant="caption">
                        Not yet available on mobile devices.
                    </Typography>
                )} */}
            </Box>
        </Box>
    );
};

export default StartupScreen;
