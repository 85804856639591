import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { ButtonBase, Typography } from '@mui/material';

import { IDebate } from '../../../model/interface';
import { ColorHelper } from '../../../helpers/Color';
import { StringHelper } from '../../../helpers/String';
import { EDebatePosition } from '../../../model/enum';
import { RootState } from '../../../redux/store';

interface ITopicsProps {
    currentStep: number;
    handleClick: (index: number) => void;
}

const SequenceComponent: React.FC<ITopicsProps> = ({
    currentStep,
    handleClick,
}) => {
    const debate: IDebate = useSelector(
        (state: RootState) => state.debate.active
    )!;

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ width: '100%', maxWidth: 1200 }}
        >
            {debate.sequence.map((position, index) => {
                return (
                    <Box key={index} display="flex" alignItems="center">
                        <ButtonBase
                            id={`sequence-${index + 1}`}
                            onClick={() => handleClick(index)}
                            disabled={
                                index !== currentStep &&
                                (StringHelper.isEmpty(
                                    debate.discussion[index]?.statement
                                ) ||
                                    !debate.discussion[index]?.audio)
                            }
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor:
                                    ColorHelper.getBackground(position),
                                color: ColorHelper.getText(position),
                                margin: '0.2em',
                                borderRadius: '1rem',
                                width: 64,
                                height: 64,
                                opacity:
                                    index === currentStep ? undefined : 0.35,
                                filter:
                                    index === currentStep ||
                                    (StringHelper.isNotEmpty(
                                        debate.discussion[index]?.statement
                                    ) &&
                                        debate.discussion[index]?.audio)
                                        ? undefined
                                        : 'grayscale(1)',
                            }}
                        >
                            {position === EDebatePosition.MODERATOR ? (
                                <Typography variant="h5">M</Typography>
                            ) : position === EDebatePosition.SIDE_A ? (
                                <Typography variant="h5">A</Typography>
                            ) : (
                                <Typography variant="h5">B</Typography>
                            )}
                        </ButtonBase>
                    </Box>
                );
            })}
        </Box>
    );
};

export default SequenceComponent;
