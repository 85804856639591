import { createTheme } from '@mui/material/styles';
import mainTheme from './main';
import { green } from '@mui/material/colors';

const shopButtonTheme = createTheme(mainTheme, {
    palette: {
        primary: {
            main: green.A400,
        },
    },
});

export default shopButtonTheme;
