import React from 'react';
import { Box, Typography } from '@mui/material';

const AcceptableUseComponent: React.FC = () => {
    return (
        <>
            <Typography variant="h1" align="center">
                Acceptable Use Policy
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    gap: 1,
                }}
            >
                <Typography variant="caption" align="left">
                    Last Update: December, 2024
                </Typography>
                <Typography variant="body1" align="left">
                    We have established this Acceptable Use Policy to outline
                    acceptable and unacceptable conduct for your use of the Site
                    and the Services. By using the Site and the Services, you
                    agree to do what is listed under the ‘General Requirements’
                    and to abstain from those activities listed under the
                    ‘Categories of Prohibitions.’ You also agree to not
                    authorize, enable, induce or encourage any third party to
                    carry out those prohibited activities. If we believe a
                    violation of this policy has taken place, especially where
                    deliberate, repeated, or if it presents a credible risk of
                    harm to other users, Bottymouth, the Services or any third
                    parties, we may suspend or terminate your access and/or your
                    account.
                </Typography>
                <Typography variant="body1" align="left">
                    This policy may change as the Services and industry evolve,
                    so please check back regularly for updates and changes.
                    Capitalized terms used below but not defined in this policy
                    have the meaning set forth in the Terms of Use.
                </Typography>
                <Typography variant="h3" align="left">
                    General Requirements
                </Typography>
                <Typography
                    component="ul"
                    variant="body1"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Comply with applicable laws and governmental
                        regulations, including all intellectual property, data
                        protection, privacy, artificial intelligence and export
                        control laws.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Use the Services for personal entertainment only, and
                        not for commercial purposes.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Use reasonable efforts to prevent unauthorized access
                        and use of the Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Monitor and control all activity conducted through your
                        account in connection with the Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Moderate prompts to ensure that the rights and interests
                        of individuals and entities are being respected.
                    </Typography>
                </Typography>
                <Typography variant="h3" align="left">
                    Categories of Prohibitions
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Impersonation, Defamation, Deception & Abuse of
                        Individual Rights
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Services in a way that is obscene, defamatory,
                        insulting, hostile, inciting, threatening, or
                        discriminatory towards an individual or a group of
                        individuals.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Repurposing or distributing Content for the purposes of
                        committing fraud or to mislead, bully, threaten, harass,
                        or discriminate based on protected attributes, or to
                        promote violence, hatred or the suffering of others.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Impersonating any person or entity, or falsely stating
                        or otherwise misrepresenting your affiliation with a
                        person, organization or entity.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Site to defame a person or associate their
                        identity with Content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Violating, misappropriating or infringing a person’s
                        right to privacy or publicity, including any of their
                        moral or intellectual property rights.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Services to create and/or send altered,
                        deceptive or false source-identifying information to
                        scam or spam others, including "spoofing" or "phishing".
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    Adult Content & Abuse of Minors
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Using the Services in a manner that exploits, harms, or
                        attempts to exploit or harm minors in any way, for
                        example by representing or portraying them as debate
                        participants or the subject of debate content, or
                        exposing them to explicit, inappropriate Content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Services for the purpose of producing,
                        promoting, or providing obscene adult entertainment.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Services to promote or advertise
                        age-restricted goods, services or venues, including
                        alcohol, tobacco, vaping products, psychoactive
                        substances, firearms, gambling, sex toys, escort
                        services and dating services.
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    Campaigns, Communications & Abuse of the Public Interest
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Using the Services to transmit any conspiracy theories,
                        propaganda or data or information that is in furtherance
                        of any ‘misinformation’ or ‘disinformation’ campaigns or
                        activities.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Engaging in political campaigning or lobbying, including
                        generating campaign materials personalized to or
                        targeted at specific demographics.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Removing, deactivating or disabling any ‘watermarks’ or
                        other mechanisms of the Services that are designed to
                        help validate provenance or differentiate between
                        human-generated and artificial intelligence-generated
                        content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using the Services in a way that promotes, encourages,
                        or facilitates hate speech, violence, or discrimination.
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    Bottymouth Technology & Abuse of Access
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Scraping any content or data from Bottymouth or the
                        Services for the creation or training of artificial
                        intelligence models or tools, including machine learning
                        models, generative models, deep learning models, and
                        artificial neural networks.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Inputting or transmitting to the Site any data, file,
                        software, or link that contains or redirects to a virus,
                        Trojan horse, worm, or other harmful component or a
                        technology that unlawfully accesses or downloads content
                        or information stored within the Services or on the
                        hardware of Bottymouth or any third party.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Attempting to reverse engineer, decompile, hack,
                        disable, interfere with, disassemble, modify, copy,
                        translate, or disrupt the features, functionality,
                        integrity, or performance of the Services (including any
                        mechanism used to restrict or control the functionality
                        of the Services), any third party use of the Services,
                        or any third party data contained therein (except to the
                        extent such restrictions are prohibited by applicable
                        law).
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Attempting to gain unauthorized access to the Services
                        or related systems or networks or to defeat, avoid,
                        bypass, remove, deactivate, or otherwise circumvent any
                        software protection or monitoring mechanisms of the
                        Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Sublicensing, reselling, time sharing or similarly
                        exploiting the Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Accessing the Services to build a competitive product or
                        service.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Excessively, disproportionately or unreasonably
                        consuming computer or artificial intelligence generation
                        resources.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Abusing referrals or promotions for the Services, for
                        example to get more credits than deserved.
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    Bottymouth Content & Abuse of Third Party Rights
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Incorporating the Services in any patents, trademarks,
                        design-marks, or service-marks, or any registrations or
                        filings thereof.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Using Output in connection with any products or
                        services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Altering, manipulating, or publishing Output in any way.
                    </Typography>
                </Typography>
            </Box>
        </>
    );
};

export default AcceptableUseComponent;
