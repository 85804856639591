import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../store';

const REDDIT_PIXEL_SCRIPT_ID = 'reddit';

export const loadRedditPixel =
    (userKey: string) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        if (
            !document.getElementById(REDDIT_PIXEL_SCRIPT_ID) &&
            !AppHelper.isDevEnv()
        ) {
            const script = document.createElement('script');
            script.id = REDDIT_PIXEL_SCRIPT_ID;
            script.innerHTML = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${process.env.REACT_APP_REDDIT_PIXEL_ID}', {externalId: '${userKey}'});rdt('track', 'PageVisit');`;
            document.head.appendChild(script);
        }
    };
