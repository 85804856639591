import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ArrayHelper } from '../../helpers/Array';
import { StringHelper } from '../../helpers/String';

const PHRASES = [
    'Good things come to those who don’t refresh...',
    'Magic takes time...',
    'Counting electric sheep...',
    'This is a bit like watching a microwave...',
    'I’m just taking a moment to admire your patience...',
    'Something super important is probably happening...',
    'This is just for dramatic suspense...',
    'Cue the elevator music...',
    'Pretending to think really hard...',
];

const BusyComponent: React.FC = () => {
    const [phrase, setPhrase] = React.useState<string>('');

    useEffect(() => {
        setPhrase(ArrayHelper.shuffle(PHRASES)[0]);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    gap: 3,
                }}
            >
                <CircularProgress />
                <Typography
                    variant="caption"
                    align="center"
                    sx={{ maxWidth: 300 }}
                >
                    {StringHelper.insertNonBreakingSpace(phrase)}
                </Typography>
            </Box>
        </Box>
    );
};

export default BusyComponent;
