import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const creditTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '1.2rem',
            lineHeight: 1.2,
            textTransform: 'none',
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1rem',
            },
        },
        caption: {
            whiteSpace: 'nowrap',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: mainTheme.spacing(1),
                    paddingLeft: mainTheme.spacing(2),
                    paddingRight: mainTheme.spacing(2),
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: mainTheme.spacing(0.5),
                        paddingLeft: mainTheme.spacing(0.5),
                        paddingRight: mainTheme.spacing(0.5),
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                    fontSize: '1.2rem',
                    lineHeight: 1.2,
                    textTransform: 'none',
                    padding: mainTheme.spacing(1),
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    maxWidth: '20vw',
                    overflow: 'hidden',
                    [mainTheme.breakpoints.down('sm')]: {
                        fontSize: '1rem',
                        padding: 0,
                    },
                },
                endIcon: {
                    [mainTheme.breakpoints.down('sm')]: {
                        marginLeft: 0,
                    },
                },
            },
        },
    },
});

export default creditTheme;
