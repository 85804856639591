import React from 'react';
import {
    Box,
    ButtonBase,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { AppHelper } from '../../../helpers/App';
import { ColorHelper } from '../../../helpers/Color';
import { EAiProvider, EDebateFormat, EDebateSpice } from '../../../model/enum';
import { EnvironmentHelper } from '../../../helpers/Environment';
import { TRUE } from '../../../model/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface IProps {
    action: string;
    selectedSpice: EDebateSpice;
    selectedFormat: EDebateFormat;
    selectedTag?: string;
    selectedAiProvider?: EAiProvider | undefined;
    onSelectSpice: (s: EDebateSpice) => void;
    onSelectFormat: (f: EDebateFormat) => void;
    onClickTag?: () => void;
    onSelectAiProvider?: (p: EAiProvider) => void;
}

const SelectorComponent: React.FC<IProps> = ({
    action,
    selectedSpice,
    selectedFormat,
    selectedTag,
    selectedAiProvider,
    onSelectSpice,
    onSelectFormat,
    onSelectAiProvider,
    onClickTag,
}) => {
    const theme = useTheme();
    const userInfo = useSelector((state: RootState) => state.user.info);
    const validSpices = [EDebateSpice.MILD]; // , EDebateSpice.MEDIUM];
    if (EnvironmentHelper.parseAsBoolean(process.env.REACT_APP_SHOCKING)) {
        validSpices.push(EDebateSpice.HOT);
    }
    const formats = [
        EDebateFormat.STANDARD,
        // EDebateFormat.MEDIUM,
        // EDebateFormat.LONG,
    ];

    if (AppHelper.isAdmin()) {
        formats.unshift(EDebateFormat.TEST);
    }

    const aiProviders = [
        EAiProvider.OPENAI,
        EAiProvider.GOOGLE,
        EAiProvider.ANTHROPIC,
        EAiProvider.MISTRAL,
    ];

    const [anchorElSpice, setAnchorElSpice] =
        React.useState<null | HTMLElement>(null);
    const [anchorElFormat, setAnchorElFormat] =
        React.useState<null | HTMLElement>(null);
    const [anchorElAiProvider, setAnchorElAiProvider] =
        React.useState<null | HTMLElement>(null);

    const showSpiceMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElSpice(event.currentTarget);
    };

    const showFormatMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElFormat(event.currentTarget);
    };

    const showAiProviderMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAiProvider(event.currentTarget);
    };

    const handleSelectSpice = (s: EDebateSpice) => {
        handleMenuClose();
        onSelectSpice(s);
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        handleMenuClose();
        onSelectFormat(f);
    };

    const handleSelectAiProvider = (p: EAiProvider) => {
        handleMenuClose();
        onSelectAiProvider!(p);
    };

    const handleMenuClose = () => {
        setAnchorElSpice(null);
        setAnchorElFormat(null);
        setAnchorElAiProvider(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            sx={{ gap: 6, width: '100%' }}
        >
            <Typography variant="h1" align="center">
                {`${action} a `}
                <ButtonBase
                    id="select-spice"
                    aria-controls={anchorElSpice ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorElSpice ? TRUE : undefined}
                    onClick={showSpiceMenu}
                    sx={{
                        verticalAlign: 'baseline',
                        borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                        borderBottomStyle: 'dotted',
                        borderBottomColor:
                            ColorHelper.getSpiceColor(selectedSpice),
                    }}
                >
                    <Typography
                        variant="h1"
                        component="span"
                        sx={{
                            color: ColorHelper.getSpiceColor(selectedSpice),
                        }}
                    >
                        {AppHelper.getDebateSpiceName(selectedSpice)}
                    </Typography>
                </ButtonBase>{' '}
                {formats.length > 1 && (
                    <>
                        <ButtonBase
                            id="select-format"
                            aria-controls={
                                anchorElFormat ? 'basic-menu' : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={anchorElFormat ? TRUE : undefined}
                            onClick={showFormatMenu}
                            sx={{
                                verticalAlign: 'baseline',
                                borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                                borderBottomStyle: 'dotted',
                                borderBottomColor: grey[500],
                            }}
                        >
                            <Typography variant="h1" component="span">
                                {AppHelper.getDebateFormatName(selectedFormat)}
                            </Typography>
                        </ButtonBase>{' '}
                    </>
                )}
                {selectedTag !== undefined && (
                    <>
                        <ButtonBase
                            id="select-tag"
                            onClick={onClickTag}
                            sx={{
                                verticalAlign: 'baseline',
                                borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                                borderBottomStyle: 'dotted',
                                borderBottomColor: theme.palette.secondary.main,
                            }}
                        >
                            <Typography
                                component="span"
                                variant="h1"
                                color="secondary"
                            >
                                {selectedTag}
                            </Typography>
                        </ButtonBase>
                        &nbsp;
                    </>
                )}
                debate!
            </Typography>
            {userInfo?.admin && onSelectAiProvider !== undefined && (
                <Typography variant="caption" align="center">
                    Use{' '}
                    <ButtonBase
                        id="select-aiprovider"
                        aria-controls={
                            anchorElAiProvider ? 'basic-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={anchorElAiProvider ? TRUE : undefined}
                        onClick={showAiProviderMenu}
                        sx={{
                            verticalAlign: 'baseline',
                            borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                            borderBottomStyle: 'dotted',
                            borderBottomColor: grey[500],
                        }}
                    >
                        <Typography variant="caption" component="span">
                            {AppHelper.getAiProviderName(selectedAiProvider)}
                        </Typography>
                    </ButtonBase>
                </Typography>
            )}
            <Menu
                id="spice-menu"
                anchorEl={anchorElSpice}
                open={!!anchorElSpice}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {validSpices.map((s, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleSelectSpice(s)}
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                color: ColorHelper.getSpiceColor(s),
                            }}
                        >
                            {AppHelper.getDebateSpiceName(s)}
                        </Typography>
                        {/* <Typography component="span" variant="caption">
                            {AppHelper.getDebateSpiceDescription(s)}
                        </Typography> */}
                    </MenuItem>
                ))}
            </Menu>
            {formats.length > 1 && (
                <Menu
                    id="format-menu"
                    anchorEl={anchorElFormat}
                    open={!!anchorElFormat}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {formats.map((f, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleSelectFormat(f)}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography component="span" variant="h1">
                                {AppHelper.getDebateFormatName(f)}
                            </Typography>
                            <Typography component="span" variant="caption">
                                {`${AppHelper.getCreditCost(f)} credit${AppHelper.getCreditCost(f) > 1 ? 's' : ''} - ${AppHelper.getDebateFormatDescription(f)}`}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            )}
            <Menu
                id="ai-provider-menu"
                anchorEl={anchorElAiProvider}
                open={!!anchorElAiProvider}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {aiProviders.map((p, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleSelectAiProvider(p)}
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography component="span" variant="caption">
                            {AppHelper.getAiProviderName(p)}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default SelectorComponent;
