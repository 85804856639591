import React, { useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { EAppState, EDebateStage, EPage, EPollType } from '../../../model/enum';
import { Box, Button, Modal, Paper, ThemeProvider } from '@mui/material';
import PollScreen from './Poll';
import pollTheme from '../../../themes/poll';
import DiscussionScreen from './Discussion';
import SummaryScreen from './Summary';
import ResultsScreen from './Results';
import StartupScreen from '../Startup';
import { loadDebate } from '../../../redux/debate/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { StringHelper } from '../../../helpers/String';
import { setDebateStage } from '../../../redux/debate/slice';
import ShareScreen from './Share';
import { ArrayHelper } from '../../../helpers/Array';
import summaryTheme from '../../../themes/summary';
import BusyComponent from '../../components/Busy';
import { saveDisclaimer } from '../../../redux/user/actions';
import DisclaimerComponent from '../../components/legal/Disclaimer';
import legalThemeSmall from '../../../themes/legalSmall';
import PreviewScreen from './Preview';

const DebateContainer: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const appState = useSelector((state: RootState) => state.app.appState);
    const debateStage = useSelector((state: RootState) => state.debate.stage);
    const debate = useSelector((state: RootState) => state.debate.active);
    const polls = useSelector((state: RootState) => state.debate.polls);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const debateKey = useParams<{ key: string }>().key;
    const [showDisclaimer, setShowDisclaimer] = React.useState<boolean>(false);
    const [busy, setBusy] = React.useState<boolean>(false);
    const [debateLoaded, setDebateLoaded] = React.useState<boolean>(false);

    const handleDisclaimerAccepted = async () => {
        setShowDisclaimer(false);
        setBusy(true);
        await dispatch(saveDisclaimer());
        setBusy(false);
    };

    useEffect(() => {
        const fetchDebate = async () => {
            try {
                setBusy(true);
                const debate = await dispatch(loadDebate(debateKey!));
                setDebateLoaded(true);
                setBusy(false);
            } catch (error) {
                navigate(EPage.HOME);
            }
        };

        if (
            appState === EAppState.LOADED &&
            StringHelper.isNotEmpty(debateKey) &&
            !debateLoaded
        ) {
            fetchDebate();
        }
    }, [appState, debateKey]);

    useEffect(() => {
        if (ArrayHelper.isNotEmpty(polls)) {
            if (polls?.some((p) => p.type === EPollType.POST)) {
                dispatch(setDebateStage(EDebateStage.RESULTS));
            } else if (polls?.some((p) => p.type === EPollType.OPENNESS)) {
                dispatch(setDebateStage(EDebateStage.DISCUSSION));
            } else if (polls?.some((p) => p.type === EPollType.PRE)) {
                dispatch(setDebateStage(EDebateStage.OPENNESS_POLL));
            }
        }
    }, [polls]);

    useEffect(() => {
        if (userInfo !== undefined && !userInfo.disclaimer) {
            setShowDisclaimer(true);
        }
    }, [userInfo]);

    useEffect(() => {
        if (debateLoaded) {
            if (debateStage === undefined) {
                dispatch(
                    setDebateStage(
                        debate?.owner && ArrayHelper.isEmpty(debate?.discussion)
                            ? EDebateStage.PREVIEW
                            : EDebateStage.INTRO_POLL
                    )
                );
            }
        }
    }, [debateLoaded]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    if (busy) {
        return <BusyComponent />;
    }

    switch (appState) {
        case EAppState.LOADED:
            if (debate?.key === undefined) {
                return <BusyComponent />;
            }
            if (showDisclaimer) {
                return (
                    <Modal open={true}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                            sx={{}}
                        >
                            <ThemeProvider theme={legalThemeSmall}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: 640,
                                        gap: 2,
                                        padding: '2rem',
                                    }}
                                >
                                    <DisclaimerComponent />
                                    <Button
                                        variant="contained"
                                        onClick={handleDisclaimerAccepted}
                                    >
                                        Click to Accept
                                    </Button>
                                </Paper>
                            </ThemeProvider>
                        </Box>
                    </Modal>
                );
            }
            switch (debateStage) {
                case EDebateStage.PREVIEW:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PreviewScreen />
                        </ThemeProvider>
                    );
                case EDebateStage.INTRO_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.PRE}
                                nextStage={EDebateStage.OPENNESS_POLL}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.OPENNESS_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.OPENNESS}
                                nextStage={EDebateStage.DISCUSSION}
                                previousStage={EDebateStage.INTRO_POLL}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.DISCUSSION:
                    return <DiscussionScreen />;
                case EDebateStage.SUMMARY:
                    return (
                        <ThemeProvider theme={summaryTheme}>
                            <SummaryScreen />
                        </ThemeProvider>
                    );
                case EDebateStage.EXIT_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.POST}
                                nextStage={EDebateStage.RESULTS}
                                previousStage={EDebateStage.SUMMARY}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.RESULTS:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <ResultsScreen />
                        </ThemeProvider>
                    );
                case EDebateStage.SHARE:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <ShareScreen />
                        </ThemeProvider>
                    );

                default:
                    return null;
            }
        default:
            return <StartupScreen />;
    }
};

export default DebateContainer;
