export enum EDebateFormat {
    TEST = 'test',
    STANDARD = 'standard',
    MEDIUM = 'medium',
    // LONG = 'long',
}

export enum EDebateSpice {
    MILD = 'mild',
    MEDIUM = 'medium',
    HOT = 'hot',
}

export enum EPollType {
    PRE = 'pre',
    OPENNESS = 'openness',
    POST = 'post',
}

export enum EIdentityProvider {
    BOTVBOT = 'botvbot',
    GOOGLE = 'google',
}

export enum EDebateStatus {
    CREATING = 'creating',
    SHARING = 'sharing',
}

export const enum EAppState {
    INITIALIZING = 'init',
    LOGGING_IN = 'logging in',
    LOGGED_IN = 'logged in',
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error',
}

export const enum EDebateStage {
    PREVIEW = 'preview',
    INTRO_POLL = 'intro',
    OPENNESS_POLL = 'openness',
    DISCUSSION = 'discussion',
    SUMMARY = 'summary',
    EXIT_POLL = 'exit',
    RESULTS = 'results',
    SHARE = 'share',
}

export enum EDebatePosition {
    MODERATOR = 'Moderator',
    SIDE_A = 'Side_A',
    SIDE_B = 'Side_B',
}

export enum EPage {
    HOME = '/',
    DEBATES_BY_AUDIENCE = '/:audience/debates',
    CREATE = '/new',
    SEARCH = '/search',
    LATEST = '/latest',
    ACCOUNT_DEBATES = '/account/debates',
    ACCOUNT_CREDITS = '/account/credits',
    ABOUT = '/about',
    // DEBATE = `/${DEBATE_PATH_ELEMENT}/:key/:title/:status`,
    DEBATE = `/debate/:key/:title/:status`,
    CREDITS = '/credits',
    PURCHASE_COMPLETE = '/purchase-complete',
    COUPON = '/coupon/:key',
    CONTACT = '/contact',
    NEWSLETTER = '/newsletter',
    LOGIN = '/signin',
    ACCOUNT_SSO = '/sso',
    DISCLAIMER = '/disclaimer',
    TERMS_OF_USE = '/terms',
    ACCEPTABLE_USE = '/aup',
    PRIVACY_POLICY = '/privacy',
    DPA = '/dpa',
    ADMIN = '/admin',
}

export enum ECreditType {
    COUPON = 'coupon',
    PENDING = 'pending',
    PURCHASE = 'purchase',
    SPEND = 'spend',
    GIFT = 'gift',
    VOTE = 'vote',
}

export enum EReturnCode {
    SUCCESS,
    REDIRECT_TO_SSO,
}

export enum ECurrency {
    CAD = 'cad',
    USD = 'usd',
    GBP = 'gbp',
    EUR = 'eur',
    AUD = 'aud',
    NZD = 'nzd',
}

export enum EAiProvider {
    OPENAI = 'openai',
    GOOGLE = 'gemini',
    ELEVEN_LABS = 'elevenlabs',
    ANTHROPIC = 'anthropic',
    MISTRAL = 'mistral',
}
