import { amber, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: amber[400],
            main: amber[500],
            dark: 'white',
        },
        secondary: {
            light: green.A400,
            main: green.A700,
            dark: 'white',
        },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 24,
        h1: {
            fontFamily: 'DynaPuff, serif',
            fontSize: '2.8rem',
            lineHeight: 1.2,
            fontWeight: 600,
        },
        h2: {
            fontSize: '1rem',
            textTransform: 'uppercase',
            lineHeight: 1.2,
            fontWeight: 500,
        },
        h3: {
            fontFamily: 'DynaPuff, serif',
            fontSize: '2.4rem',
            lineHeight: 1.3,
            fontWeight: 400,
        },
        h4: {
            fontSize: '1.8rem',
            lineHeight: 1.2,
            fontWeight: 400,
        },
        // used in debate sequence component
        h5: {
            fontFamily: 'DynaPuff, serif',
            fontSize: '2.6rem',
            lineHeight: 1.2,
            fontWeight: 400,
        },
        body1: {
            fontSize: '2rem',
            lineHeight: 1.3,
            fontWeight: 400,
        },
        body2: {
            textTransform: 'uppercase',
            fontSize: '1.6rem',
            lineHeight: 1.3,
            fontWeight: 400,
        },
        caption: {
            fontSize: '1.2rem',
            lineHeight: 1.2,
            textTransform: 'uppercase',
            fontWeight: 400,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'DynaPuff, serif',
                    fontSize: '2rem',
                    lineHeight: 1.2,
                    padding: '1.25rem 2rem',
                    borderRadius: '1rem',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: 0,
                    '& .MuiInputBase-input': {
                        fontSize: '2.4rem',
                        paddingRight: 72,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(15px, 15px) scale(1)',
                },
                shrink: {
                    transform: 'translate(10px, -15px) scale(0.5)',
                    padding: '0.5rem',
                    backgroundColor: '#202124',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 0.5rem',
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    width: '3rem !important',
                    height: '3rem !important',
                },
            },
        },
    },
});

const responsiveTheme = createTheme(mainTheme, {
    typography: {
        h3: {
            lineHeight: 1.2,
        },
        caption: {
            [mainTheme.breakpoints.down('sm')]: {
                opacity: 0.9,
            },
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: '1rem 1.6rem',
                        lineHeight: 1.1,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: 0,
                    '& .MuiInputBase-input': {
                        [mainTheme.breakpoints.down('sm')]: {
                            paddingRight: 56,
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    [mainTheme.breakpoints.down('sm')]: {
                        transform: 'translate(18px, 15px) scale(1)',
                    },
                },
                shrink: {
                    [mainTheme.breakpoints.down('sm')]: {
                        transform: 'translate(14px, -16px) scale(0.75)',
                    },
                },
            },
        },
    },
});

export default responsiveTheme;
