import axios, { AxiosResponse } from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setStartupSeen, setWarningMessage } from '../../../redux/app/slice';
import { Controller, useForm } from 'react-hook-form';
import { EAppState, EPage } from '../../../model/enum';
import { CheckCircleRounded as CheckCircle } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import contactTheme from '../../../themes/contact';
import { joinMailingList } from '../../../redux/user/actions';
import { StringHelper } from '../../../helpers/String';

type ContactFormData = {
    email: string;
    firstName: string;
};

const NewsletterScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const appState = useSelector((state: RootState) => state.app.appState);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const [busy, setBusy] = useState(false);
    const [sent, setSent] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ContactFormData>();

    const handleHomeClick = () => {
        navigate(EPage.HOME);
    };

    const onSubmit = async (data: ContactFormData) => {
        setBusy(true);
        const result = await dispatch(
            joinMailingList(data.email, data.firstName)
        );
        setBusy(false);
        if (result === true) {
            setSent(true);
        } else {
            dispatch(setWarningMessage('Failed to join newsletter'));
        }
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={contactTheme}>
            <Box
                display="flex"
                sx={{ paddingTop: '3rem', width: '100%' }}
                onSubmit={handleSubmit(onSubmit)}
            >
                {sent ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            margin: 'auto',
                            height: '100%',
                            gap: 4,
                        }}
                    >
                        <CheckCircle
                            sx={{ width: 128, height: 128, color: green.A400 }}
                        />
                        <Typography variant="h1" align="center">
                            Thank you!
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleHomeClick}
                        >
                            Browse debates
                        </Button>
                    </Box>
                ) : (
                    <Box
                        component="form"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            margin: 'auto',
                            width: '100%',
                            maxWidth: 800,
                            gap: 4,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ gap: 2 }}
                        >
                            <Typography variant="h1" align="center">
                                Join a Humane Mailing List
                            </Typography>
                            <Typography
                                variant="caption"
                                align="center"
                                sx={{ opacity: 0.7 }}
                            >
                                {StringHelper.insertNonBreakingSpace(
                                    'Let’s stay in touch, although I promise not to bother you too much.'
                                )}
                            </Typography>
                        </Box>
                        {appState !== EAppState.LOADED ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                    width="100%"
                                >
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={userInfo?.email || ''}
                                        rules={{
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message:
                                                    'Invalid email address',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Your email address"
                                                variant="outlined"
                                                margin="normal"
                                                error={!!errors.email}
                                                helperText={
                                                    errors.email?.message
                                                }
                                                inputProps={{
                                                    maxLength: 128,
                                                }}
                                                disabled={busy}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={userInfo?.name || ''}
                                        rules={{
                                            required: 'First name is required',
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Your first name"
                                                variant="outlined"
                                                margin="normal"
                                                error={!!errors.firstName}
                                                helperText={
                                                    errors.firstName?.message
                                                }
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    maxLength: 32,
                                                }}
                                                disabled={busy}
                                            />
                                        )}
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ gap: 4, position: 'relative' }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={busy}
                                    >
                                        Submit
                                    </Button>
                                    {busy && (
                                        <CircularProgress
                                            sx={{
                                                position: 'absolute',
                                                right: '-4rem',
                                                top: 'auto',
                                                bottom: 'auto',
                                            }}
                                        />
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default NewsletterScreen;
