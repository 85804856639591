import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const shopTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '1.2rem',
        },
        caption: {
            fontSize: '1.2rem',
            marginTop: 4,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: mainTheme.spacing(4),
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: mainTheme.spacing(2),
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    flexDirection: 'column',
                    whiteSpace: 'nowrap',
                },
            },
        },
    },
});

export default shopTheme;
