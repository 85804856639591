import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { EPage } from '../../../model/enum';

const PrivacyComponent: React.FC = () => {
    return (
        <>
            <Typography variant="h1" align="center">
                Privacy Policy
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    gap: 1,
                }}
            >
                <Typography variant="caption" align="left">
                    Last Update: December, 2024
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth Entertainment is committed to maintaining the
                    security, confidentiality and privacy of your Personal
                    Information (which includes the Personal Information of
                    Children). This Privacy Policy applies to the BOTvBOT
                    application found on this site, botvbot.com, and other
                    subdomains of botvbot.com.
                </Typography>
                <Typography variant="h3" align="left">
                    Introduction and Definitions
                </Typography>
                <Typography variant="body1" align="left">
                    This privacy policy (the “Privacy Policy”) constitutes an
                    agreement between you, or if you are under the age of
                    majority in your local jurisdiction, you, with the consent
                    of your parent or legal guardian, (“user”, “you” or “your”)
                    and Bottymouth Entertainment and its affiliates and
                    subsidiaries (collectively, “Bottymouth”, “us”, “our” or
                    “we”). Bottymouth is dedicated to protecting the privacy
                    rights of our users. This Privacy Policy describes how
                    Bottymouth collects, protects, uses, retains, discloses,
                    purges and destroys information and data created in the
                    course of your access to and use of the Site, Content and/or
                    Services (each as defined below). Please note that this
                    Privacy Policy applies only to information collected through
                    the Site and/or Services.
                </Typography>
                <Typography variant="body1" align="left">
                    For the purposes of this Privacy Policy:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        “Site” (or “BOTvBOT”) means botvbot.com, and other
                        affiliated subdomains of botvbot.com.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Account” means an account created on the Site.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Child or Children” means any child or children under
                        the age of 13 years old.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Parent” means a parent or guardian of a Child or
                        Children.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Content” means any expression fixed in a tangible
                        medium and includes, without limitation, graphics,
                        avatars, debates, summaries, participant profiles,
                        logos, images, trademarks, copyrights, information,
                        software, and any intellectual property therein, any of
                        which may be created, submitted, or otherwise made
                        accessible on or through the Site and/or Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Bottymouth Content” means all Content that is not User
                        Generated Content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Services” means creation of debates accessed through
                        the Site, as well as access to AI-Generated Content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “User” means someone who signs up for an Account on the
                        Site.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “User Generated Content” or “UGC” means any material
                        whatsoever, including debate topics, that a user,
                        including you, submits, creates, transfers or otherwise
                        makes available by access to the Site or through the
                        Services, including but not limited to ideas and
                        information posted in any area within the Site or
                        through the Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Shocking” means any material whatsoever that is
                        labelled on the Site as not safe for children.
                    </Typography>
                </Typography>
                <Typography variant="h3" align="left">
                    Compliance with Privacy Laws
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth’s data privacy and security practices are
                    consistent with the requirements of Canadian, US, European,
                    and other privacy laws.
                </Typography>
                <Typography variant="body1" align="left">
                    Don’t hesitate to contact{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>{' '}
                    if you are unsure whether Bottymouth is consistent with your
                    local privacy laws.
                </Typography>
                <Typography variant="h3" align="left">
                    Acceptance of Terms and Revisions
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth intends to be compliant with the requirements of
                    Canadian and US privacy laws including the Personal
                    Information Protection and Electronic Documents Act, the
                    Personal Information Protection Act within British Columbia.
                    As such, if you are a Child under the age of 13 years old,
                    you may not create or view “Shocking” debates. If you
                    attempt to create or view “Shocking” debates, you will be
                    informed that you are not old enough. We ask for your age
                    before permitting access to “Shocking” debates for this
                    purpose. We rely on you to answer truthfully.
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing the Site, submitting information to us
                    (regardless of whether you register an Account with us) or
                    using any of the Content or Services you accept the terms of
                    this Privacy Policy. If you do not accept the terms of this
                    Privacy Policy you must not submit information to or
                    register an Account with us, access the Site or download,
                    install or use any of the Content or Services.
                </Typography>
                <Typography variant="body1" align="left">
                    We reserve the right to revise this Privacy Policy at any
                    time. We will give you notice of such revisions by posting
                    the revised Privacy Policy at{' '}
                    {`${window.location.origin}${EPage.PRIVACY_POLICY}`}.
                </Typography>
                <Typography variant="h3" align="left">
                    Type of Information We Collect
                </Typography>
                <Typography variant="body1" align="left">
                    There are two types of information you may submit through
                    your access to and use of the Site and/or Services:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        “Non-Personal Information” means information that does
                        not identify you and cannot be used to identify you
                        personally and may include your browser and operating
                        system descriptor, date of birth, and non-precise
                        geolocation information (e.g., your city, region, and
                        country). Non-Personal Information also includes “Usage
                        Data” which is anonymous data associated with your
                        computer that includes activities and time on the Site,
                        when not linked to any persistent identifier or other
                        Personal Information.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Personal Information” means information about an
                        identifiable individual (including any “Personal
                        Information” as such term is defined in the applicable
                        privacy statute). DO NOT SUBMIT PERSONAL INFORMATION in
                        the debate prompts. We do not wish to collect it.
                    </Typography>
                </Typography>
                <Typography variant="h3" align="left">
                    Application of this Privacy Policy
                </Typography>
                <Typography variant="body1" align="left">
                    This Privacy Policy only applies to the information that
                    Bottymouth collects and uses, or is supplied, through the
                    access to and use of the Site and/or Services. Any
                    information disclosed to any third party is dealt with in
                    accordance with the privacy policies adopted by each of
                    those third parties. We do, however, only use third party
                    services whose policies are consistent with our own. We
                    encourage you to review the privacy policies maintained by
                    each of those third parties to understand how their
                    information will be used by those entities to process
                    requests. For example, we partner with third party service
                    providers and vendors to provide access to existing payment
                    gateways and your credit card, banking and other billing
                    information is kept with our payment processor, Stripe, and
                    you can read about its privacy policy at the following link:
                    https://www.stripe.com/privacy-center/legal.
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth uses artificial intelligence (AI) services such
                    as OpenAI, Google Gemini, Anthropic Claude, Mistral, and
                    ElevenLabs to provide the botvbot service. For example,
                    botvbot uses OpenAI and/or Google Gemini for text generation
                    and OpenAI, Google Cloud, and Eleven Labs for voice
                    generation. These providers may store and process the
                    information you provide in the debate creation prompt. That
                    information will be subject to their terms and policies. You
                    are required by us and by them to comply with all applicable
                    laws and regulations that are relevant to your use of the
                    Services, including these AI services. By using the Services
                    you consent and agree to our use of these AI services.
                </Typography>
                <Typography variant="h3" align="left">
                    Collection of Information
                </Typography>
                <Typography variant="body1" align="left">
                    We may collect information (including Personal Information)
                    as follows:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Information you provide to us upon registration. If you
                        choose to register, through Google single sign-on,
                        request support, or in any other way take steps that
                        require the submission of information, we may collect
                        Personal Information such as your email address and your
                        name.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Purchases. If you choose to subscribe to the Site and/or
                        Services, our payment processor, Stripe, will collect
                        your credit card information. We do not collect or store
                        your credit card information.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Information you submit to us. If you choose to submit
                        your information to us for any other reason in any other
                        form, we will collect such information and use it for
                        the purposes for which you submitted it.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Visiting our Site. We will not collect any Personal
                        Information from you simply by virtue of your visiting
                        the Site; we only collect Personal Information if you
                        choose to submit it to us. We do, however, collect
                        Non-Personal Information such as Usage Data and
                        anonymous voting data, whenever you use the Site and/or
                        Services. If prohibited in your jurisdiction, such
                        Non-Personal Information is not combined with Personal
                        Information.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Where permitted by law. We may also collect information,
                        including Personal Information as otherwise permitted by
                        law.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Browser privacy preference. We will use reasonable
                        efforts to comply with the privacy preference setting in
                        any browser, but generally do not comply with any
                        Do-Not-Track requests on browsers.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Email messages. We may collect Non-Personal Information
                        through emails we send you which contain code that
                        allows us to track whether the message was opened and/or
                        links were clicked.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Cookies. In connection with the foregoing collection of
                        information, we may also use “cookies” or similar
                        technologies (small amounts of data that are stored on
                        your computer’s hard drive when you use or access the
                        Site and/or Services that identify your computer and may
                        store information about you such as behavioural data).
                        Should you choose to submit Personal Information to us,
                        we may link cookie information to such Personal
                        Information. If you do not wish to accept cookies, you
                        have the option of blocking or disabling cookies.
                        However, please be aware that some of the Site and/or
                        Services will not function properly if you do so and you
                        {/* may lose access to Services you purchased. In light of
                        the above, if you are a Child and happen to be in a
                        jurisdiction in which COPPA applies, we will not collect
                        any Personal Information with cookies and will not link
                        any cookie information with Personal Information without
                        verifiable parental consent. */}
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Third party advertisers. No third-party advertising is
                        permitted on the Site.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        User-generated content. You can enter debate topics with
                        few restrictions other than length.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    We strongly advise you not to submit Personal Information as
                    part of UGC. However, if you do, the Personal Information
                    will be stored in our database and treated the same as other
                    Personal Information collected from you.
                </Typography>
                <Typography variant="body1" align="left">
                    Public Disclosure of User Generated Content and Other
                    Internet Activity By default, all UGC is visible only to
                    Bottymouth, and is not shared publicly.
                </Typography>
                <Typography variant="body1" align="left">
                    You may choose to disclose information about yourself in the
                    course of creating User Generated Content to us or through
                    your use of the Site and/or Services. You may copy and share
                    a “Share Link” on the Site for any of your completed
                    debates. Debates viewable via a Share Link never include
                    your name, nor the topic description you input. However, the
                    debate title and other details are derived, through the use
                    of AI, from your topic description.
                </Typography>
                <Typography variant="body1" align="left">
                    We are not responsible for the Personal Information you
                    choose to make public through a Share Link. Please see our
                    Terms of Use at{' '}
                    {`${window.location.origin}${EPage.TERMS_OF_USE}`} for other
                    guidelines about sharing content on the Site.
                </Typography>
                <Typography variant="h3" align="left">
                    Use of Your Information
                </Typography>
                <Typography variant="body1" align="left">
                    We may use your information (including Personal Information)
                    for the following purposes:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        to provide you with any services or functionality you
                        have requested, including the Services;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to improve the Site and/or Services, and to inform the
                        creation of future Services;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to process transactions for the Services;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to manage your Account with Bottymouth;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to respond to customer service inquiries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to troubleshoot problems with the Site and/or Services;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to enforce our Terms of Use (available at
                        {`${window.location.origin}${EPage.TERMS_OF_USE}`}
                        );
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to protect against unlawful activities or other misuse
                        of the Site and/or and Services or for other security
                        reasons;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to compile statistics;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to allow you to share your User Generated Content with
                        your colleagues, friends, family, or others in your
                        social network;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to authenticate your identity; and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        to integrate third-party authentication, namely Google
                        single sign-on.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    <strong>
                        Bottymouth does not use your information to train the
                        BOTvBOT AI.
                    </strong>
                </Typography>
                <Typography variant="body1" align="left">
                    By providing Personal Information through the access to, or
                    use of, the Site and any of the Services, you acknowledge,
                    consent and agree that we may use the Personal Information
                    for the purposes set out in this Privacy Policy and by
                    accessing, using, or installing any of the Site and/or
                    Services or submitting information to us you also agree that
                    we may use the Non-Personal Information for the purposes set
                    out in this Privacy Policy.
                </Typography>
                <Typography variant="h3" align="left">
                    SPECIFIC THIRD-PARTY SERVICES
                </Typography>
                <Typography variant="body1" align="left">
                    The Site makes use of the following third-party services:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Stripe.com is a payment processor, which we use to
                        collect credit card payments from users. This service
                        collects limited Personal Information, such as name and
                        credit card information.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Google facilitates single sign-on to the Site. We use it
                        to authenticate users and to retrieve the unique
                        identifier of the user, for the purposes of provisioning
                        and then enabling access to a Bottymouth account. These
                        services collect and pass to Bottymouth limited Personal
                        Information (unique identifier).
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Amazon Web Services is a set of cloud-based web hosting
                        services. We use it to host the Site, including the
                        database where user data are stored. This service does
                        not collect Personal Information, although it may store
                        (in an encrypted format) such data as collected and
                        stored by Bottymouth.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        OpenAI is a provider of advanced AI technology used for
                        generating text and synthesizing voices.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Google also provides Gemini, advanced AI technology that
                        we use to generate text, and Google Cloud, that we use
                        to synthesize voices.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Eleven Labs provoides AI-powered technology that we use
                        to synthesize voices.
                    </Typography>
                </Typography>
                <Typography variant="h3" align="left">
                    Disclosure of Information
                </Typography>
                <Typography variant="body1" align="left">
                    We will not transfer your Personal Information to third
                    parties beyond the specific third-party services listed
                    above. Notwithstanding the foregoing, we reserve the right
                    to disclose information, including Personal Information, if
                    we reasonably believe that we are required to do so by law
                    or legal process or if we are otherwise requested by any law
                    enforcement officer or agency acting under colour of law. In
                    addition, we reserve the right to disclose Personal
                    Information and Non-Personal Information in order to (a)
                    enforce the Terms of Use (available at
                    {`${window.location.origin}${EPage.TERMS_OF_USE}`}
                    ); (b) investigate and/or take action against unlawful
                    activity, suspected abuse or unauthorised use of the Site
                    and Services; (c) protect and defend the rights or property
                    of Bottymouth; or (d) act in urgent circumstances to protect
                    the safety or security of the public or users of the Site
                    and Services. We may also disclose your information
                    (including Personal Information) in connection with a
                    corporate re-organization, a merger or amalgamation with
                    another entity, or a sale of all or a substantial portion of
                    our assets provided that the information disclosed continues
                    to be used for the purposes permitted by this Privacy Policy
                    by the entity acquiring the information. In the event of a
                    merger, amalgamation, or sale, we will provide you notice by
                    email no fewer than 30 days in advance of said event. We
                    will take all steps necessary to inform all users of all
                    data collection conducted by legal authorities through all
                    legal channels.
                </Typography>
                <Typography variant="h3" align="left">
                    Protection of Your Information
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth has implemented reasonable physical and technical
                    measures to protect the information we collect or are
                    provided with from unauthorized access and against loss,
                    misuse or alteration by third parties, including but not
                    limited to:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Containment of database(s) inside a Virtual Private
                        Cloud (VPC), access to which is extremely restricted;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Encryption of database data in transit and at rest;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Use of SSL / HTTPS for all data transmission over the
                        Internet;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Multifactor authentication on administrator-level
                        access;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Code reviews and scans to monitor for security
                        vulnerabilities;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Firewalls, private keys, anti-virus protection, IP
                        address whitelists, and encrypted local hard drives.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    Further, while we attempt to ensure the integrity and
                    security of our network and systems, we cannot guarantee
                    that our security measures will prevent third-party
                    “hackers” from illegally obtaining access to this
                    information. We do not warrant or represent that your
                    information will be protected against, loss, misuse, or
                    alteration by third parties. No method of transmission over
                    the Internet, or method of electronic storage, is 100%
                    secure.
                </Typography>
                <Typography variant="body1" align="left">
                    BOTTYMOUTH STRIVES TO EXCEED COMMERCIALLY REASONABLE EFFORTS
                    TO PROTECT YOUR PERSONAL INFORMATION, HOWEVER, TO THE
                    MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY
                    GUARANTEE OF SECURITY IN CONNECTION WITH YOUR PERSONAL
                    INFORMATION.
                </Typography>
                <Typography variant="body1" align="left">
                    For further information on how we safeguard information we
                    collect or are provided with, contact us via email at{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    .
                </Typography>
                <Typography variant="h3" align="left">
                    Incident Response Plan
                </Typography>
                <Typography variant="body1" align="left">
                    If we ever discover or receive reports of a security breach,
                    we will take the following steps to address it.
                </Typography>
                <Typography variant="body1" align="left">
                    The staff member who detects or receives a report of a
                    breach will forward all details to Bottymouth’s privacy
                    officer at{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    .
                </Typography>
                <Typography variant="body1" align="left">
                    The privacy officer will:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Determine the severity of the potential impact. Is it
                        real or perceived? Is it still in progress? What data is
                        threatened and how critical is it? What is the impact on
                        the business should the attack succeed – minimal,
                        serious, or critical?
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        If the breach is real, determine the system(s) being
                        targeted, along with all relevant details such as the
                        attacker’s IP address.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Determine how the incident can be contained, and contain
                        it. This may involve changing passwords, encryption
                        keys, or other system access information.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Determine what data has been compromised, and who should
                        be notified about the incident.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Notify affected parties by email no more than seven
                        calendar days after the discovering of the breach,
                        including relevant details such as: the data that was
                        compromised; the measures being taken to prevent any
                        future such incidents.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Document the incident, including date detected, date
                        occurred, notifications issued, and response.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Consider how the intrusion could have been prevented,
                        and make changes to systems and/or policies accordingly.
                    </Typography>
                </Typography>
                <Typography variant="h3" align="left">
                    Retention of Your Information
                </Typography>
                <Typography variant="body1" align="left">
                    The data and information that we collect will be stored and
                    maintained by Bottymouth or our third-party service
                    providers until we no longer have legal or reasonable
                    business reasons to retain it, or earlier if you delete it
                    or instruct us to delete it, or until your Account is
                    terminated by us. We will retain data and information we
                    collect for a period of 60 days after your Account is
                    terminated, at which time it will be permanently deleted. We
                    intend to only retain data, including Dependent data, for as
                    long as is reasonably necessary to fulfill the purpose for
                    which the information was collected.
                </Typography>
                <Typography variant="body1" align="left">
                    To request deletion of your account, please email our
                    Privacy Office at{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>{' '}
                    with specific details, such as the email address on your
                    account and the date you wish it to be deleted. In order to
                    verify your identity and authorization to make a deletion
                    request, we will only correspond using the email address
                    associated with your Account.
                </Typography>
                <Typography variant="body1" align="left">
                    If you have not logged into your Bottymouth account for more
                    than 365 days, we reserve the right to terminate it.
                </Typography>
                <Typography variant="body1" align="left">
                    Currently, Bottymouth or our third-party service providers
                    retain and store information collected by, or provided to,
                    us in the cloud and on secure servers in Canada. You hereby
                    consent to Bottymouth storing any Personal Information you
                    provide to us on secure servers in Canada.
                </Typography>
                <Typography variant="h3" align="left">
                    Account Security
                </Typography>
                <Typography variant="body1" align="left">
                    If you access the Site and/or use any of the Services, you
                    are responsible for protecting the confidentiality of your
                    Account password and elected codes, and for restricting
                    access to your computer and you agree to accept
                    responsibility for all activities that occur under your
                    Account. Please notify us immediately if you detect
                    suspected misuse of your Account via email at:{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    .
                </Typography>
                <Typography variant="h3" align="left">
                    Access and Accuracy
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth will use commercially reasonable efforts to
                    provide you access to your Personal Information (to the
                    extent we are in possession of any) if you submit your
                    request for access via{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    . Bottymouth may charge you a reasonable fee for doing so.
                    Subject to applicable law
                    {/* , including COPPA, */}
                    Bottymouth reserves the right to deny access to your
                    Personal Information on any of the following grounds:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        when denial of access is required by law;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        when granting you access is reasonably likely to
                        negatively impact other people’s privacy;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        when granting access is, in our judgement and acting
                        reasonably, cost prohibitive; or
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        when we have reason to believe that such requests are
                        frivolous or made in bad faith.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    You are responsible for ensuring that all information
                    created through your access to and use of the Site and/or
                    Services is accurate, reliable and complete and you
                    acknowledge and accept that the use of such information is
                    at your own risk. We can only provide accurate Services if
                    we are in possession of your current and accurate
                    information, therefore, we ask that you keep any Personal
                    Information that you provide to us current and accurate. You
                    represent and warrant that all Personal Information you
                    provide us is true and accurate and relates to you and not
                    to any other person. If you believe that the Personal
                    Information maintained by Bottymouth about you is inaccurate
                    or incomplete, you may notify us by describing in detail any
                    inaccuracies or omissions via email at{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    . Following receipt of a properly submitted notice, we will,
                    within a reasonable time period and acting in our sole
                    discretion, use commercially reasonable efforts to either:
                    (a) amend or correct your Personal Information to reflect
                    corrected or additional information provided by you, or (b)
                    in connection with your Personal Information, make note of
                    any claimed inaccuracies or omissions reported in the notice
                    submitted by you.
                </Typography>
                <Typography variant="h3" align="left">
                    Our Policy Regarding Children
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth recognizes the privacy interest of children and
                    we encourage parents/guardians to take an active role in
                    their children’s use of the Site and/or Services. Users
                    under the age of majority in their local jurisdiction may
                    only use the Site and/or Services with parental consent and
                    if the User is a Child obtain and provide verifiable
                    parental consent to Bottymouth if required by applicable
                    laws.
                    {/* , for example COPPA. */}
                </Typography>
                <Typography variant="body1" align="left">
                    If Bottymouth discovers or if a Parent becomes aware that a
                    User under the age of majority in their local jurisdiction
                    has accessed the Site and/or Services on their own and
                    without the proper consent required in their jurisdiction,
                    or provided us with information without the proper consent,
                    please contact us at{' '}
                    <Link target="_blank" href="mailto:privacy@botvbot.com">
                        privacy@botvbot.com
                    </Link>
                    . We will delete such information from our files within a
                    reasonable time and within the timeframe required by
                    applicable laws.
                </Typography>
                <Typography variant="body1" align="left">
                    If you are below the age of majority in your local
                    jurisdiction, please obtain your Parent’s permission before
                    accessing or using any of the Site and/or Services and
                    before providing us with any Personal Information.
                </Typography>
                <Typography variant="body1" align="left">
                    In the interests of the safety and comfort of all users
                    under the age of majority in your local jurisdiction, we
                    reserve the right, acting in our sole discretion but without
                    any obligation, to restrict the access of any user to any
                    space on the Site.
                </Typography>
                <Typography variant="h3" align="left">
                    Governing Law
                </Typography>
                <Typography variant="body1" align="left">
                    Those who choose to use or access the Site and/or Services
                    from outside Canada do so on their own initiative and are
                    responsible for compliance with local laws, if and to the
                    extent local laws are applicable. Bottymouth is compliant
                    with US privacy laws, as listed above, but does not make the
                    same claim for all countries outside Canada. Notwithstanding
                    the foregoing, and recognizing the global nature of the
                    Internet, each viewer and user shall comply with all local
                    rules regarding online conduct and creation of acceptable
                    materials. This Privacy Policy and your legal relationship
                    with Bottymouth shall be governed by, and will be enforced,
                    construed, and interpreted in accordance with the laws of
                    the province of British Columbia, Canada and the federal
                    laws of Canada applicable therein, without regard to
                    principles of conflicts of law. All disputes between you and
                    Bottymouth will be resolved by, and you hereby submit to,
                    the exclusive jurisdiction of the courts of British Columbia
                    in Vancouver.
                </Typography>
                <Typography variant="h3" align="left">
                    International Transfer
                </Typography>
                <Typography variant="body1" align="left">
                    We may transfer information that we collect about you to
                    third parties across borders and from your country or
                    jurisdiction to other countries or jurisdictions around the
                    world. Please note that these countries and jurisdictions
                    may not have the same personal information protection laws
                    as your own jurisdiction, and you consent to the transfer of
                    information over international borders and the use and
                    disclosure of information about you, including Personal
                    Information, as described in this Privacy Policy. We or our
                    third-party service providers store your Personal
                    Information in Canada.
                </Typography>
                <Typography variant="h3" align="left">
                    QUESTIONS OR COMMENTS
                </Typography>
                <Typography variant="body1" align="left">
                    We welcome questions or comments about this Privacy Policy.
                    Please direct any questions or comments to the individual
                    below who oversees Bottymouth’s compliance with privacy
                    requirements:
                </Typography>
                <Box sx={{ marginLeft: '3rem' }}>
                    <Typography variant="body1" align="left">
                        Bottymouth Entertainment
                    </Typography>
                    <Typography variant="body1" align="left">
                        Attention: Privacy Officer
                    </Typography>
                    <Typography variant="body1" align="left">
                        Email:{' '}
                        <Link href="mailto:privacy@botvbot.com">
                            privacy@botvbot.com
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default PrivacyComponent;
