import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const contactTheme = createTheme(mainTheme, {
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: 0,
                    '& .MuiInputBase-input': {
                        fontSize: '2rem',
                        paddingRight: 0,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(15px, 15px) scale(1)',
                },
                shrink: {
                    transform: 'translate(10px, -15px) scale(0.5)',
                    padding: '0.5rem',
                    backgroundColor: '#202124',
                },
            },
        },
    },
});

export default contactTheme;
