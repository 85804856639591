import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Grid2,
    Link,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
    CheckCircleRounded as CheckCircle,
    KeyboardReturnRounded as KeyboardReturn,
    PendingRounded as Pending,
} from '@mui/icons-material';
import axios, { AxiosResponse } from 'axios';

import { IDebate, ITrendingDebate } from '../../model/interface';
import { ArrayHelper } from '../../helpers/Array';
import topicsTheme from '../../themes/topics';
import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../../redux/store';
import { UserHelper } from '../../helpers/User';
import {
    EDebateFormat,
    EDebateSpice,
    EDebateStatus,
    EPage,
} from '../../model/enum';
import StartupScreen from './Startup';
import {
    setDefaultFormat,
    setDefaultSpice,
    setErrorMessage,
    setTrendingDebates,
} from '../../redux/app/slice';
import SelectorComponent from '../components/debate/Selector';
import { checkAge } from '../../redux/user/actions';
import BusyComponent from '../components/Busy';
import { ERROR_MESSAGES } from '../../model/constant';
import { StringHelper } from '../../helpers/String';
import { ICON_SIZE_SMALL } from '../../styles';

const SearchScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const theme = useTheme();
    const textfieldRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState('');

    // from redux store
    const defaultSpice = useSelector(
        (state: RootState) => state.app.defaultSpice
    );
    const defaultFormat = useSelector(
        (state: RootState) => state.app.defaultFormat
    );
    const appState = useSelector((state: RootState) => state.app.appState);
    const trendingDebates = useSelector(
        (state: RootState) => state.app.trendingDebates
    );
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const userAuth = useSelector((state: RootState) => state.user.auth);

    // local state
    const [spice, setSpice] = useState<EDebateSpice>(defaultSpice);
    const [format, setFormat] = useState<EDebateFormat>(defaultFormat);
    const [busy, setBusy] = useState(false);
    const [activeTrendingDebates, setActiveTrendingDebates] = useState<
        ITrendingDebate[]
    >([]);

    const handleMoreClick = () => {
        navigate(EPage.LATEST);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitQuery();
        }
    };

    const handleBlur = () => {
        if (AppHelper.isMobileDevice()) {
            handleSubmitQuery();
        }
    };

    const handleSelectSpice = async (s: EDebateSpice) => {
        if (await dispatch(checkAge(s))) {
            setSpice(s);
            dispatch(setDefaultSpice(s));
        }
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        setFormat(f);
        dispatch(setDefaultFormat(f));
    };

    const handleDebateSelection = (debate: ITrendingDebate) => {
        navigate(
            AppHelper.makeDebatePath({
                key: debate.key,
                status: EDebateStatus.SHARING,
                topic: {
                    issue: debate.issue,
                },
            } as IDebate)
        );
    };

    const handleSubmitQuery = async () => {
        if (StringHelper.isEmpty(query)) {
            return;
        }

        setBusy(true);
        try {
            const response: AxiosResponse<{ debates: ITrendingDebate[] }> =
                await axios.get(
                    UserHelper.makeURL(apiUrl, '/search', { query }, userAuth)
                );
            if (ArrayHelper.isNotEmpty(response.data?.debates)) {
                dispatch(
                    setTrendingDebates({
                        format,
                        spice,
                        debates: response.data.debates,
                    })
                );
            }
        } catch (err) {
            dispatch(setErrorMessage(ArrayHelper.shuffle(ERROR_MESSAGES)[0]));
        } finally {
            setBusy(false);
        }
    };

    useEffect(() => {
        setActiveTrendingDebates(
            trendingDebates?.[format]?.[spice]?.filter(
                (d) => d.spice === spice && d.format === format
            ) || []
        );
    }, [spice, format, trendingDebates]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
            justifyContent="center"
            height={{ xs: 'auto', sm: 'auto', md: '100%' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: 4,
                    maxWidth: 1200,
                    paddingTop: 8,
                    paddingBottom: 8,
                }}
            >
                <SelectorComponent
                    action="Find"
                    selectedSpice={spice}
                    selectedFormat={format}
                    onSelectSpice={handleSelectSpice}
                    onSelectFormat={handleSelectFormat}
                />

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    flex="0 0 auto"
                    sx={{ gap: 1, width: '100%', maxWidth: 720 }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            position: 'relative',
                            gap: 1,
                            width: '100%',
                        }}
                    >
                        <TextField
                            inputRef={textfieldRef}
                            autoFocus={true}
                            variant="outlined"
                            spellCheck={false}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            fullWidth
                            margin="normal"
                            slotProps={{
                                htmlInput: {
                                    maxLength: 256,
                                },
                            }}
                        />
                        {StringHelper.isNotEmpty(query) && (
                            <KeyboardReturn
                                onClick={handleSubmitQuery}
                                sx={{
                                    ...ICON_SIZE_SMALL,
                                    ...{
                                        opacity: 0.7,
                                        position: 'absolute',
                                        top: '50%',
                                        right: theme.spacing(2),
                                        zIndex: 10,
                                        transform: 'translateY(-50%)',
                                        backgroundColor: '#202124',
                                    },
                                }}
                            />
                        )}
                    </Box>
                </Box>

                <ThemeProvider theme={topicsTheme}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Grid2 container spacing={1} justifyContent="center">
                            {activeTrendingDebates.map((debate, index) => (
                                <Grid2 key={index}>
                                    <Button
                                        color={
                                            debate.complete !== undefined
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        variant="contained"
                                        onClick={() =>
                                            handleDebateSelection(debate)
                                        }
                                        endIcon={
                                            debate.complete === true ? (
                                                <CheckCircle />
                                            ) : debate.complete === false ? (
                                                <Pending />
                                            ) : undefined
                                        }
                                    >
                                        {debate.issue}
                                    </Button>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Box>
                </ThemeProvider>

                <Typography
                    variant="caption"
                    align="center"
                    color="textSecondary"
                >
                    or
                    <br />
                    <Link onClick={handleMoreClick}>Browse new debates</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default SearchScreen;
