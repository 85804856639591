import {
    CloseRounded as Close,
    ShareRounded as Share,
} from '@mui/icons-material';
import { Box, Button, Fab, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import React, { useEffect } from 'react';
import { AppHelper } from '../../../helpers/App';
import { StringHelper } from '../../../helpers/String';
import { setSuccessMessage, setWarningMessage } from '../../../redux/app/slice';
import { useNavigate } from 'react-router-dom';
import { EDebateStatus, EPage } from '../../../model/enum';
import { DateTimeHelper } from '../../../helpers/DateTime';
import { LARGE_GAPS } from '../../../styles';

const ShareScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const debate = useSelector((state: RootState) => state.debate.active);

    const handleCopyLink = () => {
        const shareLink = AppHelper.makeShareLink(debate!);
        if (AppHelper.copyToClipboard(shareLink)) {
            dispatch(setSuccessMessage('Copied to your clipboard'));
        } else {
            dispatch(
                setWarningMessage(
                    'That didn’t work – please copy the URL from your address bar'
                )
            );
        }
    };

    const handleCloseClick = () => {
        navigate(EPage.HOME);
    };

    useEffect(() => {
        if (debate?.status === EDebateStatus.SHARING) {
            navigate(AppHelper.makeDebatePath(debate));
        }
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={{
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'center',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: LARGE_GAPS,
                    maxWidth: 960,
                }}
            >
                <Paper elevation={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: LARGE_GAPS,
                        }}
                    >
                        <Share />
                        <Typography variant="h1" align="center">
                            Keep this debate alive
                        </Typography>
                        <Typography variant="body1" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                debate!.created > DateTimeHelper.daysAgo(1)
                                    ? 'Paste the share link wherever you like – let’s find out what others think!'
                                    : 'Nobody’s gonna see it if you don’t share it with ’em!'
                            )}
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleCopyLink}
                            id="share-copy"
                        >
                            Click to copy link
                        </Button>
                    </Box>
                </Paper>
                <Fab onClick={handleCloseClick} id="share-close">
                    <Close />
                </Fab>
            </Box>
        </Box>
    );
};

export default ShareScreen;
