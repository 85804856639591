import { Box, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setStartupSeen } from '../../../redux/app/slice';
import legalTheme from '../../../themes/legal';
import TermsComponent from '../../components/legal/Terms';

const TermsScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={legalTheme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ paddingTop: '3rem' }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        maxWidth: 960,
                        gap: 4,
                    }}
                >
                    <TermsComponent />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default TermsScreen;
