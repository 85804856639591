import React from 'react';
import { Box, Link, ThemeProvider, Typography, useTheme } from '@mui/material';
import footerTheme from '../../themes/footer';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { EPage } from '../../model/enum';

const FooterComponent: React.FC = () => {
    const navigate = useNavigate();

    const thisYear = new Date().getFullYear();
    const yearRange = thisYear === 2024 ? thisYear : `2024-${thisYear}`;

    const handlePageClick = (page: EPage) => {
        navigate(page);
    };

    return (
        <ThemeProvider theme={footerTheme}>
            <Box
                component="footer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    // position: 'fixed',
                    // bottom: 0,
                    // left: 0,
                    width: '100%',
                    padding: '0.5rem 1rem 1rem 1rem',
                }}
            >
                <Typography align="center" variant="caption">
                    ©&nbsp;{yearRange} Bottymouth&nbsp;Entertainment.
                    All&nbsp;rights&nbsp;reserved.
                    <br />
                    <Link
                        id="footer-disclaimer"
                        onClick={() => handlePageClick(EPage.DISCLAIMER)}
                    >
                        Disclaimer
                    </Link>{' '}
                    |{' '}
                    <Link
                        id="footer-terms"
                        onClick={() => handlePageClick(EPage.TERMS_OF_USE)}
                    >
                        Terms&nbsp;of&nbsp;Use
                    </Link>{' '}
                    |{' '}
                    <Link
                        id="footer-aup"
                        onClick={() => handlePageClick(EPage.ACCEPTABLE_USE)}
                    >
                        Acceptable&nbsp;Use
                    </Link>{' '}
                    |{' '}
                    <Link
                        id="footer-privacy"
                        onClick={() => handlePageClick(EPage.PRIVACY_POLICY)}
                    >
                        Privacy&nbsp;Policy
                    </Link>
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default FooterComponent;
