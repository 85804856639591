import { ECurrency } from '../model/enum';

export class NumberHelper {
    public static formatCurrency(
        amountInCents: number | undefined,
        currency: ECurrency | undefined
    ): string {
        if (amountInCents === undefined || currency === undefined) {
            return '';
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: amountInCents < 100 ? 2 : 0,
            maximumFractionDigits: amountInCents < 100 ? 2 : 0,
        }).format(amountInCents / 100);
    }
}
