import {
    USER_PARAM_A,
    USER_PARAM_ADMIN,
    USER_PARAM_B,
    USER_PARAM_LOGIN_PROVIDER,
} from '../model/constant';
import { EIdentityProvider } from '../model/enum';
import { IUserAuth } from '../model/interface';
import { StringHelper } from './String';

export class UserHelper {
    public static makeURL = (
        baseUrl: string,
        path: string,
        params?: { [key: string]: string | number | boolean | undefined },
        auth?: Partial<IUserAuth>
    ): string => {
        const url = new URL(`${baseUrl}${path}`);
        if (params !== undefined) {
            Object.keys(params).forEach((key) => {
                if (params[key] !== undefined) {
                    url.searchParams.append(key, params[key]!.toString());
                }
            });
        }
        if (
            auth?.accessToken !== undefined &&
            auth?.passwordToken !== undefined
        ) {
            url.searchParams.append(USER_PARAM_A, auth.accessToken);
            url.searchParams.append(USER_PARAM_B, auth.passwordToken);
        }
        if (StringHelper.isNotEmpty(localStorage.getItem(USER_PARAM_ADMIN))) {
            url.searchParams.append(
                USER_PARAM_ADMIN,
                localStorage.getItem(USER_PARAM_ADMIN)!
            );
        }
        return url.toString();
    };

    public static stashIdProvider = (idProvider: EIdentityProvider) => {
        localStorage.setItem(USER_PARAM_LOGIN_PROVIDER, idProvider);
    };

    public static getStashedIdProvider = (): EIdentityProvider | undefined => {
        return (
            (localStorage.getItem(
                USER_PARAM_LOGIN_PROVIDER
            ) as EIdentityProvider) || undefined
        );
    };

    public static deleteStashedIdProvider = () => {
        localStorage.removeItem(USER_PARAM_LOGIN_PROVIDER);
    };
}
