import { createSlice } from '@reduxjs/toolkit';

interface IExitPopupState {
    isVisible: boolean;
    seen: boolean;
}

const initialState: IExitPopupState = {
    isVisible: false,
    seen: false,
};

const userSlice = createSlice({
    name: 'exitPopup',
    initialState,
    reducers: {
        showPopup: (state) => {
            state.isVisible = true;
        },
        hidePopup: (state) => {
            state.isVisible = false;
            state.seen = true;
        },
    },
});

export const { showPopup, hidePopup } = userSlice.actions;
export default userSlice.reducer;
