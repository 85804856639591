import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const legalTheme = createTheme(mainTheme, {
    typography: {
        h3: {
            marginTop: '2rem',
            fontSize: '2rem',
        },
        h4: {
            marginTop: '2rem',
            textTransform: 'uppercase',
            fontSize: '1.8rem',
        },
        body1: {
            fontSize: '1.4rem',
            lineHeight: 1.8,
            marginTop: '1rem',
        },
        caption: {
            marginBottom: '2rem',
        },
    },
});

export default legalTheme;
