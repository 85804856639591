import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAppState, EDebateFormat, EDebateSpice } from '../../model/enum';
import { ITrendingDebate } from '../../model/interface';

interface IAppState {
    startupSeen: boolean;
    appState: EAppState;
    defaultSpice: EDebateSpice;
    defaultFormat: EDebateFormat;
    errorMessage?: string;
    warningMessage?: string;
    successMessage?: string;
    recentTopic?: string;
    trendingDebates?: {
        [format: string]: { [spice: string]: ITrendingDebate[] };
    };
}

const DEFAULT_SPICE = 'defaultSpice';
const DEFAULT_FORMAT = 'defaultFormat';

const initialState: IAppState = {
    startupSeen: false,
    appState: EAppState.INITIALIZING,
    defaultSpice:
        (localStorage.getItem(DEFAULT_SPICE) as EDebateSpice) ||
        EDebateSpice.MILD,
    defaultFormat:
        // (localStorage.getItem(DEFAULT_FORMAT) as EDebateFormat) ||
        EDebateFormat.STANDARD,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setStartupSeen(state) {
            state.startupSeen = true;
        },
        setDefaultSpice(state, action: PayloadAction<EDebateSpice>) {
            localStorage.setItem(
                DEFAULT_SPICE,
                action.payload || EDebateSpice.MILD
            );
            state.defaultSpice = action.payload || EDebateSpice.MILD;
        },
        setTrendingDebates(
            state,
            action: PayloadAction<{
                format: EDebateFormat;
                spice: EDebateSpice;
                debates: ITrendingDebate[];
            }>
        ) {
            state.trendingDebates = state.trendingDebates || {};
            state.trendingDebates[action.payload.format] =
                state.trendingDebates[action.payload.format] || {};
            state.trendingDebates[action.payload.format][action.payload.spice] =
                action.payload.debates;
        },
        setDefaultFormat(state, action: PayloadAction<EDebateFormat>) {
            localStorage.setItem(
                DEFAULT_FORMAT,
                action.payload || EDebateFormat.STANDARD
            );
            state.defaultFormat = action.payload || EDebateFormat.STANDARD;
        },
        setAppState(state, action: PayloadAction<EAppState>) {
            state.appState = action.payload;
        },
        setErrorMessage(state, action: PayloadAction<string | undefined>) {
            state.errorMessage = action.payload;
        },
        setWarningMessage(state, action: PayloadAction<string | undefined>) {
            state.warningMessage = action.payload;
        },
        setSuccessMessage(state, action: PayloadAction<string | undefined>) {
            state.successMessage = action.payload;
        },
        setRecentTopic: (state, action: PayloadAction<string | undefined>) => {
            state.recentTopic = action.payload;
        },
    },
});

export const {
    setStartupSeen,
    setDefaultSpice,
    setTrendingDebates,
    setDefaultFormat,
    setAppState,
    setErrorMessage,
    setWarningMessage,
    setSuccessMessage,
    setRecentTopic,
} = appSlice.actions;
export default appSlice.reducer;
