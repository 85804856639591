import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const pollTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '1.4rem',
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                elevation4: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: mainTheme.spacing(5),
                    paddingTop: mainTheme.spacing(3),
                    gap: '0.7rem',
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: mainTheme.spacing(2),
                        paddingBottom: mainTheme.spacing(4),
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'DynaPuff, serif',
                    textTransform: 'none',
                    lineHeight: 1.2,
                    position: 'relative',
                    minWidth: mainTheme.spacing(18),
                    minHeight: mainTheme.spacing(12),
                    [mainTheme.breakpoints.down('sm')]: {
                        fontSize: '1.8rem',
                        lineHeight: 1.3,
                        paddingLeft: mainTheme.spacing(4),
                        paddingRight: mainTheme.spacing(4),
                    },
                    '& .MuiButton-endIcon': {
                        position: 'absolute',
                        top: 0,
                        right: mainTheme.spacing(1),
                    },
                },
                contained: {
                    '&.Mui-disabled': {
                        color: '#000',
                        backgroundColor: '#fff',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    width: mainTheme.spacing(10),
                    height: mainTheme.spacing(10),
                },
            },
        },
    },
});

export default pollTheme;
