import axios, { AxiosResponse } from 'axios';
import { AppDispatch, RootState } from '../store';
import {
    USER_PARAM_A,
    USER_PARAM_B,
    USER_PARAM_LOGIN_PROVIDER,
} from '../../model/constant';
import { StringHelper } from '../../helpers/String';
import { setAuth, setCreditBalance, setUserInfo } from './slice';
import { setAppState, setDefaultSpice, setWarningMessage } from '../app/slice';
import { IUser, IUserAuth, IUserLoadResponse } from '../../model/interface';
import { UserHelper } from '../../helpers/User';
import {
    EAppState,
    EDebateSpice,
    EIdentityProvider,
    EReturnCode,
} from '../../model/enum';
import { getIsAgeAppropriate, promptForAge } from '../debate/actions';
import { loadRedditPixel } from '../app/actions';

const loadUser =
    () =>
    async (dispatch: AppDispatch): Promise<EReturnCode> => {
        // console.log('loadUser');
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

        // load user
        // check local storage for user key
        let userKeyA: string | null = localStorage.getItem(USER_PARAM_A);
        let userKeyB: string | null = localStorage.getItem(USER_PARAM_B);
        let userKeyC: string | null = localStorage.getItem(
            USER_PARAM_LOGIN_PROVIDER
        );
        // console.log('local storage', userKeyA, userKeyB, userKeyC);
        if (StringHelper.isEmpty(userKeyA) || StringHelper.isEmpty(userKeyB)) {
            if (userKeyC === EIdentityProvider.GOOGLE) {
                return EReturnCode.REDIRECT_TO_SSO;
            } else {
                // console.log('No stored credentials found');
                // get user ID from API
                const response: AxiosResponse<{ auth: IUserAuth }> =
                    await axios.post(UserHelper.makeURL(apiUrl, '/user'));
                if (response.data?.auth !== undefined) {
                    const userAuth: IUserAuth = response?.data?.auth;
                    // console.log('Got new user auth', userAuth);
                    if (userAuth.accessToken && userAuth.passwordToken) {
                        // save user key to local storage
                        localStorage.setItem(
                            USER_PARAM_A,
                            userAuth.accessToken
                        );
                        localStorage.setItem(
                            USER_PARAM_B,
                            userAuth.passwordToken
                        );
                        userKeyA = localStorage.getItem(USER_PARAM_A);
                        userKeyB = localStorage.getItem(USER_PARAM_B);
                    }
                }
            }
        }

        if (
            StringHelper.isNotEmpty(userKeyA) &&
            StringHelper.isNotEmpty(userKeyB)
        ) {
            // console.log('Validating user...');
            // validate user key
            const response: AxiosResponse<IUserLoadResponse> = await axios.get(
                UserHelper.makeURL(
                    apiUrl,
                    '/user',
                    {},
                    {
                        accessToken: userKeyA!,
                        passwordToken: userKeyB!,
                    }
                )
            );
            const userResponse: IUserLoadResponse | undefined = response.data;
            await dispatch(processLoggedInUser(userResponse));
        } else {
            throw new Error('Missing user key');
        }
        return EReturnCode.SUCCESS;
    };

const updateDOB =
    (dob: number) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        // console.log('updateUser');
        const state = getState();
        const userAuth = state.user.auth;
        if (userAuth !== undefined) {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

            const response: AxiosResponse<{ success: boolean }> =
                await axios.put(
                    UserHelper.makeURL(apiUrl, '/user', {}, userAuth),
                    { dob }
                );
            if (response.data.success === true) {
                dispatch(setUserInfo({ ...state.user.info!, dob }));
                return;
            }
        }
        throw new Error('Failed to save date of birth');
    };

const checkAge =
    (spice: EDebateSpice) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        const isAppropriateAge = await dispatch(getIsAgeAppropriate(spice));
        if (!isAppropriateAge) {
            if (state.user.info?.dob === undefined) {
                await dispatch(promptForAge());
            } else {
                dispatch(setWarningMessage('Sorry, you’re not old enough'));
            }
            return false;
        }
        return true;
    };

const logout =
    () =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        // console.log('logout');
        localStorage.removeItem(USER_PARAM_A);
        localStorage.removeItem(USER_PARAM_B);
    };

const processLoggedInUser =
    (userResponse: IUserLoadResponse | undefined) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        // console.log('processLoggedInUser', userResponse);
        if (
            userResponse?.auth !== undefined &&
            userResponse?.user !== undefined
        ) {
            // console.log('Valid user');
            dispatch(setCreditBalance(userResponse.creditBalance || 0));

            const userInfo: IUser = userResponse.user;
            dispatch(setUserInfo(userInfo));

            await dispatch(enforceAgeAppropriate());

            // init tracking
            await dispatch(loadRedditPixel(userInfo.key));

            const userAuth: IUserAuth = userResponse.auth;
            dispatch(setAuth(userAuth));
            UserHelper.stashIdProvider(userAuth.idProvider);

            // update password token
            localStorage.setItem(USER_PARAM_A, userAuth.accessToken);
            localStorage.setItem(USER_PARAM_B, userAuth.passwordToken);
            dispatch(setAppState(EAppState.LOGGED_IN));
        } else {
            dispatch(logout());
            throw new Error('Invalid user key');
        }
    };

const enforceAgeAppropriate =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const defaultSpice = getState().app.defaultSpice;
        const isAppropriateAge = await dispatch(
            getIsAgeAppropriate(defaultSpice)
        );
        if (!isAppropriateAge) {
            dispatch(setDefaultSpice(EDebateSpice.MEDIUM));
        }
    };

const saveDisclaimer =
    () =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const state = getState();
        const userAuth = state.user.auth;

        const response: AxiosResponse<{ success: boolean }> = await axios.post(
            UserHelper.makeURL(apiUrl, '/disclaimer', {}, userAuth)
        );
        if (response.data.success === true) {
            const updatedUser: IUser = { ...state.user.info!, disclaimer: 1 };
            await dispatch(setUserInfo(updatedUser));
            return true;
        }
        return false;
    };

const joinMailingList =
    (email?: string, name?: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/newsletter`;
        const state = getState();
        const userAuth = state.user.auth;

        try {
            const response: AxiosResponse<{ success: boolean }> =
                await axios.post(
                    UserHelper.makeURL(apiUrl, '/join', {}, userAuth),
                    { email, name }
                );
            if (response.data.success === true) {
                return true;
            }
            return false;
        } catch (error) {
            dispatch(setWarningMessage('Failed to join newsletter'));
            return false;
        }
    };

const flagNewsletterSeen =
    () =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/newsletter`;
        const state = getState();
        const userAuth = state.user.auth;

        try {
            const response: AxiosResponse<{ success: boolean }> =
                await axios.post(
                    UserHelper.makeURL(apiUrl, '/seen', {}, userAuth)
                );
            if (response.data.success === true) {
                const updatedUser: IUser = {
                    ...state.user.info!,
                    newsletterSeen: 1,
                };
                await dispatch(setUserInfo(updatedUser));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(setWarningMessage('Failed to flag newsletter seen'));
            return false;
        }
    };

export {
    loadUser,
    logout,
    processLoggedInUser,
    updateDOB,
    checkAge,
    saveDisclaimer,
    enforceAgeAppropriate,
    joinMailingList,
    flagNewsletterSeen,
};
