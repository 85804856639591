import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    MenuRounded as MenuIcon,
    CheckCircle,
    CreditCardRounded as CreditCard,
    EditRounded as Edit,
    ExploreRounded as Explore,
    GavelRounded as Gavel,
    InfoRounded as Info,
    LockRounded as Lock,
    MailRounded as Mail,
    SearchRounded as Search,
    SettingsRounded as Settings,
    ThumbUpAltRounded as ThumbUpAlt,
    WarningRounded as Warning,
    SendRounded,
    VpnKeyRounded,
    LogoutRounded,
    Savings,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import menuTheme from '../../themes/menu';
import LogoComponent from './Logo';
import {
    AppBar,
    ButtonBase,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store';
import { EPage } from '../../model/enum';
import { TRUE } from '../../model/constant';
import { resetDebate } from '../../redux/debate/slice';
import { googleLogout } from '@react-oauth/google';
import { logout } from '../../redux/user/actions';
import { AppHelper } from '../../helpers/App';

const MenuComponent: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const userCredits = useSelector(
        (state: RootState) => state.user.creditBalance
    );
    const menuOpen = Boolean(anchorEl);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const showLogout = AppHelper.isAdmin();

    // auto-hiding menu
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showMenu, setShowMenu] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 20 && currentScrollY > lastScrollY) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageClick = (page: EPage) => {
        handleClose();
        dispatch(resetDebate());
        navigate(page);
    };

    const handleAdminLogout = () => {
        googleLogout();
        dispatch(logout());
        navigate(EPage.ACCOUNT_SSO);
        AppHelper.reload();
    };

    // const handleLogoutClick = () => {
    //     googleLogout();
    //     dispatch(logout());
    //     navigate(EPage.HOME);
    //     AppHelper.reload();
    // };

    // const handleLoginClick = () => {
    //     handleClose();
    //     navigate(EPage.LOGIN);
    // };

    return (
        <AppBar
            position="fixed"
            sx={{
                transition: 'transform 0.3s ease-in-out',
                transform: showMenu ? 'translateY(0)' : 'translateY(-100%)',
                height: theme.spacing(14),
                backgroundColor: '#202124',
                boxShadow: 'none',
                backgroundImage: 'none',
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ height: '100%' }}
            >
                <Box sx={{ width: '1.8em', padding: 1 }} />
                <ButtonBase
                    id="menu-logo"
                    sx={{ borderRadius: '50%', overflow: 'visible' }}
                    onClick={() => handlePageClick(EPage.HOME)}
                >
                    <LogoComponent size="small" />
                </ButtonBase>
                <Box
                    display="flex"
                    alignItems="flex-start"
                    sx={{
                        position: 'relative',
                        gap: { xs: 1, sm: 1, md: 2 },
                        '@media print': { display: 'none' },
                    }}
                >
                    <Box sx={{ position: 'absolute', left: 0, top: 0 }}>
                        <ButtonBase
                            id="menu-credits"
                            sx={{
                                opacity: 0.54,
                                right: 0,
                                height: { xs: 47, sm: 47, md: 57 },
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                padding: '0 0.5rem',
                            }}
                            onClick={() => navigate(EPage.ACCOUNT_CREDITS)}
                        >
                            <Typography variant="body2">
                                {userCredits}
                            </Typography>
                            <Savings sx={{ width: '2rem', height: '2rem' }} />
                        </ButtonBase>
                    </Box>
                    <IconButton
                        id="menu-button"
                        aria-controls={menuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? TRUE : undefined}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <ThemeProvider theme={menuTheme}>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'menu-button',
                            }}
                        >
                            {userInfo?.admin && (
                                <Stack>
                                    <MenuItem
                                        onClick={() =>
                                            handlePageClick(EPage.ADMIN)
                                        }
                                    >
                                        {location.pathname === EPage.ADMIN ? (
                                            <ListItemIcon>
                                                <CheckCircle
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </ListItemIcon>
                                        ) : (
                                            <ListItemIcon>
                                                <VpnKeyRounded fontSize="small" />
                                            </ListItemIcon>
                                        )}
                                        <ListItemText>Admin</ListItemText>
                                    </MenuItem>
                                    <Divider />
                                </Stack>
                            )}
                            {!userInfo?.admin && showLogout && (
                                <Stack>
                                    <MenuItem
                                        id="menu-dropdown-logout"
                                        onClick={handleAdminLogout}
                                    >
                                        <ListItemIcon>
                                            <LogoutRounded fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Log out</ListItemText>
                                    </MenuItem>
                                    <Divider />
                                </Stack>
                            )}
                            <MenuItem
                                id="menu-dropdown-debates"
                                onClick={() =>
                                    handlePageClick(EPage.ACCOUNT_DEBATES)
                                }
                            >
                                {[
                                    EPage.ACCOUNT_DEBATES,
                                    EPage.ACCOUNT_CREDITS,
                                    EPage.ACCOUNT_SSO,
                                ].includes(location.pathname as EPage) ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Your account</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-credits"
                                onClick={() => handlePageClick(EPage.CREDITS)}
                            >
                                {location.pathname === EPage.CREDITS ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <CreditCard fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Get credits</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                id="menu-dropdown-home"
                                onClick={() => handlePageClick(EPage.HOME)}
                            >
                                {location.pathname === EPage.HOME ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Explore fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Browse debates</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-create"
                                onClick={() => handlePageClick(EPage.CREATE)}
                            >
                                {location.pathname === EPage.CREATE ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Start a debate</ListItemText>
                            </MenuItem>
                            {/* <MenuItem
                                id='menu-dropdown-search'
                                onClick={() => handlePageClick(EPage.SEARCH)}
                            >
                                {location.pathname === EPage.SEARCH ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Search fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Search debates</ListItemText>
                            </MenuItem> */}
                            <Divider />
                            <MenuItem
                                id="menu-dropdown-disclaimer"
                                onClick={() =>
                                    handlePageClick(EPage.DISCLAIMER)
                                }
                            >
                                {location.pathname === EPage.DISCLAIMER ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Warning fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Disclaimer</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-terms"
                                onClick={() =>
                                    handlePageClick(EPage.TERMS_OF_USE)
                                }
                            >
                                {location.pathname === EPage.TERMS_OF_USE ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Gavel fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Terms of Use</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-aup"
                                onClick={() =>
                                    handlePageClick(EPage.ACCEPTABLE_USE)
                                }
                            >
                                {location.pathname === EPage.ACCEPTABLE_USE ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <ThumbUpAlt fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Acceptable Use</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-privacy"
                                onClick={() =>
                                    handlePageClick(EPage.PRIVACY_POLICY)
                                }
                            >
                                {location.pathname === EPage.PRIVACY_POLICY ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Lock fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Privacy Policy</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                id="menu-dropdown-contact"
                                onClick={() => handlePageClick(EPage.CONTACT)}
                            >
                                {location.pathname === EPage.CONTACT ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Mail fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Contact form</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-newsletter"
                                onClick={() =>
                                    handlePageClick(EPage.NEWSLETTER)
                                }
                            >
                                {location.pathname === EPage.NEWSLETTER ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <SendRounded fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Mailing list</ListItemText>
                            </MenuItem>
                            <MenuItem
                                id="menu-dropdown-about"
                                onClick={() => handlePageClick(EPage.ABOUT)}
                            >
                                {location.pathname === EPage.ABOUT ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Info fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>About BOTvBOT</ListItemText>
                            </MenuItem>
                            {/* {idProvider === EIdentityProvider.GOOGLE && (
                            <MenuItem id='menu-dropdown-logout-google' onClick={handleLogoutClick}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Sign out</ListItemText>
                            </MenuItem>
                        )}
                        {idProvider === EIdentityProvider.BOTVBOT && (
                            <MenuItem id='menu-dropdown-logout-botvbot' onClick={handleLoginClick}>
                                {location.pathname === EPage.LOGIN ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Login fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>
                                    {stashedIdProvider === undefined
                                        ? 'Connect to Google'
                                        : 'Sign in'}
                                </ListItemText>
                            </MenuItem>
                        )} */}
                        </Menu>
                    </ThemeProvider>
                </Box>
            </Box>
        </AppBar>
    );
};

export default MenuComponent;
