import { createTheme } from '@mui/material/styles';
import legalTheme from './legal';
import { grey } from '@mui/material/colors';

const legalThemeSmall = createTheme(legalTheme, {
    typography: {
        h1: {
            fontSize: '1.8rem',
            textTransform: 'uppercase',
        },
        h3: {
            marginTop: '0.8rem',
            fontSize: '1rem',
        },
        h4: {
            marginTop: '0.8rem',
            textTransform: 'uppercase',
            fontSize: '0.8rem',
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            color: grey[400],
            marginTop: 0,
        },
        caption: {
            fontSize: '0.8rem',
            marginTop: '0.8rem',
            marginBottom: '0.8rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                },
            },
        },
    },
});

export default legalThemeSmall;
