import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    EDebateSpice,
    EDebateFormat,
    EPage,
    EAiProvider,
} from '../../model/enum';
import { StringHelper } from '../../helpers/String';
import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../../redux/store';
import { createDebate } from '../../redux/debate/actions';
import { useNavigate } from 'react-router-dom';
import {
    setDefaultFormat,
    setDefaultSpice,
    setRecentTopic,
} from '../../redux/app/slice';
import SelectorComponent from '../components/debate/Selector';
import StartupScreen from './Startup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { KeyboardReturnRounded as KeyboardReturn } from '@mui/icons-material';
import { checkAge } from '../../redux/user/actions';
import BusyComponent from '../components/Busy';
import { ICON_SIZE_SMALL } from '../../styles';
import dialogTheme from '../../themes/dialog';
import TipsComponent from '../components/debate/Tips';
import { MAX_PROMPT_LENGTH as MAX_TOPIC_LENGTH } from '../../model/constant';
import { setActiveDebate } from '../../redux/debate/slice';

const CreateScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const theme = useTheme();
    const defaultSpice = useSelector(
        (state: RootState) => state.app.defaultSpice
    );
    const defaultFormat = useSelector(
        (state: RootState) => state.app.defaultFormat
    );
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const recentTopic = useSelector(
        (state: RootState) => state.app.recentTopic
    );
    const debate = useSelector((state: RootState) => state.debate.active);
    const credits = useSelector((state: RootState) => state.user.creditBalance);
    const [topic, setTopic] = useState(recentTopic || '');
    const [busy, setBusy] = useState<boolean>(false);
    const [spice, setSpice] = useState<EDebateSpice>(defaultSpice);
    const [format, setFormat] = useState<EDebateFormat>(defaultFormat);
    const [aiProvider, setAiProvider] = useState<EAiProvider | undefined>();
    const [tipsShown, setTipsShown] = useState<boolean>(false);
    const textfieldRef = useRef<HTMLInputElement>(null);
    const activeRef = useRef<boolean>();
    const isMobile = AppHelper.isMobileDevice();

    const handleSelectSpice = async (s: EDebateSpice) => {
        if (await dispatch(checkAge(s))) {
            setSpice(s);
            dispatch(setDefaultSpice(s));
            focusInput();
        }
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        setFormat(f);
        dispatch(setDefaultFormat(f));
        focusInput();
    };

    const handleSelectAiProvider = (p: EAiProvider) => {
        setAiProvider(p);
        focusInput();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitTopic();
        }
    };

    const handleBlur = () => {
        if (isMobile) {
            handleSubmitTopic();
        }
    };

    const handleDialogClose = () => {
        setTipsShown(false);
    };

    const handleSubmitTopic = async () => {
        if (StringHelper.isEmpty(topic)) {
            return;
        }

        if (credits < AppHelper.getCreditCost(format)) {
            dispatch(setRecentTopic(topic));
            navigate(EPage.CREDITS);
            return;
        }

        setBusy(true);
        const result = await dispatch(
            createDebate(topic, spice, format, aiProvider)
        );
        if (result && activeRef.current) {
            dispatch(setActiveDebate(result));
            dispatch(setRecentTopic(undefined));
        } else {
            setBusy(false);
            if (result === null) {
                navigate(EPage.CREDITS);
            }
        }
    };

    const focusInput = () => {
        textfieldRef.current?.focus();
    };

    useEffect(() => {
        if (debate !== undefined && activeRef.current) {
            navigate(AppHelper.makeDebatePath(debate));
        }
    }, [debate]);

    useEffect(() => {
        activeRef.current = true;
        return () => {
            activeRef.current = false;
        };
    }, []);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    return busy ? (
        <BusyComponent />
    ) : (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ gap: 3, height: '100%' }}
            >
                <SelectorComponent
                    action="Start"
                    selectedSpice={spice}
                    selectedFormat={format}
                    selectedAiProvider={aiProvider}
                    onSelectSpice={handleSelectSpice}
                    onSelectFormat={handleSelectFormat}
                    onSelectAiProvider={handleSelectAiProvider}
                />
                <Box
                    display="flex"
                    flexDirection="column"
                    flex="0 0 auto"
                    sx={{ gap: 1, width: '100%' }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        flex="0 0 auto"
                        sx={{ gap: 1 }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                position: 'relative',
                                gap: 1,
                                width: '100%',
                            }}
                        >
                            <TextField
                                inputRef={textfieldRef}
                                multiline={true}
                                autoFocus={true}
                                variant="outlined"
                                spellCheck={false}
                                value={topic}
                                onChange={(e) => setTopic(e.target.value)}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                fullWidth
                                margin="normal"
                                maxRows={8}
                                slotProps={{
                                    htmlInput: {
                                        maxLength: MAX_TOPIC_LENGTH,
                                    },
                                }}
                            />
                            <Button
                                onClick={handleSubmitTopic}
                                disabled={StringHelper.isEmpty(topic)}
                                sx={{
                                    color: '#fff',
                                    minWidth: 0,
                                    padding: {
                                        xs: theme.spacing(1),
                                        sm: theme.spacing(1),
                                        md: theme.spacing(1),
                                    },
                                    paddingBottom: isMobile
                                        ? theme.spacing(1)
                                        : '0 !important',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    bottom: isMobile ? 12 : 4,
                                    right: '4px',
                                    zIndex: 10,
                                    backgroundColor: '#202124',
                                    // opacity: 0.7,
                                }}
                            >
                                {!isMobile && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            width: { xs: 32, sm: 40, md: 48 },
                                            fontSize: '1rem',
                                        }}
                                    >
                                        Enter
                                    </Typography>
                                )}
                                <KeyboardReturn
                                    sx={{
                                        ...ICON_SIZE_SMALL,
                                    }}
                                />
                            </Button>
                        </Box>
                    </Box>
                    <Typography
                        variant="caption"
                        align="center"
                        color="textSecondary"
                    >
                        <Link
                            sx={{ opacity: 0.7, '&:hover': { opacity: 1 } }}
                            onClick={() => {
                                setTipsShown(true);
                            }}
                        >
                            Useful Tips
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <ThemeProvider theme={dialogTheme}>
                <Dialog
                    open={tipsShown}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle variant="h1">Useful Tips</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <TipsComponent />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setTipsShown(false)}
                        >
                            Got it
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    );
};

export default CreateScreen;
