import React from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { ArrayHelper } from '../../../helpers/Array';
import tipsTheme from '../../../themes/tips';

const TipsComponent: React.FC = () => {
    return (
        <ThemeProvider theme={tipsTheme}>
            <Box
                display="flex"
                flexDirection="column"
                sx={{ gap: 2, paddingTop: '2rem' }}
            >
                <Typography variant="body1" align="left">
                    Your debate topic can be as simple as &ldquo;
                    {ArrayHelper.shuffle(['Chocolate', 'Vanilla']).join(
                        ' vs. '
                    )}
                    &rdquo;
                    <Typography
                        component="span"
                        variant="body1"
                        color="primary"
                    >
                        *
                    </Typography>{' '}
                    and BOTvBOT will fill in the rest&mdash;including random
                    debate participants.
                </Typography>
                <Typography variant="body1" align="left">
                    Other things to try:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    component="ul"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 3,
                        gap: 1,
                    }}
                >
                    <Typography variant="body1" align="left" component="li">
                        &ldquo;Would you rather?&rdquo; questions, such as
                        &ldquo;Would you rather have the ability to teleport, or
                        fly?&rdquo;
                    </Typography>
                    {/* <Typography variant="body1" align="left" component="li">
                    Superlatives, such as &ldquo;Who was the greatest human
                    being alive in the 16th century?&rdquo;
                </Typography> */}
                    <Typography variant="body1" align="left" component="li">
                        Questions such as &ldquo;How do we ensure long-term
                        human prosperity?&rdquo;
                    </Typography>
                    <Typography variant="body1" align="left" component="li">
                        Absurdities such as &ldquo;Pros and cons of gardening
                        with only a teaspoon?&rdquo;
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    Alternatively, you can also includes details about:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    component="ul"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 3,
                        gap: 1,
                    }}
                >
                    <Typography variant="body1" align="left" component="li">
                        the debate location
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                        >
                            **
                        </Typography>
                    </Typography>
                    <Typography variant="body1" align="left" component="li">
                        the debaters:
                    </Typography>
                    <Typography
                        variant="body1"
                        align="left"
                        component="ul"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 3,
                            gap: 1,
                        }}
                    >
                        <Typography variant="body1" align="left" component="li">
                            gender [
                            {ArrayHelper.shuffle(['male', 'female']).join(
                                ' or '
                            )}
                            ]
                        </Typography>
                        <Typography variant="body1" align="left" component="li">
                            age [25 to 65]
                        </Typography>
                        <Typography variant="body1" align="left" component="li">
                            hometown or location
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                            >
                                **
                            </Typography>
                        </Typography>
                        <Typography variant="body1" align="left" component="li">
                            role or occupation
                        </Typography>
                        {/* <Typography variant="body1" align="left" component="li">
                            personality traits
                        </Typography> */}
                    </Typography>
                </Typography>
                <Typography variant="body2" align="left">
                    <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                    >
                        *
                    </Typography>{' '}
                    If your topic involves events that occurred after October,
                    2023, be sure to specify details that indicate that fact,
                    such as the date and/or specific people or places, e.g. "Why
                    did Kalama Harris lose the 2024 US election?"
                </Typography>
                <Typography variant="body2" align="left">
                    <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                    >
                        **
                    </Typography>{' '}
                    Locations are currently limited to{' '}
                    {ArrayHelper.shuffle([
                        'Canada',
                        'the US',
                        'the UK',
                        'Australia',
                    ]).join(', ')}
                    .
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default TipsComponent;
