import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { ECurrency } from '../../../model/enum';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { NumberHelper } from '../../../helpers/Number';
import { AppHelper } from '../../../helpers/App';
import { StringHelper } from '../../../helpers/String';
import shopButtonTheme from '../../../themes/shopButton';

interface IProps {
    amount: number | undefined;
    currency: ECurrency | undefined;
    credits: number | undefined;
    returnUrl: string;
}

const StripeComponent: React.FC<IProps> = ({
    amount,
    currency,
    credits,
    returnUrl,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(true);

    const handlePaymentElementReady = () => {
        setLoading(false);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        AppHelper.scrollToTop();
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        if (result.error) {
            console.error(result.error.message);
        }

        setLoading(false);
    };

    return (
        <>
            {(loading || amount === undefined || currency === undefined) && (
                <CircularProgress />
            )}
            <Paper
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    width: '100%',
                    visibility:
                        amount === undefined || currency === undefined
                            ? 'hidden'
                            : 'visible',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        gap: 4,
                    }}
                >
                    <PaymentElement onReady={handlePaymentElementReady} />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: 2 }}
                    >
                        <ThemeProvider theme={shopButtonTheme}>
                            <Button
                                type="submit"
                                disabled={!stripe}
                                variant="contained"
                                sx={{ maxWidth: 640 }}
                            >
                                Charge this card
                            </Button>
                        </ThemeProvider>
                        <Typography variant="caption" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                `You will be charged ${NumberHelper.formatCurrency(amount, currency)} and receive ${credits} credit${credits === 1 ? '' : 's'}.`
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default StripeComponent;
