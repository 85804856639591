import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Drawer,
    IconButton,
    Grid2,
    ThemeProvider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import {
    ArrowBackRounded as ArrowBack,
    ArrowForwardRounded as ArrowForward,
    InfoRounded,
    MoreHorizRounded as MoreHoriz,
} from '@mui/icons-material';

import {
    IDebate,
    IDebateInfo,
    IDiscussion,
    IParticipant,
} from '../../../model/interface';
import { DEBATE_ID_PARAM } from '../../../model/constant';
import ParticipantComponent from '../../components/debate/Participant';
import debateTheme from '../../../themes/debate';
import SequenceComponent from '../../components/debate/Sequence';
import { AppDispatch, RootState } from '../../../redux/store';
import { setActiveDebate, setDebateStage } from '../../../redux/debate/slice';
import { UserHelper } from '../../../helpers/User';
import { AppHelper } from '../../../helpers/App';
import { EPollType, EDebatePosition, EDebateStage } from '../../../model/enum';
import { StringHelper } from '../../../helpers/String';
import dialogTheme from '../../../themes/dialog';
import { setWarningMessage } from '../../../redux/app/slice';
import DebateTitleComponent from '../../components/debate/Title';

enum EState {
    INITIAL,
    DEBATE_IN_PROGRESS,
    DEBATE_COMPLETE,
}

type RefMap = {
    [position: string]: React.RefObject<HTMLDivElement>;
};

const DiscussionScreen: React.FC = () => {
    const targetRef: RefMap = {
        [EDebatePosition.MODERATOR]: React.createRef(),
        [EDebatePosition.SIDE_A]: React.createRef(),
        [EDebatePosition.SIDE_B]: React.createRef(),
    };
    const isMobile = AppHelper.isMobileDevice();
    const dispatch = useDispatch<AppDispatch>();
    const debate: IDebate = useSelector(
        (state: RootState) => state.debate.active
    )!;
    const polls = useSelector((state: RootState) => state.debate.polls);
    const debateSeen = polls?.some((p) => p.type === EPollType.POST) || false;
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const streamUrl = process.env.REACT_APP_STREAM_URL!;
    const [aiInfo, setAiInfo] = useState<
        IParticipant | IDebateInfo | undefined
    >(undefined);
    const [sequenceShown, setSequenceShown] = useState<boolean>(
        debateSeen && !isMobile
    );
    const statementsWithAudio: IDiscussion[] = [];
    for (const statement of debate.discussion) {
        if (statement.audio === true) {
            statementsWithAudio.push(statement);
        } else {
            break;
        }
    }
    const [currentStep, setCurrentStep] = useState<number>(
        statementsWithAudio.length === debate.sequence.length
            ? 0
            : statementsWithAudio.length
    );
    const [timeIntoAudio, setTimeIntoAudio] = useState<number>(0);
    const [timeToEndOfAudio, setTimeToEndOfAudio] = useState<number>(0);
    const [state, setState] = useState<EState>(EState.INITIAL);
    const currentSpeaker = debate.sequence[currentStep];

    const [isPlaying, setIsPlaying] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isStepDone, setIsStepDone] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const checkForAutoAdvance = () => {
        if (state === EState.INITIAL) {
            return;
        }
        if (currentStep >= debate.sequence.length - 1) {
            setState(EState.DEBATE_COMPLETE);
            setCurrentStep(debate.sequence.length);
            if (!polls?.some((p) => p.type === EPollType.POST)) {
                dispatch(setDebateStage(EDebateStage.SUMMARY));
            }
        } else {
            setCurrentStep(currentStep + 1);
            // refresh debate in redux
            reloadDebate();
        }
    };

    const scrollToParticipant = (position: EDebatePosition) => {
        if (!targetRef[position].current) {
            return;
        }
        targetRef[position].current!.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    const reloadDebate = async () => {
        const response: AxiosResponse<{ debate: IDebate }> = await axios.get(
            UserHelper.makeURL(
                apiUrl,
                '/debate',
                { [DEBATE_ID_PARAM]: debate.key },
                userAuth
            )
        );
        if (response.data?.debate?.key) {
            const savedDebate: IDebate = response.data.debate;
            dispatch(setActiveDebate(savedDebate));
        }
    };

    const handleSpeakerInfo = (speaker: IParticipant) => {
        setAiInfo(speaker);
    };

    const handleSequenceClick = (index: number) => {
        if (index === currentStep) {
            setSequenceShown(false);
            return;
        }
        if (
            StringHelper.isEmpty(debate.discussion[index]?.statement) ||
            !debate.discussion[index]?.audio
        ) {
            return;
        }
        cancelAudio();
        setState(EState.DEBATE_IN_PROGRESS);
        setCurrentStep(index);
        if (isMobile) {
            setSequenceShown(false);
        }
    };

    const setMetaData = () => {
        const audio = audioRef.current;
        if (!audio) return;

        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: debate.topic.issue,
                artist: 'BOTvBOT',
                album: 'BOTvBOT.com',
                artwork: [
                    {
                        src: '/botvbot.png',
                        sizes: '1024x1024',
                        type: 'image/png',
                    },
                ],
            });
            // Provide playback state and controls
            // navigator.mediaSession.setActionHandler('play', () => audio.play());
            navigator.mediaSession.setActionHandler('pause', () =>
                audio.pause()
            );
            navigator.mediaSession.setActionHandler(
                'seekbackward',
                (details) => {
                    const skipTime = details.seekOffset || 10; // Default 10 seconds
                    audio.currentTime = Math.max(
                        audio.currentTime - skipTime,
                        0
                    );
                }
            );
            navigator.mediaSession.setActionHandler(
                'seekforward',
                (details) => {
                    const skipTime = details.seekOffset || 10; // Default 10 seconds
                    audio.currentTime = Math.min(
                        audio.currentTime + skipTime,
                        audio.duration
                    );
                }
            );
            // navigator.mediaSession.setActionHandler('seekto', (details) => {
            //     if (details.fastSeek && 'fastSeek' in audio) {
            //         audio.fastSeek(details.seekTime!);
            //     } else {
            //         audio.currentTime = details.seekTime!;
            //     }
            // });

            // // Provide playback position
            // navigator.mediaSession.playbackState = 'playing';
            // navigator.mediaSession.setPositionState({
            //     duration: audio.duration || 0,
            //     playbackRate: audio.playbackRate || 1,
            //     position: audio.currentTime || 0,
            // });
        }
    };

    const clearMetaData = () => {
        if ('mediaSession' in navigator) {
            // Clear metadata
            navigator.mediaSession.metadata = null;

            // Unset action handlers
            navigator.mediaSession.setActionHandler('play', null);
            navigator.mediaSession.setActionHandler('pause', null);
            navigator.mediaSession.setActionHandler('seekbackward', null);
            navigator.mediaSession.setActionHandler('seekforward', null);
            navigator.mediaSession.setActionHandler('seekto', null);
            navigator.mediaSession.setActionHandler('stop', null);
        }
    };

    const handlePausePlayAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
    };

    const handleSeekBackAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        const skipTime = 10;
        audio.currentTime = Math.max(audio.currentTime - skipTime, 0);
    };

    const cancelAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        clearMetaData();
        audio.pause();
        audio.src = ''; // Stop the audio source
        audio.play().catch((error) => {
            // this play() operation is necessary in order to elicit error in fetchStatement()
        });
        // console.log('audio cancelled');

        setIsPlaying(false);
        setIsBuffering(false);
        setIsStreaming(false);
        setTimeIntoAudio(0);
        setTimeToEndOfAudio(0);
    };

    useEffect(() => {
        if (isStepDone) {
            checkForAutoAdvance();
        }
    }, [isStepDone]);

    const onAudioPlaying = () => {
        setIsPlaying(true);
        setIsBuffering(false);
    };

    const onAudioPause = () => {
        // console.log('audio paused');
        setIsPlaying(false);
    };

    const onAudioEnd = () => {
        // console.log('audio ended');
        cancelAudio();
        setIsStepDone(true);
    };

    const onAudioTimeUpdate = () => {
        const audio = audioRef.current;
        if (!audio) return;

        // console.log(
        //     'onAudioTimeUpdate',
        //     audio.currentTime,
        //     '/',
        //     audio.duration
        // );
        setTimeIntoAudio(audio.currentTime);
        setTimeToEndOfAudio(audio.duration - audio.currentTime);
    };

    useEffect(() => {
        if (isPlaying) {
            if (
                !(currentStep >= debate.sequence.length - 1) &&
                StringHelper.isEmpty(
                    debate.discussion[currentStep + 1]?.statement
                )
            ) {
                // generate text for next statement
                axios.get(
                    UserHelper.makeURL(
                        streamUrl,
                        '/discussion',
                        {
                            [DEBATE_ID_PARAM]: debate!.key,
                            step: currentStep + 1,
                            warmup: true,
                        },
                        userAuth
                    )
                );
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        // console.log('creating audio element');
        const audio = new Audio();
        audio.preload = 'auto';
        audioRef.current = audio;

        // Attach event listeners
        audio.addEventListener('pause', onAudioPause);
        audio.addEventListener('playing', onAudioPlaying);
        audio.addEventListener('ended', onAudioEnd);
        audio.addEventListener('timeupdate', onAudioTimeUpdate);

        return () => {
            audio.removeEventListener('pause', onAudioPause);
            audio.removeEventListener('playing', onAudioPlaying);
            audio.removeEventListener('ended', onAudioEnd);
            audio.removeEventListener('timeupdate', onAudioTimeUpdate);

            cancelAudio();
        };
    }, []);

    useEffect(() => {
        const fetchStatement = async () => {
            if (state === EState.DEBATE_COMPLETE) {
                return;
            }
            setIsStepDone(false);
            setState(EState.DEBATE_IN_PROGRESS);
            scrollToParticipant(currentSpeaker);

            setIsPlaying(false);

            if (isPlaying) {
                return;
            }

            const audio = audioRef.current;
            if (!audio) {
                return;
            }

            audio.volume = debate.participants[currentSpeaker].volume || 1;
            audio.src = debate.discussion[currentStep]?.mp3Path
                ? `${process.env.REACT_APP_AUDIO_URL}${debate.discussion[currentStep]!.mp3Path!}`
                : UserHelper.makeURL(
                      streamUrl,
                      '/discussion',
                      { [DEBATE_ID_PARAM]: debate.key, step: currentStep },
                      userAuth!
                  );
            audio.load();

            setIsStreaming(true);
            setIsBuffering(true);

            audio.play().catch((error) => {
                if (!/^abort/i.test(error)) {
                    dispatch(setWarningMessage('Problem playing audio.'));
                }
                cancelAudio();
            });

            setMetaData();

            if (currentStep >= debate.sequence.length) {
                dispatch(setDebateStage(EDebateStage.SUMMARY));
            }
        };

        fetchStatement();
    }, [currentStep]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: 2 }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: 4,
                    width: '100%',
                    padding: '4rem 0 10vh 0',
                }}
            >
                <ThemeProvider theme={debateTheme}>
                    <Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography
                                variant="caption"
                                align="center"
                                sx={{ opacity: 0.54 }}
                            >
                                {`A ${AppHelper.getDebateSpiceName(debate.spice)}, ${AppHelper.getDebateFormatName(debate.format)} debate`}
                            </Typography>
                            {debate.info && (
                                <IconButton
                                    sx={{ opacity: 0.3 }}
                                    onClick={() => setAiInfo(debate.info)}
                                >
                                    <InfoRounded
                                        sx={{ width: '2rem', height: '2rem' }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                        <DebateTitleComponent debate={debate} />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: 2, maxWidth: 1200 }}
                    >
                        <Box ref={targetRef[EDebatePosition.MODERATOR]}>
                            <ParticipantComponent
                                size="large"
                                highlighted={
                                    currentSpeaker ===
                                        EDebatePosition.MODERATOR ||
                                    state === EState.DEBATE_COMPLETE
                                }
                                buffering={isBuffering}
                                speaking={
                                    isStreaming &&
                                    isPlaying &&
                                    currentSpeaker === EDebatePosition.MODERATOR
                                }
                                mouthClosed={
                                    timeIntoAudio < 0 ||
                                    timeToEndOfAudio < 0.5 ||
                                    !isPlaying
                                }
                                done={!isStreaming}
                                paused={!isPlaying}
                                speaker={
                                    debate.participants[
                                        EDebatePosition.MODERATOR
                                    ]
                                }
                                debateTopic={debate.topic}
                                handlePausePlayAudio={handlePausePlayAudio}
                                handleSeekBack={handleSeekBackAudio}
                                handleInfo={handleSpeakerInfo}
                            />
                        </Box>
                        <Grid2 container spacing={2} justifyContent="center">
                            {[
                                EDebatePosition.SIDE_A,
                                EDebatePosition.SIDE_B,
                            ].map((position: EDebatePosition, index) => (
                                <Grid2
                                    key={index}
                                    size={{
                                        sm: 12,
                                        md: 6,
                                    }}
                                >
                                    <Box
                                        ref={targetRef[position]}
                                        display="flex"
                                    >
                                        <ParticipantComponent
                                            size="large"
                                            highlighted={
                                                currentSpeaker === position ||
                                                state === EState.DEBATE_COMPLETE
                                            }
                                            buffering={isBuffering}
                                            speaking={
                                                isStreaming &&
                                                isPlaying &&
                                                currentSpeaker === position
                                            }
                                            mouthClosed={
                                                timeIntoAudio < 0 ||
                                                timeToEndOfAudio < 0.5
                                            }
                                            done={!isStreaming}
                                            paused={!isPlaying}
                                            speaker={
                                                debate.participants[position]
                                            }
                                            debateTopic={debate.topic}
                                            handlePausePlayAudio={
                                                handlePausePlayAudio
                                            }
                                            handleSeekBack={handleSeekBackAudio}
                                            handleInfo={handleSpeakerInfo}
                                        />
                                    </Box>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Box>
                </ThemeProvider>
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ gap: 0, width: '100%' }}
                >
                    <IconButton
                        id="discussion-more"
                        onClick={() => setSequenceShown(!sequenceShown)}
                        sx={{
                            backgroundColor: isMobile ? '#202124' : undefined,
                            position: isMobile ? 'fixed' : undefined,
                            zIndex: 100,
                            bottom: isMobile ? 40 : undefined,
                        }}
                    >
                        <MoreHoriz
                            sx={{
                                fontSize: '3rem',
                            }}
                        />
                    </IconButton>
                    <Drawer
                        open={sequenceShown}
                        anchor="bottom"
                        variant="persistent"
                        onClose={() => setSequenceShown(!sequenceShown)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ padding: '0 1rem' }}
                        >
                            <IconButton
                                id="discussion-back"
                                onClick={() =>
                                    dispatch(
                                        setDebateStage(
                                            EDebateStage.OPENNESS_POLL
                                        )
                                    )
                                }
                                sx={{ flex: 0 }}
                            >
                                <ArrowBack
                                    sx={{
                                        fontSize: '3rem',
                                    }}
                                />
                            </IconButton>
                            <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                sx={{
                                    flex: 1,
                                    margin: '1rem',
                                    gap: 1,
                                    visibility: sequenceShown
                                        ? 'visible'
                                        : 'hidden',
                                }}
                            >
                                <SequenceComponent
                                    currentStep={currentStep}
                                    handleClick={handleSequenceClick}
                                />
                            </Box>
                            <IconButton
                                id="discussion-next"
                                onClick={() =>
                                    dispatch(
                                        setDebateStage(EDebateStage.SUMMARY)
                                    )
                                }
                                sx={{ flex: 0 }}
                                disabled={!debateSeen}
                            >
                                <ArrowForward
                                    sx={{
                                        fontSize: '3rem',
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </Drawer>
                </Box>
            </Box>
            <ThemeProvider theme={dialogTheme}>
                <Dialog
                    open={aiInfo !== undefined}
                    onClose={() => setAiInfo(undefined)}
                >
                    {aiInfo !== undefined && (
                        <>
                            <DialogTitle variant="h1">
                                About{' '}
                                {'provider' in aiInfo!
                                    ? 'Debate'
                                    : aiInfo?.name}
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ padding: '2rem', gap: 2 }}
                                >
                                    {'provider' in aiInfo! ? (
                                        <>
                                            {aiInfo.provider && (
                                                <Typography variant="body1">
                                                    Debate orchestrated by{' '}
                                                    {AppHelper.getAiProviderName(
                                                        aiInfo.provider
                                                    )}
                                                </Typography>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {aiInfo?.info?.textProvider && (
                                                <Typography variant="body1">
                                                    Dialogue generated by{' '}
                                                    {AppHelper.getAiProviderName(
                                                        aiInfo.info.textProvider
                                                    )}
                                                </Typography>
                                            )}
                                            {aiInfo?.info?.speechProvider && (
                                                <Typography variant="body1">
                                                    Voice:{' '}
                                                    {AppHelper.getAiProviderName(
                                                        aiInfo.info
                                                            .speechProvider
                                                    )}{' '}
                                                    / {aiInfo.info.voice} /{' '}
                                                    {aiInfo.info.volume}
                                                </Typography>
                                            )}
                                            {aiInfo?.info?.personality && (
                                                <Typography variant="body1">
                                                    Personality is &ldquo;
                                                    {aiInfo.info.personality}
                                                    &rdquo;
                                                </Typography>
                                            )}
                                            {aiInfo?.info?.age && (
                                                <Typography variant="body1">
                                                    Age is {aiInfo.info.age}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={() => setAiInfo(undefined)}
                                >
                                    Okay
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </ThemeProvider>
        </Box>
    );
};

export default DiscussionScreen;
