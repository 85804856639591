import axios, { AxiosResponse } from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAppState,
    setErrorMessage,
    setStartupSeen,
} from '../../../redux/app/slice';
import { Controller, useForm } from 'react-hook-form';
import { EAppState, EPage } from '../../../model/enum';
import { UserHelper } from '../../../helpers/User';
import contactTheme from '../../../themes/contact';
import { IUserLoadResponse } from '../../../model/interface';
import { processLoggedInUser } from '../../../redux/user/actions';
import { useNavigate } from 'react-router-dom';

type FormData = {
    userId: string;
};

const AdminUserScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const appState = useSelector((state: RootState) => state.app.appState);
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const apiUrl = `${process.env.REACT_APP_API_URL}/admin`;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData) => {
        dispatch(setAppState(EAppState.LOADING));
        const response: AxiosResponse<IUserLoadResponse> = await axios.post(
            UserHelper.makeURL(apiUrl, '/user', {}, userAuth),
            { ...(data || {}), admin: userInfo?.admin }
        );
        const userResponse = response.data;
        if (userResponse.error) {
            dispatch(setAppState(EAppState.LOADED));
            dispatch(setErrorMessage(userResponse.error));
        } else {
            await dispatch(processLoggedInUser(userResponse));
            dispatch(setAppState(EAppState.LOADED));
            navigate(EPage.ACCOUNT_DEBATES);
        }
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={contactTheme}>
            <Box
                display="flex"
                sx={{ paddingTop: '3rem', width: '100%' }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box
                    component="form"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        margin: 'auto',
                        width: '100%',
                        maxWidth: 800,
                        gap: 2,
                    }}
                >
                    <Typography variant="h1" align="center">
                        User Login
                    </Typography>
                    {appState !== EAppState.LOADED ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                width="100%"
                            >
                                <Controller
                                    name="userId"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'User ID is required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="User ID"
                                            variant="outlined"
                                            margin="normal"
                                            error={!!errors.userId}
                                            helperText={errors.userId?.message}
                                            inputProps={{
                                                maxLength: 128,
                                            }}
                                        />
                                    )}
                                />
                            </Box>

                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ gap: 4, position: 'relative' }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Log in
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default AdminUserScreen;
