import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import shopTheme from './shop';

const earnTheme = createTheme(shopTheme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                elevation4: {
                    backgroundColor: purple.A400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0.4rem',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'white',
                    textDecorationColor: 'rgba(255, 255, 255, 0.54)',
                },
            },
        },
    },
});

export default earnTheme;
