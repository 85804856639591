import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import { IDebate } from '../../../model/interface';
import { AppHelper } from '../../../helpers/App';

interface IProps {
    debate: IDebate;
}

const DebateTitleComponent: React.FC<IProps> = ({ debate }) => {
    const [title, setTitle] = React.useState<string[]>([]);

    useEffect(() => {
        setTitle(AppHelper.getTitleParts(debate.topic.issue));
    }, []);

    return (
        <Typography variant="h1" align="center">
            &ldquo;
            {title.map((titlePart, index) => (
                <Typography
                    key={index}
                    component="span"
                    variant="h1"
                    sx={index === 1 ? { opacity: 0.35 } : undefined}
                >
                    {titlePart}
                </Typography>
            ))}
            &rdquo;
        </Typography>
    );
};

export default DebateTitleComponent;
