export const LOGO_FONT = 'Poppins';
export const DEBATE_ID_PARAM = 'key';
export const ROOT_ELEMENT_ID = 'root';
export const USER_PARAM_A = 'a'; // access token
export const USER_PARAM_B = 'b'; // password token
export const USER_PARAM_LOGIN_PROVIDER = 'id-provider'; // id provider
export const USER_PARAM_ADMIN = 'admin';
export const DEBATE_PATH_ELEMENT = 'debate';
export const TRUE = 'true';
export const MAX_PROMPT_LENGTH = 500;
export const COST_PER_CREDIT_IN_CENTS = 25;

export const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const FAKE_PROVERBS = [
    'The face of ‘other’ is a mirror not yet polished.',
    'Peace sprouts when words flow and ears remain open.',
    'In seeing enemies, we lose sight of shared dreams and fears.',
];

export const ERROR_MESSAGES = [
    'Oopsie daisy! Crash time!',
    'Well, this is awkward.',
    'App’s feeling moody today.',
    'Welp, that didn’t work.',
    '404: Fun not found.',
    'Oops, the gremlins won.',
];
