import { Box, ThemeProvider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setStartupSeen } from '../../../redux/app/slice';
import legalTheme from '../../../themes/legal';

const PrivacyScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={legalTheme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ paddingTop: '3rem' }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        maxWidth: 960,
                        gap: 4,
                    }}
                >
                    <Typography variant="h1" align="left">
                        Data Processing Addendum
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                            gap: 4,
                        }}
                    >
                        <Typography variant="caption" align="left">
                            Last Update: December, 2024
                        </Typography>
                        <Typography variant="body1" align="left">
                            THIS DATA PROCESSING ADDENDUM (“DPA”) is entered
                            into and forms part of the Terms of Use (the
                            “Agreement”) by and between: (1) Bottymouth
                            Entertainment (“Vendor” or “Bottymouth); and (2) the
                            entity or other person (“Customer”) who is a
                            counterparty to the Agreement (together the
                            “Parties” and each a “Party”).
                        </Typography>
                        <Typography variant="h3" align="left">
                            Definitions
                        </Typography>
                        <Typography variant="body1" align="left">
                            Coming soon.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PrivacyScreen;
