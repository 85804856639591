import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, IUserAuth } from '../../model/interface';

interface IUserState {
    info?: IUser;
    auth?: IUserAuth;
    creditBalance: number;
}

const initialState: IUserState = {
    creditBalance: 0,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<IUser>) => {
            state.info = action.payload;
        },
        setAuth: (state, action: PayloadAction<IUserAuth>) => {
            state.auth = action.payload;
        },
        setCreditBalance: (state, action: PayloadAction<number>) => {
            state.creditBalance = action.payload;
        },
    },
});

export const { setAuth, setCreditBalance, setUserInfo } = userSlice.actions;
export default userSlice.reducer;
