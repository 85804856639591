import {
    Box,
    Button,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { StringHelper } from '../../../helpers/String';
import { useEffect } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setStartupSeen } from '../../../redux/app/slice';
import { useNavigate } from 'react-router-dom';
import { EPage } from '../../../model/enum';
import aboutTheme from '../../../themes/about';

const AboutScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleContactClick = () => {
        navigate(EPage.CONTACT);
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={aboutTheme}>
            <Box display="flex" sx={{ paddingTop: '3rem' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        gap: 4,
                        maxWidth: 960,
                        margin: 'auto',
                        paddingBottom: '3rem',
                    }}
                >
                    <Typography variant="h1" align="center">
                        {StringHelper.insertNonBreakingSpace(
                            'This is an experiment.'
                        )}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: 2 }}
                    >
                        <Typography variant="h4" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'I’d appreciate any and all feedback about this thing!'
                            )}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ gap: 4, maxWidth: 630, margin: 'auto' }}
                    >
                        <Typography variant="body1" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'This project is the product of a convergence of ideas and influences, one of which is the YouTube video from BigThink, “Psychologist debunks 8 myths of mass scale.”'
                            )}
                        </Typography>

                        <Typography variant="body2" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'My hope is that this site will entertain, demonstrate the capabilities of AI (including its buffoonery), and inspire people to think better.'
                            )}
                        </Typography>
                        <Typography variant="caption" align="center">
                            ~ Hugh Mann
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button variant="outlined" onClick={handleContactClick}>
                            Contact Hugh
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default AboutScreen;
