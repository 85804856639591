import MobileDetect from 'mobile-detect';
import { IDebate, IVotedDebate } from '../model/interface';
import copy from 'copy-to-clipboard';
import {
    EAiProvider,
    ECreditType,
    EDebateFormat,
    EDebateSpice,
    EPage,
} from '../model/enum';
import {
    DEBATE_PATH_ELEMENT,
    ERROR_MESSAGES,
    USER_PARAM_ADMIN,
} from '../model/constant';
import { ArrayHelper } from './Array';
import { StringHelper } from './String';
import { debateStructure } from '../data/structure';

export class AppHelper {
    public static getCosts(): string[] {
        return [
            `${this.getCreditCost(EDebateFormat.STANDARD)} credit${this.getCreditCost(EDebateFormat.STANDARD) === 1 ? '' : 's'} = one ${this.getDebateFormatName(
                EDebateFormat.STANDARD
            )} debate`,
            // `${this.getCreditCost(EDebateFormat.MEDIUM)} credits = one ${this.getDebateFormatName(
            //     EDebateFormat.MEDIUM
            // )} debate`,
        ];
    }

    public static getAiProviderName = (
        provider: EAiProvider | undefined
    ): string => {
        switch (provider) {
            case EAiProvider.OPENAI:
                return 'OpenAI';
            case EAiProvider.ANTHROPIC:
                return 'Anthropic';
            case EAiProvider.ELEVEN_LABS:
                return 'Eleven Labs';
            case EAiProvider.GOOGLE:
                return 'Google';
            case EAiProvider.MISTRAL:
                return 'Mistral';
            default:
                return 'Random AI';
        }
    };

    public static getDebateSpiceName = (spice: EDebateSpice): string => {
        switch (spice) {
            case EDebateSpice.HOT:
                return 'shocking';
            case EDebateSpice.MEDIUM:
                return 'cheeky';
            case EDebateSpice.MILD:
                return 'friendly';
        }
    };

    public static getDebateSpiceDescription = (spice: EDebateSpice): string => {
        switch (spice) {
            case EDebateSpice.HOT:
                return 'Sweary and not kid-safe';
            case EDebateSpice.MEDIUM:
                return 'Sassy and kid-safe';
            case EDebateSpice.MILD:
                return 'Nice and kid-safe';
        }
    };

    public static getDebateFormatName = (format: EDebateFormat): string => {
        switch (format) {
            case EDebateFormat.STANDARD:
                return '3-minute';
            case EDebateFormat.MEDIUM:
                return '6-minute';
            default:
                return 'Test';
        }
    };

    public static getDebateFormatDescription = (
        format: EDebateFormat
    ): string => {
        switch (format) {
            case EDebateFormat.STANDARD:
                return 'Two rounds of arguments';
            case EDebateFormat.MEDIUM:
                return 'Four rounds of arguments';
            default:
                return 'Test';
        }
    };

    public static getCreditTypeName = (type: ECreditType): string => {
        switch (type) {
            case ECreditType.COUPON:
                return 'Coupon';
            case ECreditType.PENDING:
                return 'Pending';
            case ECreditType.PURCHASE:
                return 'Purchase';
            case ECreditType.SPEND:
                return 'Debate';
            case ECreditType.GIFT:
                return 'Gift';
            case ECreditType.VOTE:
                return 'Vote';
        }
    };

    public static isMobileDevice = (): boolean => {
        const md = new MobileDetect(navigator.userAgent);
        return !!md.mobile() || !!md.tablet() || this.isIPad();
    };

    public static enterFullscreen = (element: any) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // For Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // For IE/Edge
            element.msRequestFullscreen();
        } else {
            console.warn('Fullscreen API is not supported.');
        }
    };

    public static copyToClipboard = (content: string): boolean => {
        try {
            copy(content === undefined ? this.getCurrentPageURL() : content, {
                debug: AppHelper.isAdmin(),
                format: 'text/plain',
                message: 'Press #{key} to copy',
                onCopy: (clipboardData: any) => {
                    // console.log('clipboardData', clipboardData);
                    // try {
                    //     console.log(
                    //         'clipboardData.getData()',
                    //         clipboardData?.getData('application/json')
                    //     );
                    // } catch (e) {
                    //     //
                    // }
                },
            });
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    public static makeDebatePath = (
        debate: Partial<IDebate> | Partial<IVotedDebate>
    ): string => {
        return EPage.DEBATE.replace(':key', debate.key!)
            .replace(':title', encodeURIComponent(debate.topic!.issue))
            .replace(':status', debate.status!)
            .replaceAll('%20', '+');
    };

    public static truncateText = (text: string, maxLength: number): string => {
        const spacePos = text.indexOf(' ', maxLength);
        if (spacePos > 0) {
            return text.substring(0, spacePos) + '...';
        }
        return text;
    };

    public static getCreditCost = (format: EDebateFormat): number => {
        return debateStructure[format].credits;
    };

    public static reload = () => {
        window.location.reload();
    };

    public static makeSiteLink = (page: EPage, key: string): string => {
        return `${window.location.protocol}//${window.location.host}${page}`.replace(
            ':key',
            key
        );
    };

    public static makeShareLink = (debate: IDebate): string => {
        return `${process.env.REACT_APP_SHARE_URL}${this.makeDebatePath(debate)}`;
    };

    public static isOnDebatePage = (pathname: string): boolean => {
        return new RegExp(`^/${DEBATE_PATH_ELEMENT}/`).test(pathname);
    };

    public static scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    public static scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    public static getErrorMessage = (
        error: { message: string } | string
    ): string => {
        if (typeof error === 'string') {
            return error;
        }
        return error.message || ArrayHelper.shuffle(ERROR_MESSAGES)[0];
    };

    public static isAdmin = (): boolean => {
        return StringHelper.isNotEmpty(localStorage.getItem(USER_PARAM_ADMIN));
    };

    public static isDevEnv = (): boolean => {
        return process.env.NODE_ENV === 'development';
    };

    public static getTitleParts = (title: string): string[] => {
        const titleParts = title.split(/ v /i);
        if (titleParts.length > 1) {
            titleParts.splice(1, 0, ' v ');
        }
        return titleParts;
    };

    public static formatTitle = (title: string): string => {
        return this.getTitleParts(title).join('');
    };

    private static getCurrentPageURL = () => {
        return window.location.href;
    };

    private static isIPad = () => {
        return (
            navigator.maxTouchPoints > 2 &&
            (/MacIntel/.test(navigator.platform) ||
                /Intel Mac/.test(navigator.platform)) &&
            typeof window !== 'undefined' &&
            typeof document !== 'undefined'
        );
    };
}
