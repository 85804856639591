import {
    Button,
    Slide,
    SlideProps,
    Snackbar,
    SnackbarCloseReason,
    Typography,
} from '@mui/material';
import React from 'react';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    setErrorMessage,
    setSuccessMessage,
    setWarningMessage,
} from '../../redux/app/slice';
import { green, purple, red } from '@mui/material/colors';
import { AppHelper } from '../../helpers/App';
import { StringHelper } from '../../helpers/String';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const MessageComponent: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const errorMessage = useSelector(
        (state: RootState) => state.app.errorMessage
    );
    const successMessage = useSelector(
        (state: RootState) => state.app.successMessage
    );
    const warningMessage = useSelector(
        (state: RootState) => state.app.warningMessage
    );

    const handleErrorSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setErrorMessage(undefined));
    };

    const handleSuccessSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSuccessMessage(undefined));
    };

    const handleWarningSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setWarningMessage(undefined));
    };

    const handleReload = () => {
        AppHelper.reload();
    };

    const snackBarAction = (
        <Button color="secondary" size="small" onClick={handleReload}>
            <Typography variant="body2">Reload</Typography>
        </Button>
    );
    return (
        <>
            <Snackbar
                ContentProps={{
                    sx: { backgroundColor: red[500] },
                }}
                open={errorMessage !== undefined}
                onClose={handleErrorSnackBarClose}
                message={StringHelper.insertNonBreakingSpace(errorMessage)}
                action={snackBarAction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionComponent={SlideTransition}
                autoHideDuration={3000}
            />
            <Snackbar
                ContentProps={{
                    sx: {
                        justifyContent: 'center',
                        backgroundColor: green.A400,
                    },
                }}
                open={successMessage !== undefined}
                onClose={handleSuccessSnackBarClose}
                message={StringHelper.insertNonBreakingSpace(successMessage)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionComponent={SlideTransition}
                autoHideDuration={3000}
            />
            <Snackbar
                ContentProps={{
                    sx: {
                        justifyContent: 'center',
                        backgroundColor: purple.A400,
                    },
                }}
                open={warningMessage !== undefined}
                onClose={handleWarningSnackBarClose}
                message={StringHelper.insertNonBreakingSpace(warningMessage)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionComponent={SlideTransition}
                autoHideDuration={3000}
            />
        </>
    );
};

export default MessageComponent;
