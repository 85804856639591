import axios, { AxiosResponse } from 'axios';
import { AppDispatch, RootState } from '../store';
import { StringHelper } from '../../helpers/String';
import { DEBATE_ID_PARAM } from '../../model/constant';
import {
    EAiProvider,
    EDebateFormat,
    EDebateSpice,
    EDebateStatus,
    EPollType,
} from '../../model/enum';
import { setActiveDebate, setPolls } from './slice';
import {
    setDefaultSpice,
    setRecentTopic,
    setSuccessMessage,
    setWarningMessage,
} from '../app/slice';
import { PollHelper } from '../../helpers/Poll';
import { ArrayHelper } from '../../helpers/Array';
import { UserHelper } from '../../helpers/User';
import { AppHelper } from '../../helpers/App';
import { ICredit, IDebate, IPoll } from '../../model/interface';
import { DateTimeHelper } from '../../helpers/DateTime';
import { checkAge } from '../user/actions';
import { setCreditBalance, setUserInfo } from '../user/slice';

const loadDebate =
    (key: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<IDebate | undefined | null> => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const state = getState();
        const userAuth = state.user.auth;

        // load debate
        if (
            StringHelper.isNotEmpty(key) &&
            StringHelper.isNotEmpty(userAuth?.accessToken) &&
            StringHelper.isNotEmpty(userAuth?.passwordToken)
        ) {
            const response: AxiosResponse<{ debate: IDebate }> =
                await axios.get(
                    UserHelper.makeURL(
                        apiUrl,
                        '/debate',
                        { [DEBATE_ID_PARAM]: key },
                        userAuth
                    )
                );
            if (response.data.debate?.key) {
                const debate = response.data.debate!;
                if (!(await dispatch(checkAge(debate.spice)))) {
                    dispatch(setDefaultSpice(EDebateSpice.MEDIUM));
                    // return EReturnCode.REDIRECT_TO_AGE_GATE;
                }
                const polls = await PollHelper.loadPolls(
                    apiUrl!,
                    debate,
                    userAuth!
                );
                dispatch(setActiveDebate(debate));
                dispatch(setPolls(polls));
                document.title = `BOTvBOT | ${AppHelper.formatTitle(debate.topic.issue)}`;
                return debate;
            } else {
                throw new Error('Invalid debate key');
            }
        }
    };

const getIsAgeAppropriate =
    (spice: EDebateSpice) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        if (spice !== EDebateSpice.HOT) {
            return true;
        }
        return DateTimeHelper.isOldEnough(state.user.info?.dob);
    };

const createDebate =
    (
        topic: string,
        spice: EDebateSpice,
        format: EDebateFormat,
        aiProvider: EAiProvider | undefined
    ) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<IDebate | false | null> => {
        const state = getState();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response: AxiosResponse<{
            debate?: IDebate;
            error?: Error | string;
            credits?: number;
        }> = await axios.post(
            UserHelper.makeURL(apiUrl, '/debate', {}, state.user.auth),
            {
                topic,
                spice,
                format,
                aiProvider,
            }
        );
        if (response.data?.debate?.key) {
            const newDebate: IDebate = response.data.debate;
            return newDebate;
        } else if (response.data.error) {
            dispatch(
                setWarningMessage(
                    AppHelper.getErrorMessage(response.data.error)
                )
            );
            if (response.data.credits !== undefined) {
                return null;
            }
        }
        return false;
    };

const savePollResponse =
    (type: EPollType, value: boolean) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<string | undefined> => {
        const state = getState();
        const debate = state.debate.active!;

        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response: AxiosResponse<{
            polls?: IPoll[];
            earnedCredits?: number;
            error?: Error;
        }> = await axios.put(
            UserHelper.makeURL(
                apiUrl,
                '/poll',
                { [DEBATE_ID_PARAM]: debate.key },
                state.user.auth!
            ),
            { type, value }
        );
        if (response.data.error) {
            return response.data.error.message;
        } else if (ArrayHelper.isNotEmpty(response.data.polls)) {
            dispatch(setPolls(PollHelper.normalizeData(response.data.polls!)));

            if (debate.status === EDebateStatus.CREATING) {
                // publish debate
                const publishResponse: AxiosResponse<{ debate: IDebate }> =
                    await axios.post(
                        UserHelper.makeURL(
                            apiUrl,
                            '/discussion',
                            { [DEBATE_ID_PARAM]: debate.key },
                            state.user.auth
                        )
                    );
                if (publishResponse.data.debate) {
                    const updatedDebate: IDebate = publishResponse.data.debate;
                    dispatch(setActiveDebate(updatedDebate));
                }
            }

            if (type === EPollType.PRE || type === EPollType.POST) {
                dispatch(fetchCredits());
                if (
                    type === EPollType.POST &&
                    (response.data.earnedCredits || 0) > 0
                ) {
                    dispatch(
                        setSuccessMessage(
                            'You earned 1 credit for voting on this debate!'
                        )
                    );
                }
            }
        }
    };

const fetchCredits =
    () =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const state = getState();
        const userAuth = state.user.auth;
        try {
            const response: AxiosResponse<{
                creditHistory: ICredit[];
                creditBalance: number;
            }> = await axios.get(
                UserHelper.makeURL(apiUrl, '/credits', {}, userAuth)
            );
            dispatch(setCreditBalance(response.data.creditBalance));
        } catch (error) {
            console.error(error);
        } finally {
            //
        }
    };

const promptForAge =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(setUserInfo({ ...state.user.info!, dob: null }));
    };

const cancelAgePrompt =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(setUserInfo({ ...state.user.info!, dob: undefined }));
    };

const deleteDebate =
    (key: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response: AxiosResponse<{
            success: boolean;
            error?: string;
        }> = await axios.delete(
            UserHelper.makeURL(apiUrl, '/debate', { key }, state.user.auth!)
        );
        if (response.data.success === true) {
            // reload debates
            return true;
        } else if (response.data.error) {
            dispatch(setWarningMessage(response.data.error.toString()));
        }
        return false;
    };

const redoDebate =
    (debateKey: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<IDebate | undefined> => {
        const state = getState();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response: AxiosResponse<{
            debate?: IDebate;
            error?: Error | string;
        }> = await axios.post(
            UserHelper.makeURL(apiUrl, '/debate/redo', {}, state.user.auth),
            {
                key: debateKey,
            }
        );
        if (response.data?.debate?.key) {
            const newDebate: IDebate = response.data.debate;
            dispatch(setActiveDebate(newDebate));
            dispatch(setRecentTopic(undefined));
            return newDebate;
        } else if (response.data.error) {
            dispatch(
                setWarningMessage(
                    AppHelper.getErrorMessage(response.data.error)
                )
            );
        }
        return undefined;
    };

export {
    loadDebate,
    createDebate,
    savePollResponse,
    getIsAgeAppropriate,
    promptForAge,
    cancelAgePrompt,
    deleteDebate,
    redoDebate,
};
