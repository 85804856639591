import { EDebateFormat } from '../model/enum';
import { IDebateStructure } from '../model/interface';

export const debateStructure: { [format: string]: IDebateStructure } = {
    [EDebateFormat.TEST]: {
        credits: 0,
    },
    [EDebateFormat.STANDARD]: {
        credits: 3,
    },
    [EDebateFormat.MEDIUM]: {
        credits: 6,
    },
};
