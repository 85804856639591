import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { hidePopup } from '../../../redux/exitPopup/slice';
import dialogTheme from '../../../themes/dialog';
import { flagNewsletterSeen } from '../../../redux/user/actions';
import { useNavigate } from 'react-router-dom';
import { EPage } from '../../../model/enum';
import BusyComponent from '../Busy';

const JoinListComponent: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isVisible = useSelector(
        (state: RootState) => state.exitPopup.isVisible
    );
    const [busy, setBusy] = React.useState<boolean>(false);

    const handleClose = async () => {
        dispatch(hidePopup());
        dispatch(flagNewsletterSeen());
    };

    const handleSubscribe = async () => {
        navigate(EPage.NEWSLETTER);
        handleClose();
    };

    return busy ? (
        <BusyComponent />
    ) : (
        <ThemeProvider theme={dialogTheme}>
            <Dialog open={isVisible} onClose={handleClose}>
                <DialogTitle>
                    <Typography component="span" variant="h1">
                        Let’s stay in touch!
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        marginTop: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="body1">
                        Before you go, please join the BOTvBOT mailing list. I
                        promise not to bother you too much, but I feel like we
                        were just getting to know each other.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        // variant="outlined"
                        onClick={handleClose}
                        style={{ marginLeft: '10px' }}
                    >
                        No Thanks
                    </Button>
                    <Button variant="contained" onClick={handleSubscribe}>
                        Join Now
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default JoinListComponent;
