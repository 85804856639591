import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Box } from '@mui/material';

interface IProps {
    size: 'small' | 'large';
}

const LogoComponent: React.FC<IProps> = ({ size }) => {
    return (
        <Box
            sx={{
                width: {
                    xs: 486 * (size === 'small' ? 0.3 : 0.65),
                    sm: 486 * (size === 'small' ? 0.3 : 0.65),
                    md: 486 * (size === 'small' ? 0.5 : 1),
                },
                height: {
                    xs: 184 * (size === 'small' ? 0.3 : 0.65),
                    sm: 184 * (size === 'small' ? 0.3 : 0.65),
                    md: 184 * (size === 'small' ? 0.5 : 1),
                },
            }}
        >
            <Logo width="100%" height="100%" />
        </Box>
    );
};

export default LogoComponent;
