import {
    ArrowBackRounded as ArrowBack,
    ArrowForwardRounded as ArrowForward,
} from '@mui/icons-material';
import { Box, Fab, Link, Typography } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { EDebatePosition, EDebateStage, EPage } from '../../../model/enum';
import { StringHelper } from '../../../helpers/String';
import { deleteDebate } from '../../../redux/debate/actions';
import { resetDebate, setDebateStage } from '../../../redux/debate/slice';
import { useState } from 'react';
import BusyComponent from '../../components/Busy';
import { LARGE_GAPS, SMALL_GAPS } from '../../../styles';
import { ArrayHelper } from '../../../helpers/Array';
import { useNavigate } from 'react-router-dom';
import ParticipantComponent from '../../components/debate/Participant';
import DebateTitleComponent from '../../components/debate/Title';

const PreviewScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const debate = useSelector((state: RootState) => state.debate.active);
    const [busy, setBusy] = useState(false);

    const handleNextClick = async () => {
        dispatch(setDebateStage(EDebateStage.INTRO_POLL));
    };

    const handleCancelClick = async () => {
        setBusy(true);
        await dispatch(deleteDebate(debate!.key));
        dispatch(resetDebate());
        navigate(EPage.CREATE);
    };

    return debate === undefined || busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={{
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'center',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: LARGE_GAPS,
                    maxWidth: 1200,
                    padding: '2rem 0',
                }}
            >
                {ArrayHelper.isEmpty(debate.discussion) && (
                    <Typography
                        variant="caption"
                        align="left"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Link
                            id="preview-cancel"
                            onClick={handleCancelClick}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: grey[500],
                                textDecoration: 'none',
                                gap: 1,
                                '&:hover': {
                                    color: amber[500],
                                },
                            }}
                        >
                            <ArrowBack sx={{ width: 24, height: 24 }} />
                            {StringHelper.insertNonBreakingSpace(
                                'Forget this, try a different idea'
                            )}
                        </Link>
                    </Typography>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ gap: 3 }}
                >
                    <Typography variant="caption" align="center">
                        Preview
                    </Typography>
                    <DebateTitleComponent debate={debate} />
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{ maxWidth: 800 }}
                    >
                        {StringHelper.insertNonBreakingSpace(
                            debate.topic.description
                        )}
                    </Typography>
                    <Box display="flex" flexDirection="column" sx={{ gap: 3 }}>
                        {/* <Box display="flex" justifyContent="center">
                            <ParticipantComponent
                                size="small"
                                speaker={
                                    debate.participants[
                                        EDebatePosition.MODERATOR
                                    ]!
                                }
                            />
                        </Box> */}
                        <Box
                            display="flex"
                            alignItems="stretch"
                            flexDirection={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                            }}
                            sx={{ gap: SMALL_GAPS }}
                        >
                            {[
                                EDebatePosition.SIDE_A,
                                EDebatePosition.SIDE_B,
                            ].map((position) => (
                                <Box
                                    display="flex"
                                    key={position}
                                    sx={{ flex: 1 }}
                                >
                                    <ParticipantComponent
                                        size="medium"
                                        speaker={debate.participants[position]!}
                                        debateTopic={debate.topic}
                                    />
                                </Box>
                            ))}
                        </Box>
                        {/* <Box display="flex" sx={{ gap: SMALL_GAPS }}>
                            {[
                                EDebatePosition.SIDE_A,
                                EDebatePosition.SIDE_B,
                            ].map((position) => (
                                <Box
                                    key={position}
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                        flex: 1,
                                        gap: 1,
                                        marginLeft: { xs: 1, sm: 1, md: 5 },
                                    }}
                                >
                                    <Typography variant="h2">
                                        Arguments
                                    </Typography>
                                    <Box
                                        component="ul"
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ gap: 1 }}
                                    >
                                        {debate?.topic.sides[
                                            position
                                        ].arguments.map((point, index) => (
                                            <Typography
                                                component="li"
                                                variant="body1"
                                                key={index}
                                                sx={{
                                                    fontSize: '1rem',
                                                    marginLeft: {
                                                        xs: 2,
                                                        sm: 2,
                                                        md: 4,
                                                    },
                                                }}
                                            >
                                                {point}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box> */}
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" sx={{ gap: 4 }}>
                    <Fab
                        color="primary"
                        onClick={handleNextClick}
                        id="preview-next"
                    >
                        <ArrowForward />
                    </Fab>
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewScreen;
