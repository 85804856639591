export class StringHelper {
    public static isEmpty(str: string | null | undefined): boolean {
        return !str || str.length === 0;
    }

    public static isNotEmpty(str: string | null | undefined): boolean {
        return !this.isEmpty(str);
    }

    public static replaceWordInSentence(
        word: string,
        sentence: string
    ): string {
        let regexp = new RegExp(`\\b${word}\\b`, 'i');
        if (regexp.test(sentence)) {
            const matches = sentence.match(regexp);
            return sentence.replace(regexp, `*${matches?.[0] || word}*`);
        }
        regexp = new RegExp(`\\b${word}`, 'i');
        const matches = sentence.match(regexp);
        return sentence.replace(regexp, `*${matches?.[0] || word}*`);
    }

    public static getCommonPrefix(str1: string, str2: string): string {
        let commonPrefix = '';
        for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
            if (str1[i] === str2[i]) {
                commonPrefix += str1[i];
            } else {
                break;
            }
        }
        return commonPrefix;
    }

    public static insertNonBreakingSpace = (
        sentence: string | undefined
    ): string => {
        if (sentence === undefined) {
            return '';
        }

        const spaces = sentence?.match(/\s/g);
        if (spaces && spaces.length < 3) {
            return sentence;
        }

        let sentenceWithoutPunctuation = sentence.replace(
            /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
            ''
        );
        const maxWordLength = Math.max(
            8,
            Math.round(sentenceWithoutPunctuation.length / 3)
        );
        const words = sentence.split(' ');
        if (words.length > 1) {
            const firstWord = words.shift();
            const secondWord = words.shift();
            if (firstWord!.length + secondWord!.length > maxWordLength) {
                words.unshift(`${firstWord} ${secondWord}`);
            } else {
                words.unshift(`${firstWord}\u00A0${secondWord}`);
            }
        }
        if (words.length > 3) {
            const lastWord = words.pop();
            const secondLastWord = words.pop();
            if (secondLastWord!.length + lastWord!.length > maxWordLength) {
                words.push(`${secondLastWord} ${lastWord}`);
            } else {
                words.push(`${secondLastWord}\u00A0${lastWord}`);
            }
        }
        return words.join(' ');
    };
}
