import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/slice';
import debateReducer from './debate/slice';
import userReducer from './user/slice';
import exitPopupReducer from './exitPopup/slice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        debate: debateReducer,
        user: userReducer,
        exitPopup: exitPopupReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // thunk
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
