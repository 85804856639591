import axios, { AxiosResponse } from 'axios';
import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    Link,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { EAppState, EPage } from '../../../model/enum';
import { UserHelper } from '../../../helpers/User';
import { IUserLoadResponse } from '../../../model/interface';
import { processLoggedInUser } from '../../../redux/user/actions';
import { setAppState, setErrorMessage } from '../../../redux/app/slice';
import shopTheme from '../../../themes/shop';
import legalThemeSmall from '../../../themes/legalSmall';
import DisclaimerComponent from '../legal/Disclaimer';
import TermsComponent from '../legal/Terms';
import AcceptableUseComponent from '../legal/AcceptableUse';
import { AppHelper } from '../../../helpers/App';

const GoogleComponent: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const apiUrl = `${process.env.REACT_APP_API_URL}/auth`;
    const [agreedDisclaimer, setAgreedDisclaimer] = React.useState(
        AppHelper.isAdmin()
    );
    const [agreedTerms, setAgreedTerms] = React.useState(AppHelper.isAdmin());
    const [modalType, setModalType] = React.useState<EPage | undefined>();

    const showLegal = (
        type: EPage,
        event?: React.MouseEvent<HTMLAnchorElement>
    ) => {
        event?.preventDefault();
        setModalType(type);
    };

    const showError = () => {
        dispatch(setErrorMessage('Connection to Google failed'));
    };

    const handleLoginSuccess = async (
        credentialResponse: CredentialResponse
    ) => {
        // console.log('credentialResponse', credentialResponse, userAuth);
        const idToken = credentialResponse.credential;
        // console.log('userAuth', userAuth);
        try {
            const response: AxiosResponse<IUserLoadResponse> = await axios.post(
                UserHelper.makeURL(apiUrl, '/google', {}, userAuth),
                {
                    idToken,
                }
            );
            // console.log('response', response);
            const userResponse: IUserLoadResponse | undefined = response.data;
            if (userResponse !== undefined) {
                await dispatch(processLoggedInUser(userResponse));
                dispatch(setAppState(EAppState.LOADED));
            } else {
                UserHelper.deleteStashedIdProvider();
            }
        } catch (err) {
            showError();
            UserHelper.deleteStashedIdProvider();
        }
    };

    return (
        <ThemeProvider theme={shopTheme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ gap: 2 }}
            >
                <Box display="flex" width="100%" flexDirection="column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={agreedDisclaimer}
                                onClick={
                                    agreedDisclaimer
                                        ? undefined
                                        : () => showLegal(EPage.DISCLAIMER)
                                }
                            />
                        }
                        label={
                            <Typography variant="body1">
                                I have read and agree to the{' '}
                                <Link
                                    onClick={(e) =>
                                        showLegal(EPage.DISCLAIMER, e)
                                    }
                                >
                                    Disclaimer
                                </Link>
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={agreedTerms}
                                onClick={
                                    agreedTerms
                                        ? undefined
                                        : () => showLegal(EPage.TERMS_OF_USE)
                                }
                            />
                        }
                        label={
                            <Typography variant="body1">
                                I have read and agree to the{' '}
                                <Link
                                    onClick={(e) =>
                                        showLegal(EPage.TERMS_OF_USE, e)
                                    }
                                >
                                    Acceptable&nbsp;Use&nbsp;Policy &amp;
                                    Terms&nbsp;of&nbsp;Use
                                </Link>
                            </Typography>
                        }
                    />
                </Box>
                {agreedDisclaimer && agreedTerms ? (
                    <GoogleLogin
                        text="continue_with"
                        shape="pill"
                        onSuccess={handleLoginSuccess}
                        onError={() => {
                            // console.log('Login Failed');
                        }}
                    />
                ) : (
                    <Button
                        variant="contained"
                        disabled={true}
                        sx={{ padding: '0.5rem 1rem', fontSize: '1.2rem' }}
                    >
                        Continue
                    </Button>
                )}
            </Box>
            <Dialog
                open={modalType !== undefined}
                onClose={() => setModalType(undefined)}
                fullWidth={true}
                maxWidth="md"
            >
                <ThemeProvider theme={legalThemeSmall}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: 2,
                        }}
                    >
                        {modalType === EPage.DISCLAIMER && (
                            <>
                                <DisclaimerComponent />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setAgreedDisclaimer(true);
                                        setModalType(undefined);
                                    }}
                                >
                                    Click to Accept
                                </Button>
                            </>
                        )}
                        {modalType === EPage.TERMS_OF_USE && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={{ gap: 6 }}
                            >
                                <Box>
                                    <AcceptableUseComponent />
                                </Box>
                                <Box>
                                    <TermsComponent />
                                </Box>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setAgreedTerms(true);
                                        setModalType(undefined);
                                    }}
                                >
                                    Click to Accept
                                </Button>
                            </Box>
                        )}
                    </Box>
                </ThemeProvider>
            </Dialog>
        </ThemeProvider>
    );
};

export default GoogleComponent;
