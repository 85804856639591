import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPopup } from '../redux/exitPopup/slice';
import { AppDispatch, RootState } from '../redux/store';
import { useLocation } from 'react-router-dom';
import { EPage } from '../model/enum';

const useExitIntent = () => {
    const seen = useSelector((state: RootState) => state.exitPopup.seen);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    useEffect(() => {
        const handleMouseLeave = (event: MouseEvent) => {
            if (
                event.clientY < 0 &&
                !seen &&
                !userInfo?.newsletterSeen &&
                location.pathname !== EPage.CREDITS
            ) {
                dispatch(showPopup());
            }
        };

        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [dispatch, seen, userInfo]);
};

export default useExitIntent;
