import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Chip, Grid2 } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
    CheckCircleRounded as CheckCircle,
    PendingRounded as Pending,
} from '@mui/icons-material';
import axios, { AxiosResponse } from 'axios';

import { IDebate, ITrendingDebate } from '../../model/interface';
import { ArrayHelper } from '../../helpers/Array';
import topicsTheme from '../../themes/topics';
import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../../redux/store';
import { UserHelper } from '../../helpers/User';
import {
    EAppState,
    EDebateFormat,
    EDebateSpice,
    EDebateStatus,
    EPage,
} from '../../model/enum';
import StartupScreen from './Startup';
import {
    setDefaultFormat,
    setDefaultSpice,
    setErrorMessage,
    setTrendingDebates,
} from '../../redux/app/slice';
import SelectorComponent from '../components/debate/Selector';
import { checkAge } from '../../redux/user/actions';
import BusyComponent from '../components/Busy';
import { ERROR_MESSAGES } from '../../model/constant';

interface IProps {
    isLatest?: boolean;
}

const TrendingScreen: React.FC<IProps> = ({ isLatest }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const audience = useParams<{ audience: string }>().audience;
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

    // from redux store
    const defaultSpice = useSelector(
        (state: RootState) => state.app.defaultSpice
    );
    const defaultFormat = useSelector(
        (state: RootState) => state.app.defaultFormat
    );
    const appState = useSelector((state: RootState) => state.app.appState);
    const trendingDebates = useSelector(
        (state: RootState) => state.app.trendingDebates
    );
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const userAuth = useSelector((state: RootState) => state.user.auth);

    // local state
    const [spice, setSpice] = useState<EDebateSpice>(defaultSpice);
    const [format, setFormat] = useState<EDebateFormat>(defaultFormat);
    const [busy, setBusy] = useState(false);
    const [activeTrendingDebates, setActiveTrendingDebates] = useState<
        ITrendingDebate[]
    >([]);
    const [activeAudiences, setActiveAudiences] = useState<
        string[] | undefined
    >();
    const [activeTags, setActiveTags] = useState<string[] | undefined>();
    const [selectedTag, setSelectedTag] = useState<string | undefined>();

    const handleMoreClick = () => {
        navigate(isLatest ? EPage.SEARCH : EPage.CREATE);
    };

    const handleSelectSpice = async (s: EDebateSpice) => {
        if (await dispatch(checkAge(s))) {
            setSpice(s);
            dispatch(setDefaultSpice(s));
        }
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        setFormat(f);
        dispatch(setDefaultFormat(f));
    };

    const handleTagSelection = (tag: string) => {
        setSelectedTag(tag);
    };

    const handleDebateSelection = (debate: ITrendingDebate) => {
        navigate(
            AppHelper.makeDebatePath({
                key: debate.key,
                status: EDebateStatus.SHARING,
                topic: {
                    issue: debate.issue,
                },
            } as IDebate)
        );
    };

    const fetchDebates = async () => {
        setBusy(true);
        try {
            const response: AxiosResponse<{ debates: ITrendingDebate[] }> =
                await axios.get(
                    UserHelper.makeURL(
                        apiUrl,
                        `/${isLatest ? 'latest' : 'trending'}`,
                        { audience, format, spice },
                        userAuth
                    )
                );
            if (ArrayHelper.isNotEmpty(response.data?.debates)) {
                dispatch(
                    setTrendingDebates({
                        format,
                        spice,
                        debates: response.data.debates,
                    })
                );
            }
        } catch (err) {
            dispatch(setErrorMessage(ArrayHelper.shuffle(ERROR_MESSAGES)[0]));
        } finally {
            setBusy(false);
        }
    };

    useEffect(() => {
        const filteredDebates =
            trendingDebates?.[format]?.[spice]?.filter(
                (d) => d.spice === spice && d.format === format
            ) || [];
        const filteredDebateTags: string[] = [];
        const filteredDebateAudiences: string[] = [];
        filteredDebates.forEach((debate) => {
            debate.tags?.forEach((tag) => {
                if (/^#/.test(tag) && !filteredDebateTags.includes(tag)) {
                    filteredDebateTags.push(tag);
                } else if (
                    /^@/.test(tag) &&
                    !filteredDebateAudiences.includes(tag)
                ) {
                    filteredDebateAudiences.push(tag);
                }
            });
        });
        filteredDebateTags.sort((a, b) => a.localeCompare(b));
        setActiveTags(filteredDebateTags);
        setActiveAudiences(filteredDebateAudiences);
        setActiveTrendingDebates(filteredDebates);
    }, [spice, format, trendingDebates]);

    useEffect(() => {
        // console.log('fetchTrending?', tag);
        if (
            appState === EAppState.LOADED &&
            trendingDebates?.[format]?.[spice] === undefined
        ) {
            // console.log('fetchTrending');
            fetchDebates();
        }
    }, [appState, location, spice, format]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
            justifyContent="center"
            height={{ xs: 'auto', sm: 'auto', md: '100%' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: 4,
                    maxWidth: 1200,
                    paddingTop: 8,
                    // paddingBottom: 8,
                }}
            >
                {/* {(audience ||
                    selectedTag ||
                    ArrayHelper.isNotEmpty(activeAudiences)) && (
                    <ThemeProvider theme={topicsTheme}>
                        <Box display="flex" sx={{ gap: 1 }}>
                            {audience ? (
                                <Chip
                                    label={`@${audience}`}
                                    onDelete={() => navigate(EPage.HOME)}
                                />
                            ) : (
                                activeAudiences?.map(
                                    (audienceKeyword, index) => (
                                        <Chip
                                            key={index}
                                            label={audienceKeyword}
                                            onClick={() =>
                                                navigate(
                                                    EPage.DEBATES_BY_AUDIENCE.replace(
                                                        ':audience',
                                                        audienceKeyword.replace(
                                                            /^@/,
                                                            ''
                                                        )
                                                    )
                                                )
                                            }
                                        />
                                    )
                                )
                            )}
                            {selectedTag && (
                                <Chip
                                    label={selectedTag}
                                    onDelete={() => setSelectedTag(undefined)}
                                />
                            )}
                        </Box>
                    </ThemeProvider>
                )} */}
                <SelectorComponent
                    action={isLatest ? 'Discover' : 'Witness'}
                    selectedSpice={spice}
                    selectedFormat={format}
                    selectedTag={selectedTag}
                    onSelectSpice={handleSelectSpice}
                    onSelectFormat={handleSelectFormat}
                    onClickTag={() => setSelectedTag(undefined)}
                />
                <ThemeProvider theme={topicsTheme}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Grid2 container spacing={1} justifyContent="center">
                            {selectedTag === undefined
                                ? activeTags?.map((tag, index) => (
                                      <Grid2 key={index}>
                                          <Button
                                              id={tag.replace(/^#/, '')}
                                              // color='primary'
                                              variant="contained"
                                              onClick={() =>
                                                  handleTagSelection(tag)
                                              }
                                          >
                                              {tag}
                                          </Button>
                                      </Grid2>
                                  ))
                                : activeTrendingDebates
                                      .filter((debate) =>
                                          debate.tags?.includes(selectedTag)
                                      )
                                      .map((debate, index) => (
                                          <Grid2 key={index}>
                                              <Button
                                                  color={
                                                      debate.complete !==
                                                      undefined
                                                          ? 'secondary'
                                                          : 'primary'
                                                  }
                                                  variant="contained"
                                                  onClick={() =>
                                                      handleDebateSelection(
                                                          debate
                                                      )
                                                  }
                                                  endIcon={
                                                      debate.complete ===
                                                      true ? (
                                                          <CheckCircle />
                                                      ) : debate.complete ===
                                                        false ? (
                                                          <Pending />
                                                      ) : undefined
                                                  }
                                              >
                                                  {AppHelper.formatTitle(
                                                      debate.issue
                                                  )}
                                              </Button>
                                          </Grid2>
                                      ))}
                        </Grid2>
                    </Box>
                </ThemeProvider>

                <Button
                    id="button-more"
                    onClick={handleMoreClick}
                    color="secondary"
                    sx={{ fontSize: '1.5rem' }}
                >
                    {isLatest ? 'Search by keyword' : 'Start your own debate'}
                </Button>
            </Box>
        </Box>
    );
};

export default TrendingScreen;
