import React from 'react';
import {
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Link,
    Dialog,
    ThemeProvider,
} from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { MONTH_NAMES } from '../../../model/constant';
import { StringHelper } from '../../../helpers/String';
import { updateDOB } from '../../../redux/user/actions';
import { DateTimeHelper } from '../../../helpers/DateTime';
import { CloseRounded as Close } from '@mui/icons-material';
import { setDefaultSpice } from '../../../redux/app/slice';
import { EDebateSpice, EPage } from '../../../model/enum';
import { useNavigate } from 'react-router-dom';
import LogoComponent from '../Logo';
import BusyComponent from '../Busy';
import legalThemeSmall from '../../../themes/legalSmall';
import PrivacyComponent from '../legal/Privacy';
import TermsComponent from '../legal/Terms';

interface IProps {
    handleCloseClick: () => void;
}

const AgeGateComponent: React.FC<IProps> = ({ handleCloseClick }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const debate = useSelector((state: RootState) => state.debate.active);

    const [busy, setBusy] = React.useState(false);
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');
    const [modalType, setModalType] = React.useState<EPage | undefined>();

    const handleDOBSubmit = async () => {
        setBusy(true);
        const dob = DateTimeHelper.makeUnixtime(year, month, day);
        await dispatch(updateDOB(dob));
        setBusy(false);
        if (!DateTimeHelper.isOldEnough(dob)) {
            dispatch(setDefaultSpice(EDebateSpice.MEDIUM));
            navigate(EPage.HOME);
        } else {
            dispatch(setDefaultSpice(EDebateSpice.HOT));
        }
    };

    return busy ? (
        <BusyComponent />
    ) : (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: { xs: 2, sm: 2, md: 4 },
                    maxWidth: 800,
                    padding: '1rem',
                }}
            >
                <LogoComponent size="small" />
                <Typography variant="h3" align="center">
                    Age verification required
                </Typography>
                <Typography
                    variant="caption"
                    align="center"
                    sx={{ fontSize: '1rem', opacity: 0.54 }}
                >
                    This {debate ? 'debate' : 'section contains content that'}{' '}
                    may not be suitable for individuals under 13 years of age.
                    By proceeding, you confirm that you are at least 13 years
                    old, or you have obtained verifiable parental or legal
                    guardian consent to access this content. Providing
                    inaccurate birthdate information may result in suspension or
                    termination of your account. For more details, please review
                    our{' '}
                    <Link onClick={() => setModalType(EPage.TERMS_OF_USE)}>
                        Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link onClick={() => setModalType(EPage.PRIVACY_POLICY)}>
                        Privacy Policy
                    </Link>
                    .
                </Typography>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
                    sx={{ gap: 2 }}
                >
                    <FormControl>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            sx={{ minWidth: 250 }}
                        >
                            {MONTH_NAMES.map((monthName, index) => (
                                <MenuItem key={index} value={index + 1}>
                                    {monthName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Day</InputLabel>
                        <Select
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                            sx={{ minWidth: 140 }}
                        >
                            {Array.from({ length: 31 }, (_, i) => (
                                <MenuItem key={i} value={i + 1}>
                                    {i + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Year</InputLabel>
                        <Select
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            sx={{ minWidth: 140 }}
                        >
                            {Array.from({ length: 100 }, (_, i) => (
                                <MenuItem key={i} value={2023 - i}>
                                    {2023 - i}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Button
                    id="dob-submit"
                    variant="contained"
                    onClick={handleDOBSubmit}
                    disabled={
                        StringHelper.isEmpty(year) ||
                        StringHelper.isEmpty(month) ||
                        StringHelper.isEmpty(day)
                    }
                >
                    Submit
                </Button>
                <IconButton
                    id="dob-close"
                    onClick={handleCloseClick}
                    sx={{
                        position: 'fixed',
                        top: '1rem',
                        right: '1rem',
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <Dialog
                open={modalType !== undefined}
                onClose={() => setModalType(undefined)}
                fullWidth={true}
                maxWidth="md"
            >
                <ThemeProvider theme={legalThemeSmall}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: 2,
                        }}
                    >
                        {modalType === EPage.PRIVACY_POLICY && (
                            <PrivacyComponent />
                        )}
                        {modalType === EPage.TERMS_OF_USE && <TermsComponent />}
                    </Box>
                </ThemeProvider>
            </Dialog>
        </>
    );
};

export default AgeGateComponent;
