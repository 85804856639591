import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { EPage } from '../../../model/enum';

const TermsComponent: React.FC = () => {
    return (
        <>
            <Typography variant="h1" align="center">
                Terms of Use
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    gap: 1,
                }}
            >
                <Typography variant="caption" align="left">
                    Last Update: December, 2024
                </Typography>
                <Typography variant="body1" align="left">
                    Welcome to BOTvBOT! The following Terms of Use and end user
                    license agreement (the <strong>“Terms of Use”</strong>)
                    constitute an agreement between you, or if you are under the
                    age of majority in your local jurisdiction, you, with the
                    consent of your parent or legal guardian (collectively,
                    <strong>“user”</strong>, <strong>“you”</strong> or{' '}
                    <strong>“your”</strong>) and Client Entertainment and its
                    affiliates and subsidiaries (collectively,{' '}
                    <strong>“Client”</strong>, <strong>“our”</strong>,
                    <strong>“us”</strong> or <strong>“we”</strong>). These Terms
                    of Use govern your use of and access to the Site, Content
                    and/or Services (each as defined below).
                </Typography>
                <Typography variant="h3" align="left">
                    For the purposes of these Terms of Use:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        <strong>“Claims”</strong> means any and all claims,
                        demands, actions, suits, proceedings, losses, damages,
                        harm, costs, liabilities, fines, penalties, or expenses
                        (including, without limitation, reasonable legal fees
                        and expenses on a full indemnity basis).
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>“Content”</strong> means any input provided to,
                        or Output produced by, the Site, including without
                        limitation, ideas, prompts, topics, text, debates,
                        phrases used in debate, graphics, avatars, avatar
                        profiles, designs, drawings, logos, images, audio,
                        video, audiovisual works, trademarks, copyrights,
                        information, and any intellectual property therein, any
                        of which may be created, submitted, or otherwise made
                        accessible on or through the Site and/or Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>“Output”</strong> means any content generated,
                        created, transformed, or otherwise produced by the Site,
                        whether originating from, influenced by, or derived from
                        user input, and including, without limitation, text,
                        images, audio, video, or other media formats.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>“Services”</strong> means any content made
                        available on the Site that is not solely generated at a
                        user’s request, including but not limited to
                        pre-existing avatars, scenes, debate topics, templates,
                        previously generated debates curated for ongoing access,
                        or any other materials designated by Client as part of
                        its standard offerings.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>“Site”</strong> means botvbot.com and affiliated
                        subdomains of botvbot.com.
                    </Typography>
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Entertainment Only
                </Typography>
                <Typography variant="body1" align="left">
                    <strong>
                        The Content and Output provided by the Site are for
                        entertainment purposes only and must not be relied upon
                        for any other purpose. You are prohibited from using the
                        Content or Output for decision-making, professional
                        advice, or purposes with legal, medical, financial, or
                        other material consequences. By using the Site, you
                        acknowledge and agree to these limitations.
                    </strong>
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Acceptance of Terms and Revisions
                </Typography>
                <Typography variant="body1" align="left">
                    <strong>
                        You should carefully review these Terms of Use and the
                        pop-up summary disclaimer, which provides key
                        information about the entertainment and fictional nature
                        of the Services. If you do not agree with these Terms of
                        Use or the pop-up disclaimer, you are not permitted to
                        access or use the Site, Content, and/or Services, and
                        you must exit the Site and cease using the Site,
                        Content, and/or Services immediately.
                    </strong>
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing the Site, submitting information to us
                    (regardless of whether you register an Account or use the
                    Services as a Site guest), or using any of the Content or
                    Services, you acknowledge and agree to these Terms of Use,
                    including the pop-up disclaimer. If you do not accept these
                    terms, you must not submit information to or register an
                    account with Client, access the Site, or use any of the
                    Content or Services.
                </Typography>
                <Typography variant="body1" align="left">
                    We reserve the right, acting in our sole discretion, to
                    revise these Terms of Use at any time. Notice of revisions
                    will be provided by posting the updated Terms of Use at{' '}
                    <Link href={`${EPage.TERMS_OF_USE}`}>
                        {`${window.location.origin}${EPage.TERMS_OF_USE}`}
                    </Link>
                    . It is your responsibility to ensure that you are aware of
                    the current Terms of Use when accessing or using the Site
                    and/or Services. If you continue to access or use the Site
                    and/or Services after any revisions to these Terms of Use
                    are posted, you will be deemed to have accepted those
                    revisions.
                </Typography>
                <Typography variant="h3" align="left">
                    <strong>Parents and Legal Guardians of Minors</strong>
                </Typography>
                <Typography variant="body1" align="left">
                    <strong>
                        If you are a parent or legal guardian, you are
                        responsible for monitoring and controlling access to the
                        Site, including content identified as age-restricted.
                        Certain features, such as 'shocking' debates, are not
                        suitable for individuals under 13 years of age. These
                        features are gated by age verification requiring
                        accurate birthdate input. By allowing your child to
                        access the Site, you represent and warrant that you have
                        provided verifiable consent where applicable.
                    </strong>
                </Typography>
                <Typography variant="body1" align="left">
                    <strong>
                        We reserve the right to suspend or terminate accounts
                        that provide false information during age verification.
                        If you suspect your child has accessed age-restricted
                        content without appropriate consent, please contact us
                        immediately at{' '}
                        <Link target="_blank" href="mailto:human@botvbot.com">
                            human@botvbot.com
                        </Link>
                        . For more information, refer to our{' '}
                        <Link href={`${EPage.TERMS_OF_USE}`}>
                            {`${window.location.origin}${EPage.TERMS_OF_USE}`}
                        </Link>{' '}
                        and{' '}
                        <Link href={`${EPage.PRIVACY_POLICY}`}>
                            {`${window.location.origin}${EPage.PRIVACY_POLICY}`}
                        </Link>
                        .
                    </strong>
                </Typography>
                <Typography variant="h3" align="left">
                    Consideration
                </Typography>
                <Typography variant="body1" align="left">
                    For avoidance of doubt, you acknowledge that your acceptance
                    of these Terms of Use is supported by good and valuable
                    consideration including, but not limited to, the limited
                    license granted in these Terms of Use, and you hereby
                    acknowledge the receipt and sufficiency of such
                    consideration.
                </Typography>
                <Typography variant="h3" align="left">
                    Hardware, Software and Connectivity
                </Typography>
                <Typography variant="body1" align="left">
                    You are responsible for providing all equipment and
                    software, including maintaining an optional account with any
                    social networking service through which you connect to the
                    Site, and you are responsible for any fees, including
                    internet connection, mobile data services and similar third
                    party fees that you incur when accessing the Site.
                </Typography>
                <Typography variant="h3" align="left">
                    Suggestions and Feedback
                </Typography>
                <Typography variant="body1" align="left">
                    You agree that any and all feedback that you may provide to
                    Client, whether solicited or unsolicited (
                    <strong>“Feedback”</strong>) will be owned by Client,
                    without providing compensation to you or any other person
                    and without any liability whatsoever, including all
                    intellectual property rights therein and you hereby
                    irrevocably assign all intellectual property rights
                    throughout the world and in perpetuity in and to the
                    Feedback to Client and waive any and all associated moral
                    rights you may have therein. Upon request from Client and at
                    Client 's expense, you will fully cooperate and assist with,
                    execute and deliver all further documents to enable Client a
                    to document, acquire, apply for, prosecute, perfect or
                    enforce any intellectual property rights in the Feedback. If
                    for any reason Client is unable to secure any necessary
                    documentation from you to effect the foregoing further
                    assurances, then you irrevocably designate and appoint
                    Client and its duly authorized officers and agents as agent
                    and attorney in fact, to act for and on your behalf for the
                    purpose of documenting, acquiring, applying for,
                    prosecuting, perfecting or enforcing such intellectual
                    property rights in the Feedback.
                </Typography>
                <Typography variant="h3" align="left">
                    Data Collection and Ownership
                </Typography>
                <Typography variant="body1" align="left">
                    Client collects minimal data to improve your experience and
                    ensure the functionality of the Site and Services. The data
                    we collect includes:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Non-Personal Data: Anonymous or aggregated data such as
                        IP address, city-level location, device type, browser
                        type, session duration, and actions within the Site or
                        Services.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    This data is used solely for improving service performance,
                    analytics, and moderation. No personal information (e.g.,
                    names, email addresses) is collected unless explicitly
                    provided by the user during account creation or through
                    direct communication with Client.
                </Typography>
                <Typography variant="h3" align="left">
                    Laws and Regulations
                </Typography>
                <Typography variant="body1" align="left">
                    Your access to and use of the Site, Content and/or Services
                    is subject to all applicable international, federal,
                    provincial, state and local laws and regulations.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    INTELLECTUAL PROPERTY RIGHTS
                </Typography>
                <Typography variant="h4" align="left">
                    Grant of a Limited License
                </Typography>
                <Typography variant="body1" align="left">
                    Subject to your strict compliance with these Terms of Use,
                    Client grants you a limited, non-exclusive, revocable, and
                    personal license to access and use the Site and its Content
                    and Output solely for entertainment and non-commercial
                    purposes. This license also allows you to distribute links
                    to debates generated by the Site to third parties, provided:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        The Content or Output remains unmodified; and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        You do not claim ownership of the Content or Output.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    This license does not grant you any ownership rights in the
                    Content or Output, and all rights not expressly granted to
                    you are reserved by Client. You further agree that you will
                    not:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Use any Content or Output for commercial purposes or
                        public distribution beyond sharing links as permitted;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Modify or create derivative works from any Content or
                        Output; or
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Assert any claim to ownership of any Content or Output
                        generated by the Site.
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    License Term
                </Typography>
                <Typography variant="body1" align="left">
                    Your License commences on the date of your acceptance of
                    these Terms of Use and continues until terminated as set
                    forth below:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Termination by Client:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Client may suspend or terminate your license,
                            Account, and/or access to the Site, Content, and
                            Services at any time, with or without notice, at its
                            sole discretion, including but not limited to:
                        </Typography>
                        <Typography
                            variant="body1"
                            component="ul"
                            sx={{ marginLeft: 3 }}
                        >
                            <Typography
                                component="li"
                                variant="body1"
                                align="left"
                            >
                                Your violation of these Terms of Use or
                                applicable laws;
                            </Typography>
                            <Typography
                                component="li"
                                variant="body1"
                                align="left"
                            >
                                Misuse of Content or Output, including
                                unauthorized sharing, modification, or
                                commercial use;
                            </Typography>
                            <Typography
                                component="li"
                                variant="body1"
                                align="left"
                            >
                                Circumvention of technical protection measures
                                or attempts to compromise the functionality,
                                security, or integrity of the Site.
                            </Typography>
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Automatic Termination:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Your License may automatically terminate without
                            notice if:
                        </Typography>
                        <Typography
                            variant="body1"
                            component="ul"
                            sx={{ marginLeft: 3 }}
                        >
                            <Typography
                                component="li"
                                variant="body1"
                                align="left"
                            >
                                You discontinue use of the Services for more
                                than 12 months;
                            </Typography>
                            <Typography
                                component="li"
                                variant="body1"
                                align="left"
                            >
                                You provide false information or otherwise
                                violate applicable laws when using the Site.
                            </Typography>
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Effect of Termination:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Upon termination of your license, you must
                            immediately cease all use of the Content and
                            Services. Links to debates you have shared may be
                            deactivated at Client’s sole discretion. Termination
                            does not affect Client’s ownership of any Content,
                            Output, or intellectual property created, derived,
                            or generated during the term of your license.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Survival:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            The provisions of these Terms of Use which require
                            or contemplate performance after the expiration or
                            termination of these Terms of Use shall remain
                            enforceable notwithstanding such expiration or
                            termination.
                        </Typography>
                    </Box>
                </Typography>
                <Typography variant="h4" align="left">
                    Alleged Copyright Infringement Policy
                </Typography>
                <Typography variant="body1" align="left">
                    If you allege that Content on the Site infringes your
                    copyright, it is Client’s policy to respond to notices of
                    alleged copyright infringement that are made in compliance
                    with the United States Digital Millennium Copyright Act of
                    1998. If you wish to provide notice of alleged copyright
                    infringement, you must provide the following information:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        Identify the copyrighted work that you claim has been
                        infringed;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Identify the material claimed to be infringing and where
                        it is located on the Site;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Provide reasonably sufficient information to allow us to
                        contact you, such as your address, phone number, and
                        email address;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Provide a statement that you have a good faith belief
                        that use of the material in the manner complained of is
                        not authorized by the copyright owner, its agent, or by
                        law;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Provide a statement, made under penalty of perjury, that
                        the above information is accurate and that you are the
                        copyright owner or are authorized to act on behalf of
                        the owner;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Provide reasonably sufficient information to allow us to
                        contact you, such as your address, phone number, and
                        email address;
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    Client requires all notices of alleged copyright
                    infringement (a “<strong>Copyright Notice</strong>”) to be
                    in writing and delivered via regular mail or e-mail to
                    Client or an agent in order to enable us to review and
                    respond to your complaint as efficiently as possible while
                    maintaining compliance with applicable laws.
                </Typography>
                <Typography variant="body1" align="left">
                    Please mail or e-mail the dated and signed Copyright Notice
                    to us:
                </Typography>
                <Typography variant="body1" align="left">
                    By e-mail, at:{' '}
                    <Link
                        target="_blank"
                        href="mailto:infringement@botvbot.com"
                    >
                        infringement@botvbot.com
                    </Link>
                </Typography>
                <Typography variant="body1" align="left">
                    By mail: 1600 – 925 West Georgia Street, Vancouver, BC, V6C
                    3L2
                </Typography>
                <Typography variant="body1" align="left">
                    Client may provide you notice if your communication, post,
                    submission, or upload was removed as a result of Client
                    receiving a Copyright Notice from a copyright owner. If you
                    receive notice from Client, you may provide a
                    counter-notification in writing to Client through the e-mail
                    or address provided above.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    User Accounts
                </Typography>
                <Typography variant="body1" align="left">
                    In order to access and use certain features of the Site,
                    Content and/or Services you may be required to register an
                    account (the “<strong>Account</strong>”) through the Site,
                    Content or Services. When you register an Account, you will
                    be required to provide us with certain personal information,
                    which may include your first name and email address. This
                    information will be retained and used by Client in
                    accordance with its Privacy Policy (as amended from time to
                    time, the “<strong>Privacy Policy</strong>”), which is
                    incorporated by reference into and forms part of these Terms
                    of Use. You agree that all information provided by you will
                    be accurate and complete and that you will promptly update
                    such information if and when it changes.
                </Typography>
                <Typography variant="body1" align="left">
                    DESPITE ANY OTHER PROVISION IN THESE TERMS OF USE TO THE
                    CONTRARY, YOU ACKNOWLEDGE AND AGREE THAT YOU DO NOT ACQUIRE
                    ANY OWNERSHIP OR OTHER PROPERTY RIGHT IN YOUR ACCOUNT(S).
                </Typography>
                <Typography variant="body1" align="left">
                    YOU MAY NOT SELL, ASSIGN, TRANSFER, SUBLICENSE, CONVEY OR
                    SHARE WITH ANY OTHER PERSON ANY USER NAMES OR ACCOUNTS
                    REGISTERED BY YOU AND ANY ATTEMPT TO DO SO WILL ENTITLE
                    CLIENT, IN ITS SOLE AND ABSOLUTE DISCRETION AND WITHOUT
                    NOTICE, TO CANCEL OR SUSPEND YOUR ACCOUNT AND, IN SUCH
                    EVENT, YOU WILL NOT BE ENTITLED TO ANY REFUND OF FEES PAID
                    TO CLIENT BY YOU OR TO ANY OTHER DAMAGES IN RESPECT THEREOF.
                </Typography>
                <Typography variant="h4" align="left">
                    Login Information
                </Typography>
                <Typography variant="body1" align="left">
                    When you register an Account, you will use Google Single
                    Sign-on and you will be required to permit the Site to
                    access basic information about you, including your first
                    name (if any provided to Google Single Sign-on) and email
                    address, and to grant express consent to Client to contact
                    you by way of the email address provided (the “
                    <strong>Login Information</strong>”).
                </Typography>
                <Typography variant="body1" align="left">
                    You are responsible for protecting the confidentiality of
                    your Account password and for restricting access to your
                    computer, device or media system platform, and you agree to
                    accept responsibility for all activities that occur under
                    your Account. You are not permitted to share your Account(s)
                    or Login Information or to allow any other person to access
                    to your Account(s). You agree to notify us immediately if
                    you suspect or become aware of any unauthorized use of your
                    password(s), your Account(s), or any other security breach
                    involving your Account(s) or the Site, Content and/or
                    Services at{' '}
                    <Link target="_blank" href="mailto:human@botvbot.com">
                        human@botvbot.com
                    </Link>
                    . We are not responsible for any unauthorized access of your
                    Account(s) or password(s) even if you have advised us of
                    such. You are responsible for all activities that occur
                    under your Account(s), including but not limited to
                    purchases, whether or not such activities have been
                    authorized by you.
                </Typography>
                <Typography variant="h4" align="left">
                    Accessing Your Account(s)
                </Typography>
                <Typography variant="body1" align="left">
                    Each time that you log into and access an Account, you
                    represent and warrant that:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        you are the person who registered the Account;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        all of the personal information registered in connection
                        with the Account is current, complete and accurate;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you and are accessing the Site and using the Content
                        and/or Services solely for lawful purposes and in strict
                        compliance with these Terms of Use;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are accessing the Site, Content and/or Services
                        solely for your own personal, non-commercial purposes;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not, and are not acting on behalf of, any
                        competitor or prospective competitor of Client or its
                        affiliates or subsidiaries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not accessing the Site, Content and/or Services
                        for the purposes of any dispute or litigation involving
                        Client or its affiliates or subsidiaries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not accessing the Site, Content and/or Services
                        for any illegal purpose or to advertise, solicit or
                        communicate with other users for any commercial purpose;
                        and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you will logoff and exit the Account at the end of each
                        session.
                    </Typography>
                </Typography>
                <Typography variant="h4" align="left">
                    Unauthorized Access to Password Protected/Secure Areas
                </Typography>
                <Typography variant="body1" align="left">
                    Access to and use of password protected and/or secure areas
                    of the Site, Content and/or Services is restricted to
                    authorized users only. Unauthorized individuals attempting
                    to access the Site and/or these areas of the Site, Content
                    and/or Services may be subject to criminal or civil
                    prosecution or both.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Information Collection and Use
                </Typography>
                <Typography variant="h4" align="left">
                    Privacy
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing or using the Site, Content and/or Services
                    and/or permitting access to the Site, Content and/or
                    Services by a Permitted Minor, or otherwise accepting or
                    agreeing to these Terms of Use, you fully and completely
                    agree to the terms and conditions of Client’s Privacy Policy
                    and to any terms and conditions incorporated therein by
                    reference. Client’s Privacy Policy sets out how, why and to
                    what extent Client collects and uses your personal and
                    non-personal information in connection with your access to
                    or use of the Site, Content and/or Services. Client’s
                    Privacy Policy, as amended from time to time, is available
                    at{' '}
                    <Link href={`${EPage.PRIVACY_POLICY}`}>
                        {`${window.location.origin}${EPage.PRIVACY_POLICY}`}
                    </Link>
                    .
                </Typography>
                <Typography variant="h4" align="left">
                    Transmission of Personal Data
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge and agree that by providing Client with any
                    personal or proprietary user information through the Site,
                    Content and/or Services you consent to the transmission of
                    such personal or proprietary user information over
                    international borders as may be necessary for processing in
                    accordance with Client’s standard business practices.
                </Typography>
                <Typography variant="h4" align="left">
                    Use of “Cookies”
                </Typography>
                <Typography variant="body1" align="left">
                    Client reserves the right to store information on a user’s
                    computer in the form of a “cookie” or similar file for
                    purposes of modifying the Site and the user's experience to
                    reflect users’ preferences. The Privacy Policy provides
                    additional information regarding Client’s use of cookies as
                    well as procedures for disabling cookies.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    PURCHASES AND USE OF CREDITS
                </Typography>
                <Typography variant="h4" align="left">
                    Credit-Based Access to Services
                </Typography>
                <Typography variant="body1" align="left">
                    To access and use the Site’s Services, users must purchase
                    credits. The number of credits required for a each Service
                    depends on the nature and length of the debate or activity.
                    Longer debates or premium features may require additional
                    credits, as indicated at the time of selection.
                </Typography>
                <Typography variant="h4" align="left">
                    Dynamic Pricing and Adjustments
                </Typography>
                <Typography variant="body1" align="left">
                    Credit pricing, as well as the number of credits required
                    for specific Services, may change periodically. Pricing
                    details are displayed at the point of purchase or Service
                    selection. Client reserves the right to modify these rates
                    at its sole discretion; however, changes will not affect
                    credits already purchased.
                </Typography>
                <Typography variant="h4" align="left">
                    Credit Validity and Expiration
                </Typography>
                <Typography variant="body1" align="left">
                    Credits purchased are valid indefinitely unless explicitly
                    stated otherwise, such as for promotional or loyalty
                    credits, which may have specific expiration dates. Any
                    expiration terms will be disclosed at the time of issuance
                    and comply with applicable consumer protection laws.
                </Typography>
                <Typography variant="h4" align="left">
                    Consumer Protection Compliance
                </Typography>
                <Typography variant="body1" align="left">
                    Client complies with all applicable consumer protection
                    laws, including those governing the expiration and use of
                    prepaid services. For example, in jurisdictions such as
                    Canada, credits may not expire unless exempt (e.g.,
                    promotional credits). Users are encouraged to familiarize
                    themselves with the laws in their region.
                </Typography>
                <Typography variant="h4" align="left">
                    Adjustments to Credit Policies
                </Typography>
                <Typography variant="body1" align="left">
                    Client reserves the right to modify the credit system,
                    including pricing structures, the number of credits required
                    for Services, or the availability of specific features.
                    Notice of significant changes will be provided to users to
                    ensure they can make informed decisions about future
                    purchases.
                </Typography>
                <Typography variant="h4" align="left">
                    Age Requirement
                </Typography>
                <Typography variant="body1" align="left">
                    Only adults (individuals who have reached the age of
                    majority in their jurisdiction) may enter into and complete
                    transactions involving the payment of money. Users under the
                    age of majority must obtain verifiable consent from a parent
                    or legal guardian to make payments.
                </Typography>
                <Typography variant="h4" align="left">
                    Account and Payment Information
                </Typography>
                <Typography variant="body1" align="left">
                    When you provide payment account information to Client, you
                    represent and warrant that you are the authorized user of
                    the payment account. Unauthorized or fraudulent use of
                    payment information is strictly prohibited and may result in
                    suspension or termination of your Account.
                </Typography>
                <Typography variant="h4" align="left">
                    Third-Party Payment Processor
                </Typography>
                <Typography variant="body1" align="left">
                    All payments are processed through a third-party payment
                    provider approved by Client. By making a payment, you agree
                    to comply with the terms and conditions of the payment
                    provider. Client is not responsible for errors, delays, or
                    disputes arising from the payment processing service.
                </Typography>
                <Typography variant="h4" align="left">
                    Recurring Charges and Additional Purchases
                </Typography>
                <Typography variant="body1" align="left">
                    If you authorize recurring payments, the payment provider
                    will automatically charge your selected payment method for
                    applicable fees related to your Account(s) or additional
                    purchases, including any applicable taxes. You will be
                    notified of any changes to recurring payment amounts as
                    required by applicable law.
                </Typography>
                <Typography variant="h4" align="left">
                    Refund Policy and Consumer Protection
                </Typography>
                <Typography variant="body1" align="left">
                    All payments and credit purchases are final and
                    non-refundable, except as required by applicable law or at
                    the sole discretion of Client. This includes situations
                    where your Account is terminated or suspended, or the Site
                    or Services are interrupted or discontinued.
                </Typography>
                <Typography variant="body1" align="left">
                    If you encounter issues with a purchase or credits, please
                    contact support at{' '}
                    <Link target="_blank" href="mailto:human@botvbot.com">
                        human@botvbot.com
                    </Link>
                    .
                </Typography>
                <Typography variant="h4" align="left">
                    User Liability
                </Typography>
                <Typography variant="body1" align="left">
                    You are fully liable for all charges to your payment
                    account(s). If you become aware of unauthorized or erroneous
                    charges, you must notify Client immediately for resolution.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    WARRANTIES AND LIMITATION OF LIABILITY
                </Typography>
                <Typography variant="h4" align="left">
                    No Warranties
                </Typography>
                <Typography variant="body1" align="left">
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL
                    CHARACTERS, DEBATES, AND CONTENT MADE AVAILABLE THROUGH THIS
                    SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS FOR
                    ENTERTAINMENT PURPOSES ONLY. ALL CONTENT IS FICTIONAL,
                    GENERATED BY ARTIFICIAL INTELLIGENCE, AND MAY BE INACCURATE,
                    UNRELIABLE, OR INCOMPLETE.
                </Typography>
                <Typography variant="body1" align="left">
                    ANY RESEMBLANCE TO ACTUAL PERSONS (LIVING OR DECEASED),
                    EVENTS, OR ENTITIES IS PURELY COINCIDENTAL. CLIENT
                    ENTERTAINMENT, ITS AFFILIATES, AND THEIR RESPECTIVE
                    DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND CONTRACTORS
                    DISCLAIM ALL LIABILITY FOR ANY PERCEIVED ASSOCIATION OR
                    ATTRIBUTION OF CONTENT TO ANY REAL PERSON OR ORGANIZATION.
                    USERS ARE RESPONSIBLE FOR EVALUATING THE ACCURACY,
                    COMPLETENESS, AND APPROPRIATENESS OF ANY OUTPUT AND AGREE
                    NOT TO RELY ON THE CONTENT FOR FACTUAL, LEGAL, MEDICAL, OR
                    OTHER DECISIONS.
                </Typography>
                <Typography variant="body1" align="left">
                    SOME DEBATES MAY CONTAIN STRONG OR ADULT LANGUAGE, MATURE
                    THEMES, OR CONTENT THAT CERTAIN USERS MAY FIND OFFENSIVE.
                    USERS SHOULD EXERCISE DISCRETION AND ARE ADVISED TO SEEK
                    PROFESSIONAL ADVICE WHEN NECESSARY. CLIENT DISCLAIMS ANY
                    ENDORSEMENT OR ASSOCIATION WITH THE VIEWS, OPINIONS,
                    PRODUCTS, OR SERVICES EXPRESSED IN GENERATED OUTPUT.
                </Typography>
                <Typography variant="body1" align="left">
                    IF YOU BELIEVE ANY CONTENT INFRINGES UPON YOUR RIGHTS OR
                    OTHERWISE VIOLATES THE LAW, PLEASE NOTIFY US PROMPTLY AT{' '}
                    <Link target="_blank" href="mailto:human@botvbot.com">
                        human@botvbot.com
                    </Link>
                    .
                </Typography>
                <Typography variant="body1" align="left">
                    SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED
                    WARRANTIES, AND IN SUCH CASES, THE DISCLAIMERS ABOVE WILL
                    APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    LIMITATION OF LIABILITY FOR USE OF THE SITE, SERVICES, AND
                    CONTENT
                </Typography>
                <Typography variant="body1" align="left">
                    THE SITE, SERVICES, AND CONTENT ARE PROVIDED SOLELY FOR
                    ENTERTAINMENT PURPOSES AND MUST NOT BE RELIED UPON FOR
                    FACTUAL ACCURACY, DECISION-MAKING, OR ANY OTHER PURPOSE.
                    INACCURACIES, ERRORS, OR OMISSIONS IN THE CONTENT OR
                    SERVICES ARE INHERENT TO THEIR NATURE AND MAY BE PART OF THE
                    INTENDED ENTERTAINMENT EXPERIENCE.
                </Typography>
                <Typography variant="body1" align="left">
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CLIENT,
                    ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
                    EMPLOYEES, AND AGENTS SHALL NOT BE LIABLE FOR ANY CLAIMS
                    ARISING FROM OR RELATED TO YOUR USE OF, OR INABILITY TO USE,
                    THE SITE, SERVICES, OR CONTENT, REGARDLESS OF THE LEGAL
                    THEORY UNDER WHICH THE LIABILITY ARISES, INCLUDING BUT NOT
                    LIMITED TO CONTRACT, STATUTORY LIABILITY, TORT (INCLUDING
                    NEGLIGENCE), OR ANY OTHER LEGAL THEORY WHATSOEVER.
                </Typography>
                <Typography variant="body1" align="left">
                    THIS LIMITATION APPLIES TO ALL CLAIMS, INCLUDING BUT NOT
                    LIMITED TO:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        <strong>INACCURACIES AND ERRORS:</strong> ANY
                        INACCURACIES, ERRORS, OR OMISSIONS IN THE SITE,
                        SERVICES, OR CONTENT, WHETHER INTENTIONAL OR OTHERWISE.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>USER DECISIONS:</strong> ANY ACTIONS OR
                        DECISIONS MADE BASED ON THE SITE, SERVICES, OR CONTENT,
                        INCLUDING BUT NOT LIMITED TO LEGAL, FINANCIAL, MEDICAL,
                        OR OTHER PROFESSIONAL OR PERSONAL DECISIONS.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>OFFENSE, HARM, AND DEFAMATION:</strong> ANY
                        CLAIMS ARISING FROM CONTENT THAT USERS FIND OFFENSIVE,
                        DEFAMATORY, HARMFUL, OR INAPPROPRIATE, WHETHER ACCESSED
                        DIRECTLY THROUGH THE SITE OR DISTRIBUTED TO THIRD
                        PARTIES.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>THIRD-PARTY USE AND DISTRIBUTION:</strong> ANY
                        CLAIMS ARISING FROM THE USE, DISTRIBUTION, OR SHARING OF
                        CONTENT BY USERS WITH THIRD PARTIES, INCLUDING BUT NOT
                        LIMITED TO ANY RESULTING HARM TO THOSE THIRD PARTIES.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>INTERRUPTION OF ACCESS:</strong> ANY
                        INTERRUPTIONS, DELAYS, OR ERRORS IN ACCESSING THE SITE,
                        SERVICES, OR CONTENT.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        <strong>SECURITY ISSUES:</strong> UNAUTHORIZED ACCESS,
                        THEFT, OR LOSS OF DATA OR OTHER DAMAGES CAUSED BY
                        SECURITY VULNERABILITIES OR MALICIOUS SOFTWARE.
                    </Typography>
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Maximum Liability Cap
                </Typography>
                <Typography variant="body1" align="left">
                    IN NOT EVENT SHALL THE TOTAL LIABILITY OF CLIENT FOR ALL
                    CLAIMS RELATED TO THE SITE, SERVICES, OR CONTENT EXCEED THE
                    GREATER OF:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        THE TOTAL AMOUNT YOU PAID TO CLIENT IN THE TWELVE (12)
                        MONTHS PRECEDING THE CLAIM, OR
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        $100 USD.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    BY USING THE SITE, YOU ACKNOWLEDGE AND AGREE THAT:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        THE CONTENT AND SERVICES ARE FICTIONAL AND INTENDED TO
                        ENTERTAIN, NOT INFORM.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        YOU ASSUME FULL RESPONSIBILITY FOR ANY RELIANCE ON OR
                        ACTIONS TAKEN IN CONNECTION WITH THE SITE, SERVICES, OR
                        CONTENT.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        YOU WAIVE AND RELEASE ALL CLAIMS AGAINST CLIENT, ITS
                        AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
                        EMPLOYEES, AND AGENTS IN EXCESS OF THE TOTAL LIABILITY
                        CAP ABOVE.
                    </Typography>
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Violations of Terms of Use
                </Typography>
                <Typography variant="body1" align="left">
                    You agree and acknowledge that Client may, in its sole and
                    absolute discretion and without prior notice to you,
                    terminate or suspend your access to the Site and your use of
                    the Services, or any portion thereof, and/or cancel your
                    Account if Client reasonably believes that you have failed
                    to strictly comply with these Terms of Use or that the use
                    of the Site, Services or Content by you violates the rights
                    of any third party or any applicable laws. In addition to
                    the foregoing, Client reserves the right to pursue all
                    remedies available at law and in equity for violations of
                    these Terms of Use, including but not limited to the right
                    to block access from a particular Internet address to the
                    Site. You agree and acknowledge that monetary damages may
                    not be an adequate remedy for any violation of these Terms
                    of Use by you and, without limiting any of Client's other
                    remedies, you hereby consent to, and authorize Client to
                    obtain, an injunction or other equitable relief from any
                    court of competent jurisdiction. You further authorize
                    Client to disclose your personally identifiable information
                    where reasonably necessary in connection with the foregoing.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Indemnification
                </Typography>
                <Typography variant="body1" align="left">
                    YOU HEREBY AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS
                    CLIENT, ITS AFFILIATES AND SUBSIDIARIES, AND THEIR
                    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
                    SUCCESSORS, ADMINISTRATORS, AND ASSIGNS (THE “
                    <strong>INDEMNIFIED PARTIES</strong>”) FROM AND AGAINST ALL
                    CLAIMS ARISING OUT OF OR RELATING TO:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        YOUR AND/OR A PERMITTED MINOR’S VIOLATION OF ANY OF
                        THESE TERMS OF USE;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        YOUR AND/OR A PERMITTED MINOR’S USE OR MISUSE OF THE
                        SITE, SERVICES, AND/OR CONTENT, INCLUDING BUT NOT
                        LIMITED TO PERSONAL INJURY, DEATH, OR PROPERTY DAMAGE;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        YOUR AND/OR A PERMITTED MINOR’S VIOLATION OF ANY
                        APPLICABLE LAW OR THIRD-PARTY RIGHTS;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        YOUR SHARING, DISTRIBUTION, OR PUBLICATION OF CONTENT,
                        INCLUDING FICTIONAL OR AI-GENERATED MATERIAL, TO ANY
                        THIRD PARTY; AND
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        ANY ALLEGATIONS OF HARM, OFFENSE, DEFAMATION, OR OTHER
                        GRIEVANCES CAUSED BY THE FICTIONAL,
                        ENTERTAINMENT-FOCUSED, OR AI-GENERATED NATURE OF THE
                        CONTENT.
                    </Typography>
                </Typography>
                <Typography variant="body1" align="left">
                    WITHOUT LIMITING YOUR OBLIGATIONS UNDER THIS SECTION, WE
                    RESERVE THE RIGHT (AT YOUR EXPENSE) TO ASSUME THE EXCLUSIVE
                    DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION
                    BY YOU. THE CLIENT'S ASSUMPTION OF SUCH DEFENSE DOES NOT
                    WAIVE YOUR INDEMNIFICATION OBLIGATIONS UNDER THESE TERMS OF
                    USE. YOU AGREE NOT TO SETTLE ANY MATTER SUBJECT TO
                    INDEMNIFICATION WITHOUT OUR EXPRESS WRITTEN APPROVAL, WHICH
                    WILL NOT BE UNREASONABLY WITHHELD OR DELAYED.
                </Typography>
                <Typography variant="body1" align="left">
                    YOU ACKNOWLEDGE THAT THE SITE, SERVICES, AND CONTENT ARE
                    PROVIDED FOR ENTERTAINMENT PURPOSES ONLY AND AGREE THAT
                    INACCURACIES, ERRORS, OMISSIONS, OR FICTIONAL ELEMENTS ARE
                    INHERENT AND INTENTIONAL ASPECTS OF THE CONTENT. BY USING
                    THE SITE, YOU ACCEPT FULL RESPONSIBILITY FOR ANY
                    CONSEQUENCES ARISING FROM YOUR USE OF OR RELIANCE ON THE
                    SITE, SERVICES, OR CONTENT, INCLUDING ANY CLAIMS BROUGHT BY
                    THIRD PARTIES.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Updates and Amendments
                </Typography>
                <Typography variant="h4" align="left">
                    Updates and Discontinuance of Site, Software or Services
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge and agree that the Site, Content and
                    Services are under continual development and may include
                    public patches and/or updates, which are generally available
                    to all users, or private patches and/or updates which are
                    available to a smaller number of users for testing and
                    feedback before being made generally available. You further
                    acknowledge and agree that you may be required to accept
                    public patches and updates to the Site, Content and/or
                    Services from time to time in order to continue to access
                    the Site, Content and/or Services and your related
                    Account(s). You acknowledge and agree that Client may
                    update, modify, patch and/or discontinue any aspect of the
                    Site, Content and/or Services, by remotely installing
                    updates and patches or otherwise, without your consent or
                    approval and with or without prior, or any, notice to you.{' '}
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge that it may be necessary for you to update
                    or upgrade third party software, hardware and devices from
                    time to time in order to continue to access and use the
                    Site, Content and/or Services and your related Account(s).
                    Client reserves the right to modify or increase the system
                    specifications necessary to access and use the Site, Content
                    and/or Services at any time and without notice and you are
                    responsible for purchasing any necessary additional
                    technology, systems or services in order to continue to
                    access and use the Site, Content, Services and/or your
                    related Account(s) in the event of any change in the system
                    specifications.
                </Typography>
                <Typography variant="body1" align="left">
                    Client reserves the right, at any time and without notice or
                    liability to you, to update, modify and/or reset certain
                    parameters of the Site, Content and/or Services any of which
                    may impact the status, progress or stored content under your
                    control or associated with your Account(s).
                </Typography>
                <Typography variant="h4" align="left">
                    Changes to botvbot.com
                </Typography>
                <Typography variant="body1" align="left">
                    Client reserves the right, acting in its sole discretion and
                    with or without prior notice to you, to do any of the
                    following:
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Typography component="li" variant="body1" align="left">
                        modify, suspend or terminate operation of, or access to,
                        any portion, features or functions of the Site, Content
                        and/or Services including, but not limited to,
                        geographical availability and applicable policies or
                        terms;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        make changes to any fees or charges, if any, related to
                        your use of the Site, Content and/or Services;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        make changes to the equipment, hardware or software
                        required to use and access the Site, Content and/or
                        Services; and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        interrupt the Site, Content and /or Services, or any
                        portion thereof, as necessary to perform routine or
                        non-routine maintenance, error correction or other
                        modifications.
                    </Typography>
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    Termination
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use, as amended from time to time by Client,
                    shall remain effective until terminated by you or Client.
                    You may terminate these Terms of Use by discontinuing use of
                    the Site, Content, Services and your associated Account(s).
                    Without limiting any other provision of these Terms of Use
                    or the remedies available to Client, Client may immediately
                    terminate these Terms of Use and/or cancel or suspend your
                    Account(s) and access to the Site, Content and/or Services
                    in its sole and absolute discretion and for any reason
                    including, without limitation, if you violate or otherwise
                    fail to strictly comply with any term or provision of these
                    Terms of Use. Upon termination of these Terms of Use for any
                    reason, you must cease use of your Account(s), cease
                    accessing the Site, Content and Services and promptly
                    destroy any/all electronic and other copies of the Content.
                </Typography>
                <Typography
                    variant="h3"
                    align="left"
                    sx={{ textTransform: 'uppercase' }}
                >
                    General
                </Typography>
                <Typography variant="h4" align="left">
                    International Use
                </Typography>
                <Typography variant="body1" align="left">
                    This Site is operated from and Client is headquartered in
                    the Province of British Columbia, Canada. By electing to
                    access this Site, Content and/or Services from any
                    jurisdiction outside of Canada, you accept full
                    responsibility for ensuring that your use of the Site,
                    Content and/or Services is in compliance with all laws
                    applicable within that other jurisdiction. Client makes no
                    representation that materials and services available on or
                    through the Site, Content and/or Services are appropriate or
                    available for use in jurisdictions outside of Canada. You
                    are not permitted to access the Site, Content and/or
                    Services from any jurisdiction in which the Content is
                    illegal and/or accessing the Site, Content and/or Services
                    is illegal. You may not use, export or re-export any
                    materials from this Site, Content and/or Services in
                    violation of any applicable laws or regulations.
                </Typography>
                <Typography variant="h4" align="left">
                    Governing Law
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use and your legal relationship with Client
                    shall be governed by, and will be enforced, construed and
                    interpreted in accordance with the laws of the Province of
                    British Columbia and the federal laws of Canada applicable
                    therein, without regard to its conflicts of laws principles.
                    These Terms of Use shall not be governed by the{' '}
                    <em>
                        United Nations Convention on Contracts for the Sale of
                        Goods
                    </em>
                    . The controlling language of these Terms of Use is English.
                </Typography>
                <Typography variant="h4" align="left">
                    Waiver and Severability
                </Typography>
                <Typography variant="body1" align="left">
                    The failure of Client to enforce any right or provision of
                    these Terms of Use will not constitute a waiver of such
                    right or provision. In the event that any provision of these
                    Terms of Use is held to be invalid or unenforceable by any
                    court of competent jurisdiction, that provision shall be
                    enforced to the maximum extent permissible and the remaining
                    provisions of the Terms of Use will remain in full force and
                    effect. All remedies of Client hereunder shall be
                    cumulative.
                </Typography>
                <Typography variant="h4" align="left">
                    Currency
                </Typography>
                <Typography variant="body1" align="left">
                    Unless otherwise expressly provided all dollar amounts
                    referred to on the Site and all amounts payable to Client
                    are in USD.
                </Typography>
                <Typography variant="h4" align="left">
                    Assignment
                </Typography>
                <Typography variant="body1" align="left">
                    You may not assign any of your rights or obligations
                    hereunder, whether voluntarily or by operation of law except
                    with the prior express written consent of Client, which
                    consent may be withheld in Client’s sole discretion, and any
                    attempted assignment in violation of the foregoing is void.
                    Client may assign any or all of its rights and obligations
                    hereunder to an affiliate, subsidiary or a successor in
                    title to the Site and/or the business and undertaking of
                    Client.
                </Typography>
                <Typography variant="h4" align="left">
                    Enurement
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use shall enure to the benefit of and be
                    binding upon the parties and their respective heirs,
                    executors, administrators, successors and permitted assigns.
                </Typography>
                <Typography variant="h4" align="left">
                    Force Majeure
                </Typography>
                <Typography variant="body1" align="left">
                    Client will not be liable for, or be considered to be in
                    breach of or default under these Terms of Use on account of,
                    any delay or failure to perform as required by these Terms
                    of Use as a result of events beyond the reasonable control
                    of Client, including without limiting the foregoing where
                    the delay or failure is due to fires or fire related
                    hazards, explosions, power outages, earthquakes, floods,
                    spills, epidemics, acts of God, war or acts of war, acts of
                    terrorism, riots, strikes, lockouts or other labour or
                    contractor actions, acts of public authorities, or delays or
                    defaults caused by common carriers, or other similar events
                    which cannot reasonably be foreseen or provided against.
                </Typography>
                <Typography variant="h4" align="left">
                    Notices
                </Typography>
                <Typography variant="body1" align="left">
                    Any notices or other communications permitted or required
                    hereunder, including but not limited to modifications to
                    these Terms of Use and/or any policy referred to herein,
                    will be in writing and given by Client (i) via email (in
                    each case to the email address provided by you for your
                    Account(s)) or (ii) by posting to the Site. Notice delivered
                    by email will be deemed to have been received on the date on
                    which such notice is transmitted.
                </Typography>
                <Typography variant="h4" align="left">
                    Dispute Resolution
                </Typography>
                <Typography variant="body1" align="left">
                    You and Client agree that any dispute, Claim, or controversy
                    arising out of or relating to these Terms of Use, any policy
                    referred to herein, or the breach, termination, enforcement,
                    interpretation, or validity thereof, or the use of the Site,
                    Content, or Services (collectively, the “
                    <strong>Disputes</strong>”) shall be resolved exclusively
                    through the dispute resolution mechanisms set out in this
                    section.
                </Typography>
                <Typography
                    variant="body1"
                    component="ul"
                    sx={{ marginLeft: 3 }}
                >
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Binding Arbitration:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Except as provided below, all Disputes shall be
                            settled by binding arbitration. Arbitration shall be
                            conducted before a single arbitrator in accordance
                            with the rules of the Vancouver International
                            Arbitration Centre (VanIAC) for Domestic Disputes.
                            The seat of arbitration shall be Vancouver, British
                            Columbia, Canada. Arbitration may be conducted
                            virtually or at another mutually agreed venue.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Small Claims Actions:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Notwithstanding the above, you and Client retain the
                            right to bring individual actions in the British
                            Columbia Provincial Court (Small Claims Division) if
                            the action is within the jurisdiction of that court.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Injunctive Relief:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Either party may seek injunctive or other equitable
                            relief in a court of competent jurisdiction to
                            prevent actual or threatened infringement,
                            misappropriation, or violation of a party’s
                            copyrights, trademarks, trade secrets, patents,
                            confidential information, or other intellectual
                            property or proprietary rights.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>
                                Exclusivity of Dispute Resolution Mechanisms:
                            </strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            By agreeing to these Terms of Use, you irrevocably
                            waive the right to pursue dispute resolution through
                            any forum or mechanism other than those expressly
                            set out in this section, including arbitration,
                            Small Claims actions, and injunctive relief. Any
                            Dispute brought in breach of this agreement to
                            resolve disputes exclusively under this section
                            shall be dismissed or stayed in favor of arbitration
                            or another permitted mechanism.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>
                                Class Action and Jury Trial Waivers:
                            </strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            You acknowledge and agree that you and Client are
                            each irrevocably waiving the right to a trial by
                            jury and the right to participate as a plaintiff or
                            class member in any purported class action or
                            similar representative proceeding. Further, unless
                            you and Client expressly agree in writing, the
                            arbitrator may not consolidate more than one
                            person’s claims or otherwise preside over any form
                            of class or representative proceeding.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Consumer Protection Carve-Out:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            Nothing in this section shall limit or exclude any
                            rights you may have under applicable consumer
                            protection laws that prohibit mandatory arbitration,
                            venue restrictions, or the waiver of class actions
                            or jury trials.
                        </Typography>
                    </Box>
                    <Box component="li">
                        <Typography variant="body1" align="left">
                            <strong>Survival:</strong>
                        </Typography>
                        <Typography variant="body1" align="left">
                            This “Dispute Resolution” section shall survive any
                            termination of these Terms of Use.
                        </Typography>
                    </Box>
                </Typography>

                <Typography variant="h4" align="left">
                    Entire Agreement
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use, Acceptable Use Policy, and pop-up
                    disclaimer together with the Privacy Policy and any
                    supplemental terms and conditions referred to below,
                    constitute the entire agreement between the parties
                    concerning the subject-matter hereof and supersede and
                    replace any and all prior oral or written understandings or
                    agreements between you and Client regarding the Site,
                    Content, and Services.
                </Typography>
                <Typography variant="h4" align="left">
                    Survival
                </Typography>
                <Typography variant="body1" align="left">
                    The provisions of these Terms of Use which require or
                    contemplate performance after the expiration or termination
                    of these Terms of Use shall remain enforceable
                    notwithstanding such expiration or termination.
                </Typography>
                <Typography variant="h4" align="left">
                    Relationship
                </Typography>
                <Typography variant="body1" align="left">
                    The relationship between the parties is that of independent
                    contractors and neither party shall have the authority to
                    bind the other in any way.
                </Typography>
            </Box>
        </>
    );
};

export default TermsComponent;
