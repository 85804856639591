import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const footerTheme = createTheme(mainTheme, {
    typography: {
        caption: {
            fontSize: '1rem',
            lineHeight: 1.3,
            textTransform: 'none',
        },
    },
});

export default footerTheme;
