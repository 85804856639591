import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const DisclaimerComponent: React.FC = () => {
    return (
        <>
            <Typography variant="h1" align="center">
                Disclaimer
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    gap: 1,
                }}
            >
                <Typography variant="body1" align="left">
                    All characters, debates, and content on this website are
                    fictional and for entertainment purposes only. Some debates
                    may contain strong or adult language, mature themes, or
                    other content that certain users may find offensive. Any
                    resemblance to actual persons (living or deceased), events,
                    or entities is purely coincidental. The content does not
                    represent, reflect, or endorse the views, opinions,
                    products, or services of any real individual or&nbsp;entity.
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth Entertainment, its affiliates, and their
                    respective directors, officers, employees, contractors,
                    agents, and representatives disclaim all liability for any
                    perceived association or attribution of content to any real
                    person or organization. Users acknowledge that debates and
                    characters are generated by artificial intelligence and may
                    be inaccurate or unreliable and may contain
                    sensitive&nbsp;themes.
                </Typography>
                <Typography variant="body1" align="left">
                    If you believe any content infringes upon your rights or
                    otherwise violates the law, please notify us via email at{' '}
                    <Link target="_blank" href="mailto:human@botvbot.com">
                        human@botvbot.com
                    </Link>{' '}
                    so that we may investigate and address the matter promptly.
                    By using this website, you agree to these terms and proceed
                    at your own&nbsp;discretion.
                </Typography>
            </Box>
        </>
    );
};

export default DisclaimerComponent;
