import { createTheme } from '@mui/material/styles';
import dialogTheme from './dialog';

const tipsTheme = createTheme(dialogTheme, {
    typography: {
        body2: {
            fontSize: '0.9rem',
            lineHeight: 1.4,
            textTransform: 'none',
        },
    },
});

export default tipsTheme;
