import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import {
    FormatQuoteRounded as FormatQuote,
    InfoRounded,
    PauseRounded as Pause,
    PlayArrowRounded as PlayArrow,
    Replay10Rounded as Replay10,
} from '@mui/icons-material';
import { ButtonBase, CircularProgress, IconButton } from '@mui/material';

import { IDebateTopic, IParticipant } from '../../../model/interface';
import { EDebatePosition } from '../../../model/enum';
import AvatarComponent from './Avatar';
import { ColorHelper } from '../../../helpers/Color';
import participantTheme from '../../../themes/participant';
import { StringHelper } from '../../../helpers/String';

interface IParticipantProps {
    size: 'small' | 'medium' | 'large';
    speaker: IParticipant;
    debateTopic?: IDebateTopic;
    highlighted?: boolean;
    buffering?: boolean;
    speaking?: boolean;
    mouthClosed?: boolean;
    done?: boolean;
    paused?: boolean;
    handlePausePlayAudio?: () => void;
    handleSeekBack?: () => void;
    handleInfo?: (participant: IParticipant) => void;
}

const ParticipantComponent: React.FC<IParticipantProps> = ({
    size,
    speaker,
    debateTopic,
    highlighted,
    buffering,
    speaking,
    mouthClosed,
    done,
    paused,
    handlePausePlayAudio,
    handleSeekBack,
    handleInfo,
}) => {
    const handleClick = () => {
        if (handlePausePlayAudio !== undefined) {
            handlePausePlayAudio();
        }
    };

    return (
        <ThemeProvider theme={participantTheme}>
            <Box display="flex" sx={{ position: 'relative', height: '100%' }}>
                <ButtonBase
                    onClick={handleClick}
                    disabled={!highlighted || buffering || size !== 'large'}
                    sx={{
                        width:
                            speaker.position === EDebatePosition.MODERATOR
                                ? undefined
                                : '100%',
                        alignItems: size !== 'large' ? 'flex-start' : undefined,
                        boxSizing: 'border-box',
                        filter:
                            highlighted === false && size === 'large'
                                ? 'grayscale(1)'
                                : 'none',
                        backgroundColor: ColorHelper.getBackground(
                            speaker.position
                        ),
                        gap: 2,
                    }}
                >
                    <Box
                        display="flex"
                        alignItems={
                            size === 'large'
                                ? 'center'
                                : {
                                      xs: 'center',
                                      sm: 'center',
                                      md: 'flex-start',
                                  }
                        }
                        flexDirection={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        sx={{
                            gap: {
                                xs: 2,
                                sm:
                                    speaker.position ===
                                    EDebatePosition.MODERATOR
                                        ? 2
                                        : 6,
                            },
                        }}
                    >
                        <AvatarComponent
                            speaking={speaking}
                            buffering={highlighted && buffering}
                            mouthClosed={mouthClosed}
                            backgroundColor={ColorHelper.getAvatar(
                                speaker.position
                            )}
                            speaker={speaker}
                            size={size === 'large' ? 'large' : 'medium'}
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={{ xs: 'center', sm: 'flex-start' }}
                            sx={{
                                gap:
                                    speaker.position ===
                                    EDebatePosition.MODERATOR
                                        ? 0
                                        : { xs: 1, sm: 1, md: 2 },
                            }}
                        >
                            <Typography variant="h1" align="left">
                                {speaker.name}
                            </Typography>
                            {speaker.position === EDebatePosition.MODERATOR ? (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        textAlign: {
                                            xs: 'center',
                                            sm: 'left',
                                        },
                                    }}
                                >
                                    {speaker.position} from
                                    <br />
                                    {speaker.hometown}
                                </Typography>
                            ) : (
                                <>
                                    {size !== 'small' && (
                                        <Typography
                                            variant="caption"
                                            align="left"
                                            sx={{
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                },
                                            }}
                                        >
                                            {`${speaker.age}-year-old ${speaker.occupation} from ${speaker.hometown}`}
                                        </Typography>
                                    )}
                                    <Box
                                        display="flex"
                                        sx={{ position: 'relative' }}
                                        alignItems={{
                                            xs: 'center',
                                            sm: 'flex-start',
                                        }}
                                    >
                                        <FormatQuote
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                },
                                                position: 'absolute',
                                                top: '-0.3rem',
                                                left: '-2rem',
                                                fontSize: '2rem',
                                                opacity: 0.3,
                                                transform: 'scaleX(-1)',
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'left',
                                                },
                                            }}
                                        >
                                            {StringHelper.insertNonBreakingSpace(
                                                debateTopic!.sides[
                                                    speaker.position
                                                ].position
                                            )}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </ButtonBase>
                {highlighted && !done && (
                    <Box
                        display="flex"
                        sx={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.6rem',
                            zIndex: 100,
                            gap: 0.5,
                        }}
                    >
                        {!paused &&
                            !buffering &&
                            handlePausePlayAudio !== undefined && (
                                <IconButton
                                    onClick={handleSeekBack}
                                    sx={{
                                        padding: '0.4rem !important',
                                        backgroundColor:
                                            ColorHelper.getBackground(
                                                speaker.position
                                            ),
                                    }}
                                >
                                    <Replay10
                                        sx={{
                                            fontSize: '3.2rem',
                                            opacity: 0.54,
                                        }}
                                    />
                                </IconButton>
                            )}
                        {buffering ? (
                            <Box>
                                <CircularProgress />
                            </Box>
                        ) : (
                            paused && (
                                <IconButton
                                    onClick={handleClick}
                                    sx={{
                                        padding: '0.4rem !important',
                                        borderWidth: 3,
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(255, 255, 255, 0.3)',
                                        backgroundColor:
                                            ColorHelper.getBackground(
                                                speaker.position
                                            ),
                                    }}
                                >
                                    {paused ? <PlayArrow /> : <Pause />}
                                </IconButton>
                            )
                        )}
                    </Box>
                )}
                {speaker.info && handleInfo !== undefined && (
                    <IconButton
                        size="small"
                        onClick={() => handleInfo(speaker)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            opacity: 0.3,
                        }}
                    >
                        <InfoRounded sx={{ width: '2rem', height: '2rem' }} />
                    </IconButton>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default ParticipantComponent;
